import React from "react";
import { Row, Col, Typography } from "antd";
import moment from "moment";
import "moment/locale/es";

// Content
import ProfilePhoto from "../header/ProfilePhoto";

// Redux
import { useSelector } from "react-redux";

// Utils
moment.locale("es");
const { Text } = Typography;

const BasicInfo = ({ photoUrl, setPhotoUrl }) => {
  const selectedPatient = useSelector((state) => state.patient.selected);

  const {
    nombres,
    apellidos,
    personal_id,
    edad,
    fecha_nacimiento,
    deporte,
    modalidad,
    grupo_sanguineo,
    rh,
    residencia,
  } = selectedPatient;

  return (
    <Row justify="center" className="bg-white mb-5 rounded-2xl shadow-neo-card">
      <Col
        sm={24}
        lg={8}
        className="p-5 rounded-2xl shadow-neo-card-inset-blue"
        style={{
          background: "linear-gradient(145deg, #0a78b2, #45b6bc)",
          zIndex: 2,
        }}
      >
        <Row>
          <span className="mx-auto mb-2">
            <ProfilePhoto
              photoUrl={photoUrl}
              setPhotoUrl={setPhotoUrl}
              allowEdit={true}
              size={100}
            />
          </span>
        </Row>
        <Row>
          <Text className="w-full font-bold text-xl text-white text-center">
            {nombres.split(" ")[0] + " " + apellidos.split(" ")[0]}, {edad} años
          </Text>
        </Row>
        <Row>
          <Text className="w-full font-light text-base text-white text-center">
            {residencia
              ? residencia.slice(0, residencia.indexOf(","))
              : "Ciudad"}
            {", "}
            {residencia
              ? residencia.slice(residencia.indexOf(",") + 1, residencia.length)
              : "-"}
          </Text>
        </Row>
      </Col>
      <Col
        sm={24}
        lg={16}
        className="bg-white py-5 px-8 flex flex-col justify-between rounded-2xl"
      >
        <Row>
          <Col md={8} xs={24}>
            <Row>
              <Text className="text-base font-light mb-1">Identificación</Text>
            </Row>
            <Row>
              <Text className="text-lg font-bold md:mb-8">
                {!personal_id ? "Desconocido" : personal_id}
              </Text>
            </Row>
            <Row>
              <Text className="text-base font-light mb-1 mt-4 md:mt-0">
                G.S. RH
              </Text>
            </Row>

            <Row>
              <Col>
                <Text className="text-base font-bold">
                  {grupo_sanguineo ? grupo_sanguineo : "N/A"}
                </Text>
              </Col>
              <Col>
                <Text className=" text-sm font-bold ml-1">
                  {rh ? rh : "N/A"}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col md={8} xs={24}>
            <Row>
              <Text className="text-base font-light mb-1 mt-4 md:mt-0">
                Deporte
              </Text>
            </Row>
            <Row>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="text-lg font-bold md:mb-8 overflow-ellipsis"
              >
                {deporte ?? "Desconocido"}
              </Text>
            </Row>
            <Row>
              <Text className="text-base font-light mb-1 mt-4 md:mt-0">
                Modalidad
              </Text>
            </Row>
            <Row>
              <Text className="text-lg font-bold">
                {modalidad ?? "Desconocido"}
              </Text>
            </Row>
          </Col>
          <Col md={8} xs={24}>
            <Row>
              <Text className="text-base font-light mb-1 mt-4 md:mt-0">
                Fecha Nacimiento
              </Text>
            </Row>
            <Row>
              <Text className="text-lg font-bold">
                {fecha_nacimiento.value
                  ? moment(fecha_nacimiento.value).format("DD MMM YYYY")
                  : "Desconocido"}
              </Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BasicInfo;
