import React from "react";
import { Layout, Menu, Dropdown, Tooltip, Badge } from "antd";
import { signOut } from "firebase/auth";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";

// Components
import ProfilePhoto from "./ProfilePhoto";

// Assets
import logo from "../../assets/img/moebius-full.png";
import avatar_admin from "../../assets/img/avatar-admin.png";
import "../../styles/header.css";

// Config
import { auth } from "../../config/firebase-config";
import { api_enpoint } from "../../config/config";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../features/user/userSlice";
import { clearPatientState } from "../../features/patient/patientSlice";
import { headerTitles } from "../../constants";
import useWindowDimensions from "../../utils/window";

const { Header } = Layout;

const CustomHeader = ({
  visibleDropdown,
  setVisibleDropdown,
  photoUrl,
  setPhotoUrl,
}) => {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const showWelcome = useSelector((state) => state.navigation.showWelcome);

  const databaseUser = useSelector((state) => state.user.databaseUser);
  const firebaseUser = useSelector((state) => state.user.firebaseUser);

  let history = useHistory();

  const handleChangeDropdown = () => {
    setVisibleDropdown((prev) => !prev);
  };

  const logout = async () => {
    history.push("/");
    await signOut(auth);
    dispatch(clearPatientState());
    dispatch(clearUserState());
  };

  return (
    <Header
      className={
        "w-full z-1002 fixed flex justify-between items-stretch bg-gray-900 px-6 py-0"
      }
      style={{
        boxShadow: `${!showWelcome ? "0px 3px 6px #c9cdd8 " : "none"}`,
      }}
    >
      <div className={"w-3/4 m-0 flex flex-row items-center"}>
        <img width="100px" src={logo} alt="logo" />

        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[history.location.pathname]}
          className="ml-8 w-4/5 xs"
          style={{
            lineHeight: "64px",
            background: "#171717",
          }}
        >
          {!showWelcome && (
            <>
              <Menu.Item className="menu-header" key="/pasaportes">
                <Link to="/pasaportes">
                  {headerTitles.passport[databaseUser.rol]}
                </Link>
              </Menu.Item>
              <Menu.Item className="menu-header" key="/dashboards">
                <Link to="/dashboards">
                  {headerTitles.dashboard[databaseUser.rol]}
                </Link>
              </Menu.Item>
              <Menu.Item className="menu-header" key="/calendario">
                <Link to="/calendario">
                  {headerTitles.calendar[databaseUser.rol]}
                </Link>
              </Menu.Item>
              <Menu.Item className="menu-header" key="/nutricional">
                <Link to="/nutricional">
                  {headerTitles.nutritional[databaseUser.rol]}
                </Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>

      <div className={"w-auto m-0 flex flex-row items-center"}>
        <div className="line-header" />
        <Dropdown
          overlay={
            <>
              <Menu mode="vertical" theme="dark">
                {firebaseUser?.email && (
                  <Menu.Item
                    key="email-menu-item"
                    disabled={true}
                    className="cursor-default"
                  >
                    {/* Used for develop purpouses with internal user credentials */}
                    <Tooltip
                      title={`${
                        firebaseUser?.email === "demo@d4d.com.co"
                          ? process.env.NODE_ENV +
                            " " +
                            api_enpoint +
                            "fixed cd"
                          : ""
                      }`}
                    >
                      {firebaseUser?.email}
                    </Tooltip>
                  </Menu.Item>
                )}
                {/* Active user with badge */}
                <Menu.Item
                  key="name-menu-key"
                  disabled={true}
                  className="cursor-default"
                >
                  <Tooltip
                    title={
                      databaseUser?.activo
                        ? "Usuario activo"
                        : "Usuario desactivado"
                    }
                  >
                    <Badge color={databaseUser?.activo ? "green" : "yellow"} />
                    {databaseUser?.nombres} {databaseUser?.apellidos}
                  </Tooltip>
                </Menu.Item>
                <Menu.Item key="logout-menu-key" onClick={logout}>
                  Cerrar Sesión
                </Menu.Item>
              </Menu>
            </>
          }
          visible={visibleDropdown}
          overlayClassName="overlay-menu-header"
        >
          <span className="menu-header" onClick={handleChangeDropdown}>
            <div>
              <ProfilePhoto
                photoUrl={databaseUser?.rol === 1 ? avatar_admin : photoUrl}
                setPhotoUrl={setPhotoUrl}
              />
            </div>

            {width > 500 && (
              <span>
                {databaseUser?.rol === 1
                  ? "Administrador"
                  : databaseUser?.nombres.split(" ")[0]}
              </span>
            )}
          </span>
        </Dropdown>
      </div>
    </Header>
  );
};

export default withRouter(CustomHeader);
