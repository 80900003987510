import React, { useState, useEffect } from "react";
import { Layout, Divider, Button, Tooltip, Select, Spin, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import "moment/locale/es";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearRecordState,
  updateRecordList,
  updateSelectedRecord,
} from "../../features/record/recordSlice";

// Config
import { api_enpoint } from "../../config/config";

// Components
import BasicInfo from "./BasicInfo";
import CorporalCompAndElectroSection from "./CorporalCompAndElectroSection";
import ValoracionMedica from "./ValoracionMedica";
import Ergoespirometria from "./Ergoespirometria";
import Biomarcadores from "./Biomarcadores";
import PDF from "./PDF/PDF";

// Utils
import "../../styles/content.css";
import FormRegistro from "./forms/FormRegistro";

moment.locale("es");
const { Option } = Select;
const { Content } = Layout;

const CustomContent = ({ photoUrl, setPhotoUrl }) => {
  const dispatch = useDispatch();
  const selectedPatient = useSelector((state) => state.patient.selected);

  // Patient records and vitals
  const selectedRecord = useSelector((state) => state.record.selected);
  const recordsList = useSelector((state) => state.record.list);
  const loadingRecordForm = useSelector(
    (state) => state.record.recordForm.loading
  );

  const [patientVitals, setPatientVitals] = useState([]);

  const [loadingRecords, setLoadingRecords] = useState(true);

  const [recordOptions, setRecordOptions] = useState([]);

  // Pdf visor
  const [onClickElectro, setOnClickElectro] = useState(false);
  const [onClickErgo, setOnClickErgo] = useState(false);

  // Get patient records and vital signs when eleceted patient
  useEffect(() => {
    const getPatientRecords = async () => {
      setLoadingRecords(true);
      await axios
        .get(
          `${api_enpoint}/pacientes/${selectedPatient?.id_paciente}/registros`
        )
        .then(async (res) => {
          let medRecords = res.data.data;
          dispatch(updateRecordList(medRecords));
          dispatch(updateSelectedRecord(medRecords[0]));
          const recordSelectOptions = medRecords.map((row) => {
            return (
              <Option key={row.fecha_registro}>
                {moment(row.fecha_registro)
                  .add(1, "days")
                  .format("DD MMM YYYY")}
              </Option>
            );
          });
          setRecordOptions(recordSelectOptions);
          await getPatientVitals();
        })
        .catch(() => {
          console.log("Sin registros ");
          dispatch(clearRecordState());
          setLoadingRecords(false);
        });
    };
    const getPatientVitals = async () => {
      await axios
        .get(`${api_enpoint}/pacientes/${selectedPatient?.id_paciente}/vitales`)
        .then((res) => {
          let data = res.data.data;
          setPatientVitals(data);
          setLoadingRecords(false);
        })
        .catch((err) => {
          console.log(err);
          setPatientVitals([]);
          setLoadingRecords(false);
        });
    };
    getPatientRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient, loadingRecordForm]);

  const handleChangeRecord = (value) => {
    dispatch(
      updateSelectedRecord(
        recordsList.find((ele) => ele.fecha_registro === value)
      )
    );
  };
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Content
      style={{ background: "#dce5ff" }}
      className="px-6 mt-0 md:mt-8 pt-0 md:pt-12 flex flex-col "
    >
      {(onClickElectro || onClickErgo) && (
        <Button
          className="mt-4 fixed rounded-3xl z-1002 shadow-lg"
          type="primary"
          onClick={() => {
            setOnClickElectro(false);
            setOnClickErgo(false);
          }}
        >
          Volver
        </Button>
      )}
      {onClickElectro ? (
        <PDF pdf={selectedRecord?.electro_pdf} />
      ) : onClickErgo ? (
        <PDF pdf={selectedRecord?.ergo_pdf} />
      ) : selectedRecord ? (
        <>
          <BasicInfo photoUrl={photoUrl} setPhotoUrl={setPhotoUrl} />
          {!loadingRecords ? (
            <>
              <Row justify="space-between">
                <span>
                  Registros de paciente tomados el día:
                  <Select
                    placeholder="Seleccione registro"
                    onChange={handleChangeRecord}
                    bordered={false}
                    disabled={!recordsList.length}
                    defaultValue={selectedRecord?.fecha_registro}
                    style={{
                      width: 130,
                      borderRadius: "16px",
                      marginLeft: "auto",
                    }}
                  >
                    {recordOptions}
                  </Select>
                  <Tooltip title="Visualice los valores nutricionales, CPET y Biomarcadores registrados por fecha">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
                <FormRegistro />
              </Row>
              <Divider />
              <CorporalCompAndElectroSection
                setOnClickElectro={setOnClickElectro}
              />
              <Divider />
              <ValoracionMedica patientVitals={patientVitals} />
              <Divider />
              <Ergoespirometria setOnClickErgo={setOnClickErgo} />
              <Divider />
              <Biomarcadores />
            </>
          ) : (
            <Spin size="large" />
          )}
        </>
      ) : (
        <>
          <BasicInfo photoUrl={photoUrl} setPhotoUrl={setPhotoUrl} />
          <FormRegistro />
          <div className="h-auto max-w-xs mx-auto my-8 text-center p-4">
            <img
              src="/no-medical-data.png"
              alt="Sin registros médicos"
              className="mb-4 opacity-60"
            />
            <p className="text-lg font-semibold ">Sin resultados</p>
            <p className="text-sm text-gray-500 mt-2">Sin registros médicos</p>
          </div>
        </>
      )}
    </Content>
  );
};

export default CustomContent;
