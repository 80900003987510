import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const CustomFooter = () => {
  return (
    <Footer
      className={
        "h-14 bg-gray-100 flex items-center justify-center text-gray-500"
      }
      style={{
        boxShadow: `-5px -5px 15px #c9cdd8`,
      }}
    >
      2022 © Moebius by Data for decision
    </Footer>
  );
};

export default CustomFooter;
