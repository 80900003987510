import { createRequest, throwErrors } from "./globalServices";

export const createPatient = async (userId, patientBody) => {
  try {
    const response = await createRequest().post(
      `/usuarios/${userId}/pacientes`,
      patientBody
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const createMedicalRecord = async (userId, recordBody) => {
  try {
    const response = await createRequest().post(
      `/pacientes/${userId}/registros`,
      recordBody
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const getNutritionalPlanByPatient = async (userId) => {
  try {
    const response = await createRequest().get(
      `/pacientes/${userId}/plan_nutricional`
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const createMeal = async (userId, planId, mealBody) => {
  try {
    const response = await createRequest().post(
      `/pacientes/${userId}/plan_nutricional/${planId}/comidas`,
      mealBody
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const createNutritionalPlanWithMeal = async (userId, mealBody) => {
  try {
    const response = await createRequest().post(
      `/pacientes/${userId}/plan_nutricional`,
      mealBody
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const getTasksByPatient = async (userId) => {
  try {
    const response = await createRequest().get(`/pacientes/${userId}/tareas`);
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};
