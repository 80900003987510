import { useCallback } from "react";

// Redux Toolkit
import { useDispatch, useSelector } from "react-redux";

import {
  nextFormStep,
  prevFormStep,
  clearRecordFormState,
  updateRecordFormField,
  updateError,
  updateLoading,
  updateVisible,
} from "features/record/recordSlice";

import { openNotification } from "utils/utils";
import moment from "moment";
import { createMedicalRecord } from "services/patientService";

export function useCreateMedicalRecord() {
  const dispatch = useDispatch();

  const visibleModal = useSelector((state) => state.record.recordForm.visible);
  const error = useSelector((state) => state.record.recordForm.error);
  const loading = useSelector((state) => state.record.recordForm.loading);

  const medicalRecordFormValues = useSelector(
    (state) => state.record.recordForm.values
  );

  const formStep = useSelector((state) => state.record.recordForm.step);

  const setVisibleModal = useCallback(
    (value) => {
      dispatch(updateVisible(value));
    },
    [dispatch]
  );

  const handleOnClose = useCallback(() => {
    dispatch(updateVisible(false));
    dispatch(clearRecordFormState());
  }, [dispatch]);

  const nextStep = useCallback(() => {
    dispatch(nextFormStep());
  }, [dispatch]);

  const prevStep = useCallback(() => {
    dispatch(prevFormStep());
  }, [dispatch]);

  const onChangeRecordFormValue = useCallback(
    (fieldName, value) => {
      dispatch(updateRecordFormField({ fieldName, value }));
    },
    [dispatch]
  );

  const handleCreateMedicalRecord = useCallback(
    async (_userId, _medicalRecordFormValues) => {
      dispatch(updateLoading(true));
      const fecha_registro_formatted = moment(
        _medicalRecordFormValues?.fecha_registro,
        "DD/MM/YYYY"
      ).toISOString();
      const recordBody = {
        id_paciente: _userId,

        ..._medicalRecordFormValues,
        /** Format/sanitize data  by overriding the unformatted fields*/

        fecha_registro: fecha_registro_formatted,
        electro_pdf: _medicalRecordFormValues?.electro_pdf?.trim(),
      };
      try {
        console.log("[Add Medical Record] - Creating...");
        await createMedicalRecord(_userId, recordBody);

        dispatch(clearRecordFormState());
        openNotification("success", "Registro médico creado!");

        setVisibleModal(false);
      } catch (error) {
        console.log(
          "[Add Medical Record] - Error creando registro médico: ",
          error
        );
        openNotification("error", "Error creando registro médico.");
        if (error.lenght && "message" in error[0]) {
          dispatch(updateError(error[0].message));
        } else if ("message" in error) {
          dispatch(updateError(error.message));
        } else {
          dispatch(updateError(error));
        }
      } finally {
        dispatch(updateLoading(false));
      }
    },
    [dispatch, setVisibleModal]
  );

  return {
    visibleModal,
    setVisibleModal,

    handleOnClose,
    onChangeRecordFormValue,
    handleCreateMedicalRecord,

    error,
    loading,
    formStep,
    recordFormValues: medicalRecordFormValues,
    nextStep,
    prevStep,
  };
}
