import React from "react";
import { Button, Form, Modal, Row, Steps, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Global state
import { useCreateMedicalRecord } from "./useCreateRegister";

// Components
import FormRegistroBasico from "./registroSteps/FormBasico";
import FormBiomarcadores from "./registroSteps/FormBiomarcadores";
import FormErgoespirometria from "./registroSteps/FormErgoespirometria";

// Constants
const { Title } = Typography;
const { Step } = Steps;

const FormRegistro = () => {
  /** Forms intanced here to clear the error messages when closing modal on x button */
  const [formBasic] = Form.useForm();
  const [formBiomarquers] = Form.useForm();
  const [formErgospirometry] = Form.useForm();

  const { visibleModal, setVisibleModal, formStep, handleOnClose } =
    useCreateMedicalRecord();

  /** Close modal and clear input errors for each step form */
  const handleOnCancel = () => {
    handleOnClose();
    formBasic.resetFields();
    formBiomarquers.resetFields();
    formErgospirometry.resetFields();
  };
  const steps = [
    {
      title: "Datos Básicos",
      content: <FormRegistroBasico form={formBasic} />,
    },
    {
      title: "Biomarcadores",
      content: <FormBiomarcadores form={formBiomarquers} />,
    },
    {
      title: "Ergoespirometria",
      content: <FormErgoespirometria form={formErgospirometry} />,
    },
  ];

  return (
    <div className="flex flex-col">
      <Button
        className="shadow-neo-button rounded-neo-button mx-auto px-6"
        type="primary"
        onClick={() => setVisibleModal(true)}
        icon={<PlusOutlined />}
      >
        Crear Registro Médico
      </Button>

      <Modal
        maskClosable={false}
        footer={null}
        onCancel={handleOnCancel}
        width={600}
        visible={visibleModal}
      >
        <>
          <Row className="mb-4">
            <Title level={4} className="font-bold w-full text-center">
              Creación de registro médico
            </Title>
          </Row>
          <Steps current={formStep} className="mt-4" responsive size="small">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div className="w-full mt-6">{steps[formStep].content}</div>
        </>
      </Modal>
    </div>
  );
};

export default FormRegistro;
