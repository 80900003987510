import axios_core from "axios";
import { api_enpoint } from "config/config";

export const createRequest = () => {
  return axios_core.create({ baseURL: api_enpoint });
};

export const throwErrors = (err) => {
  throw err;
};
