import { InputNumber, Input, Form, Select, Row } from "antd";

// Consts
import { alimentMeasures } from "../../../constants";
const { TextArea } = Input;
const { Option } = Select;

const EditableAlimentCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const descriptionLen = Math.ceil(record?.descripcion?.length / 50);
  const intercambiosLen = Math.ceil((record?.intercambios?.length ?? 0) / 32);

  const textAreaRows = Math.max(descriptionLen, intercambiosLen); // this way both of the text areas are the same heigth, but you dont have to scroll to see the full contentn of both of them

  const formItems = {
    Alimento: (
      <Form.Item
        name={dataIndex}
        className="m-0"
        rules={[
          {
            required: true,
            message: `Ingrese ${title}!`,
            whitespace: true, //no empty strings on required field title.
          },
        ]}
      >
        <TextArea
          className="w-full rounded-lg shadow-neo-field"
          rows={textAreaRows}
        />
      </Form.Item>
    ),
    Porción: (
      <Row justify="space-between">
        <Form.Item
          name="porcion"
          className="m-0"
          rules={[
            {
              required: true,
              message: `Ingrese ${title}!`,
            },
          ]}
        >
          <InputNumber className="rounded-lg shadow-neo-field w-16" />
        </Form.Item>
        <Form.Item
          name="medida"
          className="m-0 my-select-container "
          initialValue={record?.medida}
        >
          <Select
            placeholder="Seleccione una medida"
            className="rounded-lg text-left shadow-neo-field"
          >
            {alimentMeasures.map((e) => (
              <Option key={"option-" + e.val} value={e.val}>
                {e.tag}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Row>
    ),
    Intercambios: (
      <Form.Item name={dataIndex} className="m-0">
        <TextArea
          className="w-full rounded-lg shadow-neo-field"
          rows={textAreaRows}
        />
      </Form.Item>
    ),
  };

  return <td {...restProps}>{editing ? formItems[title] : children}</td>;
};
export default EditableAlimentCell;
