import { useCallback } from "react";

// Redux Toolkit
import { useDispatch, useSelector } from "react-redux";

import {
  updateIsReady,
  updatePatientList,
  updateSelectedPatient,
} from "features/patient/patientSlice";

import { updateDatabaseUser } from "features/user/userSlice";
import { getPatientsInfo, getUserInfo } from "services/userService";

export function useUserInfo() {
  const dispatch = useDispatch();

  // Retrieve global state variables
  const loading = useSelector((state) => state.patient.loading);
  const firebaseUser = useSelector((state) => state.user.firebaseUser);

  const handleGetPatientsInfo = useCallback(
    async (userId) => {
      try {
        const res = await getPatientsInfo(userId);
        const patientList = res.data.sort((a, b) => {
          const nombreA = a.nombres.toUpperCase();
          const nombreB = b.nombres.toUpperCase();
          return nombreA < nombreB ? -1 : nombreA > nombreB ? 1 : 0;
        });

        dispatch(updateSelectedPatient(patientList[0]));
        dispatch(updatePatientList(patientList));
        dispatch(updateIsReady(true));
      } catch (err) {
        console.log(
          "Error obteniendo información de los pacientes del usuario loggeado:",
          err
        );
      } finally {
        // setLoadingPlan(false);
      }
    },
    [dispatch]
  );

  const handleGetUserInfo = useCallback(async () => {
    try {
      let res = await getUserInfo(firebaseUser?.uid);
      let dbUser = res.data;
      console.log("custom hook");
      dispatch(updateDatabaseUser(dbUser));
      await handleGetPatientsInfo(firebaseUser?.uid);
    } catch (err) {
      console.log("Error obteniendo información del usuario loggeado:", err);
    } finally {
      // setLoadingPlan(false);
    }
  }, [dispatch, handleGetPatientsInfo, firebaseUser]);

  return {
    handleGetUserInfo,
    loading,
  };
}
