import React, { useState } from "react";
import { Col, Row, Typography } from "antd";

// Utils
import {
  mapHourToColor,
  mapMinsToTime,
  openNotification,
} from "../../utils/utils";

// Components
import DeleteButton from "../@D4D/DeleteButton";
import DevInfo from "components/@D4D/DevInfo";

// Services
import { deleteMeal } from "services/mealService";

// Redux
import { useSelector } from "react-redux";
import AlimentTable from "./MealTable/AlimentTable";

// Const
const { Title, Text } = Typography;

const MealCard = ({
  mealData,
  getPlanByPatient,
  allowDelete = false,
  hideId = false,
  editable = false,
}) => {
  const selectedPatient = useSelector((state) => state.patient.selected);

  const cardColorTag = mapHourToColor(mealData.hora, "tag");
  const cardClassName = `group w-full bg-white rounded-2xl mb-4 shadow-neo-card border-2 border-transparent hover:border-${cardColorTag}`;

  const [loadingDeleteMeal, setLoadingDeleteMeal] = useState(false);

  const handleDeleteMeal = async () => {
    setLoadingDeleteMeal(true);
    try {
      await deleteMeal(mealData?.id_comida);
      openNotification(
        "success",
        "Comida eliminada!",
        "La comida se ha eliminado correctamente"
      );
      getPlanByPatient(selectedPatient?.id_paciente);
    } catch (e) {
      console.log("[mealCard - Delete]", e);
      openNotification("error", "Error eliminando comida.");
    } finally {
      setLoadingDeleteMeal(false);
    }
  };

  return (
    <div className={cardClassName}>
      {/* Color stripe */}
      <div
        className={`h-4 rounded-t-xl bg-${cardColorTag}`}
        style={{ background: mapHourToColor(mealData.hora) }}
      />
      <div className="p-6 pt-3">
        <Row className="flex justify-center items-center">
          <Col span={20}>
            <Row>
              <Col>
                {!hideId && (
                  <DevInfo tag={"id_comida"} value={mealData.id_comida} />
                )}
                <Title level={4} className="font-bold w-full">
                  {mealData?.tipo}
                </Title>
              </Col>
              {allowDelete && (
                <Col className="ml-2 transition-opacity duration-150 ease-in opacity-0 group-hover:opacity-100">
                  <DeleteButton
                    title={"Eliminar comida"}
                    onConfirm={handleDeleteMeal}
                    confirmLoading={loadingDeleteMeal}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col span={4} className="text-right">
            <Text
              className="font-bold w-full"
              style={{
                borderBottom: `2px solid ${mapHourToColor(
                  mealData.hora,
                  "val"
                )}`,
              }}
            >
              {mapMinsToTime(mealData.hora)}
            </Text>
          </Col>
        </Row>

        <Row className="flex flex-col justify-between h-5/6">
          <AlimentTable
            hideId={hideId}
            editable={editable}
            mealData={mealData}
            getPlanByPatient={getPlanByPatient}
          />
        </Row>
      </div>
    </div>
  );
};

export default MealCard;
