import React from "react";
import { Row, Col, Typography, Dropdown, Button, Menu } from "antd";

// Components
import LinearGauge from "../dashboards/LinearGauge";

// Redux
import { useSelector } from "react-redux";

// Components
import UploadExams from "components/passport/UploadExams";

// Constants
import { BIOMARCADORES_META } from "../../constants";
const { Title } = Typography;

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_self" rel="noopener noreferrer" href="#">
        14 de abril de 2022
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_self" rel="noopener noreferrer" href="#">
        3 de junio de 2022
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_self" rel="noopener noreferrer" href="#">
        23 de octubre de 2022
      </a>
    </Menu.Item>
  </Menu>
);

const Biomarcadores = () => {
  const selectedRecord = useSelector((state) => state.record.selected);
  const {
    hemoglobina,
    hematocitos,
    leucocitos,
    plaquetas,
    ferritina,
    colesterol_total,
    colesterol_ldl,
    colesterol_hdl,
    tag,
    glucosa,
  } = selectedRecord;
  return (
    <Row className="justify-center mb-6 p-8 bg-white rounded-2xl shadow-neo-card">
      <Col xs={23} className="flex flex-col justify-center items-center ">
        <Row className="w-full justify-center">
          <Title level={3}>Biomarcadores</Title>
        </Row>

        <UploadExams />
        <Dropdown
          trigger={"click"}
          className="ml-auto"
          overlay={menu}
          placement="topRight"
        >
          <Button className="shadow-neo-button rounded-neo-button ml-auto">
            Examenes anteriores
          </Button>
        </Dropdown>

        <Row className="justify-center my-6">
          <LinearGauge
            GWidth={500}
            title={"Hemoglobina"}
            value={hemoglobina}
            suffix={BIOMARCADORES_META.hemoglobina.suffix}
            refValues={BIOMARCADORES_META.hemoglobina.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Hematocrito"}
            value={hematocitos}
            suffix={BIOMARCADORES_META.hematocrito.suffix}
            refValues={BIOMARCADORES_META.hematocrito.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Leucocitos"}
            value={leucocitos}
            suffix={BIOMARCADORES_META.leucocitos.suffix}
            refValues={BIOMARCADORES_META.leucocitos.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Plaquetas"}
            value={plaquetas}
            suffix={BIOMARCADORES_META.plaquetas.suffix}
            refValues={BIOMARCADORES_META.plaquetas.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Ferritina"}
            value={ferritina}
            suffix={BIOMARCADORES_META.ferritina.suffix}
            refValues={BIOMARCADORES_META.ferritina.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Colesterol total"}
            value={colesterol_total}
            suffix={BIOMARCADORES_META.colesterol_total.suffix}
            refValues={BIOMARCADORES_META.colesterol_total.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Colesterol LDL"}
            value={colesterol_ldl}
            suffix={BIOMARCADORES_META.colesterol_ldl.suffix}
            refValues={BIOMARCADORES_META.colesterol_ldl.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Colesterol HDL"}
            value={colesterol_hdl}
            suffix={BIOMARCADORES_META.colesterol_hdl.suffix}
            refValues={BIOMARCADORES_META.colesterol_hdl.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"TAG"}
            value={tag}
            suffix={BIOMARCADORES_META.tag.suffix}
            refValues={BIOMARCADORES_META.tag.ranges}
          />
          <LinearGauge
            GWidth={500}
            title={"Glucosa"}
            value={glucosa}
            suffix={BIOMARCADORES_META.glucosa.suffix}
            refValues={BIOMARCADORES_META.glucosa.ranges}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default Biomarcadores;
