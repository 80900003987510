import ana_ekg from "../assets/docs/electrocardiograma/ANA CRISTINA SANABRIA SANCHEZ.pdf";
import camila_ekg from "../assets/docs/electrocardiograma/CAMILA VALBUENA.pdf";
import daniela_ekg from "../assets/docs/electrocardiograma/DANIELA LIZETH CARRERA.pdf";
import elizabeth_ekg from "../assets/docs/electrocardiograma/ELIZABETH CASTAÑO.pdf";
import estefania_ekg from "../assets/docs/electrocardiograma/ESTEFANIA HERRERA.pdf";
import jannie_ekg from "../assets/docs/electrocardiograma/JANNIE SALCEDO.pdf";
import yeny_ekg from "../assets/docs/electrocardiograma/YENY COLMENARES.pdf";
import yennifer_ekg from "../assets/docs/electrocardiograma/YENNIFER DUCUARA.pdf";
import jessenia_ekg from "../assets/docs/electrocardiograma/JESSENIA MENESES.pdf";
import jessica_ekg from "../assets/docs/electrocardiograma/JESSICA MARCELA PARRA.pdf";
import lina_ekg from "../assets/docs/electrocardiograma/LINA MABEL ROJAS.pdf";
import paula_ekg from "../assets/docs/electrocardiograma/PAULA ANDREA CARRASCO.pdf";

import camila_ergo from "../assets/docs/cpet/Ergo Camila Valbuena Abril 2021.PDF";
import elizabeth_ergo from "../assets/docs/cpet/Ergo Elizabeth Castano Abril 2021.PDF";
import estefania_ergo from "../assets/docs/cpet/Ergo Estefania Herrera Abril 2021.PDF";
import jessenia_ergo from "../assets/docs/cpet/Ergo Jessenia Meneses Abril 2021.PDF";
import lina_ergo from "../assets/docs/cpet/Ergo Lina Rojas Abril 2021.PDF";
import lizeth_ergo from "../assets/docs/cpet/Ergo Lizeth Carrero Abril 2021.PDF";
import milena_ergo from "../assets/docs/cpet/Ergo Milena Salcedo Abril 2021.PDF";
import yennifer_ergo from "../assets/docs/cpet/Ergo Yenifer Ducuara Abril 2021.PDF";
import yeny_ergo from "../assets/docs/cpet/Ergo Yeny Colmenares Abril 2021.PDF";

export const BIKERS = [
  {
    fecha_registro: { value: "2022-01-17" },
    ciclista: 1,
    nombres: "Ana Cristina",
    apellidos: "Sanabria Sanchez",
    cc: "1102548844",
    nacimiento: "5/2/1990",
    edad: 31,
    natural: "Zapatoca, Santander",
    procedente: "Paipa, Boyacá",
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "51.9",
    talla: "156",
    imc: "21.2",
    graso: "15.1",
    clasificacion_graso: "Alto",
    iaks: "1.16",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "50",
    graso_deseado: "13",
    somatotipo: "Endomorfa- Mesomorfa",
    electrocardiograma:
      "Ritmo sinusal, FC 54 lpm, eje normal, inversion de onda T en V1",
    electro_pdf: ana_ekg,
    ergo_pdf: null,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos:
      "Otitis media aguda en tratamiento, Infeccion por sars cov 2 (Nov 2020)",
    antecedentes_osteomusculares: "Osteosintesis clavicula izquierda (2014)",
    antecedentes_cardiovasculares:
      "Historia familiar de arritmia cardiaca  (Padre)",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: null,
    watts_max_absoluto: null,
    watts_max_relativo: null,
    fc_max: null,
    vo2_vt2_relativo: null,
    watts_vt2_absoluto: null,
    watts_vt2_relativo: null,
    fc_vt2: null,

    Hemoglobina: 16,
    Hematocrito: 51.4,
    Leucocitos: 7460,
    Plaquetas: 263000,
    Ferritina: 98.0,
    Colesterol_total: 189,
    Colesterol_LDL: 126.1,
    Colesterol_HDL: 41.5,
    TAG: 107,
    Glucosa: 86.5,
    laboratorios_sericos:
      "Hemoglobina:16 g/dl , Htcto:51.4% Leucocitos:7460 ,  Plaquetas:   263.000 , Ferritina:  98.0 Colesterol total: 189 mg/dl LDL:  126.1 mg/dl  , HDL: 41.5 mg/dl ,TAG:107 mg/dl   Glucosa: 86.5 mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-27" },
    ciclista: 2,
    nombres: "Camila Andrea",
    apellidos: "Valbuena Roa",
    cc: "1233889080",
    nacimiento: "2/18/1997",
    edad: 24,
    natural: "Bogotá, Colombia",
    procedente: "Bogotá, Colombia",
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "54.2",
    talla: "161.9",
    imc: "20.7",
    graso: "15.1",
    clasificacion_graso: "Alto",
    iaks: "1.08",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "53",
    graso_deseado: "13",
    somatotipo: "Endomorfa-Balanceada",
    electrocardiograma:
      "Ritmo sinusal, FC 50  lpm, eje normal, criterios electrocardiograficos de Hipertrofia ventricular izquierda.",
    electro_pdf: camila_ekg,
    ergo_pdf: camila_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos:
      "Sindrome de colon inrritable, salpingectomia derecha",
    antecedentes_osteomusculares: "Niega",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 61,
    watts_max_absoluto: 326,
    watts_max_relativo: 6.0,
    fc_max: 186,
    vo2_vt2_relativo: 58,
    watts_vt2_absoluto: 277,
    watts_vt2_relativo: 5.1,
    fc_vt2: 180,

    Hemoglobina: 15.3,
    Hematocrito: 48,
    Leucocitos: 6860,
    Plaquetas: 280000,
    Ferritina: 132,
    Colesterol_total: 175,
    Colesterol_LDL: 137.5,
    Colesterol_HDL: 27.3,
    TAG: 56,
    Glucosa: 85,

    conclusion_ergo:
      "\r\n1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 12%, al minuto dos 26%, al minuto tres 32%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 61 ml/min/kg ml/kg/min, a una carga de 326 W watts, logrando una carga relativa de 6,0\r\nW /kg, que sostiene por 33 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigeno",
    laboratorios_sericos:
      "Hemoglobina:15.3 g/dl Htcto:48%, Leucocitos:6860 , Plaquetas:280.000 Ferritina:132 ug/dl,  Colesterol total:175mg/dl LDL: 137.5mg/dl HDL: 27.3 mg/dl TAG:56mg/dl  Glucosa: 85 mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-31" },
    ciclista: 3,
    nombres: "Elizabeth",
    apellidos: "Castaño Quintero",
    cc: "1001477811",
    nacimiento: "10/20/2001",
    edad: 19,
    natural: "Rionegro, Antioquia",
    procedente: "Granada, Antioquia",
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "56.7",
    talla: "155.6",
    imc: "23.4",
    graso: "20.1",
    clasificacion_graso: "Alto",
    iaks: "1.20",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "54,8",
    graso_deseado: "18",
    somatotipo: "Endomorfa-Mesomorfa",
    electrocardiograma:
      "Ritmo sinusal FC 57  lpm, Inversion onda T de V1  - v3, Desviación ligera de eje a la derecha",
    electro_pdf: elizabeth_ekg,
    ergo_pdf: elizabeth_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Infeccion por covid-19 (Diciembre 2020)",
    antecedentes_osteomusculares:
      "Luxación clavicula derecha , luxación hombro izquierdo",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 54,
    watts_max_absoluto: 313,
    watts_max_relativo: 5.6,
    fc_max: 203,
    vo2_vt2_relativo: 53,
    watts_vt2_absoluto: 260,
    watts_vt2_relativo: 4.6,
    fc_vt2: 196,

    Hemoglobina: 15.6,
    Hematocrito: 48.9,
    Leucocitos: 4420,
    Plaquetas: 242000,
    Ferritina: 54.8,
    Colesterol_total: 238,
    Colesterol_LDL: 156.7,
    Colesterol_HDL: 66.3,
    TAG: 75,
    Glucosa: 89.6,

    conclusion_ergo:
      "\r\n1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 14%, al minuto dos 29%, al minuto tres 46%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 54 ml/min/kg ml/kg/min, a una carga de 313 W watts, logrando una carga relativa de 5,6\r\nW /kg, que sostiene por 47 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigen",
    laboratorios_sericos:
      "Hemoglobina:15,6 g/dl Htcto:48,9 Leucocitos:4420 , Plaquetas: 242.000, Ferritina: 54,8 Colesterol total:238 mg/dl LDL: 156.7mg/dl HDL:66,3mg/dl , TAG: 75 mg/dl Glucosa: 89,6 mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-07" },
    ciclista: 4,
    nombres: "Estefania",
    apellidos: "Herrera Marín",
    cc: "1037635055",
    nacimiento: "5/31/1994",
    edad: 26,
    natural: "Medellin, Antioquia",
    procedente: "Medellin, Antioquia",
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "51.9",
    talla: "162",
    imc: "19.8",
    graso: "14.6",
    clasificacion_graso: "Levemente elevado",
    iaks: "1.04",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "50,3",
    graso_deseado: "13",
    somatotipo: "Endo-Ectomorfa",
    electrocardiograma:
      "Ritmo sinusal, FC 54 lpm, eje 8 grados normal, no bloqueos  ni ondas de isquemia",
    electro_pdf: estefania_ekg,
    ergo_pdf: estefania_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos:
      "Sindrome de ovario Poliquistico, Endometriosis, Colecistectomia por colecistitis",
    antecedentes_osteomusculares: "Niega",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 61,
    watts_max_absoluto: 300,
    watts_max_relativo: 5.9,
    fc_max: 177,
    vo2_vt2_relativo: 52,
    watts_vt2_absoluto: 238,
    watts_vt2_relativo: 4.7,
    fc_vt2: 169,

    Hemoglobina: 15.4,
    Hematocrito: 47.6,
    Leucocitos: 6530,
    Plaquetas: 286000,
    Ferritina: 36.2,
    Colesterol_total: 130,
    Colesterol_LDL: 89.2,
    Colesterol_HDL: 29.4,
    TAG: 57,
    Glucosa: 86.6,

    conclusion_ergo:
      " 1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 21%, al minuto dos 32%, al minuto tres 37%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 61 ml/min/kg ml/kg/min, a una carga de 300 W watts, logrando una carga relativa de 5,9\r\nW /kg, que sostiene por 29 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigeno",
    laboratorios_sericos:
      "Hemoglobina:15,4g/dl , Htcto: 47.6% Leucocitos:6530  Plaquetas:286.000 Ferritina:36.2ug/L, Colesterol total: 130mg/dl LDL: 89.2 mg/dl HDL:29.4 mg/dl TAG:57mg/dl Glucosa: 86.6mg/dl",
  },
  {
    fecha_registro: { value: "2022-02-16" },
    ciclista: 5,
    nombres: "Jessica",
    apellidos: "Parra Rojas",
    cc: "1014268403",
    edad: 25,
    natural: null,
    procedente: null,
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "60.3",
    talla: "171",
    imc: "20.6",
    graso: "14.4",
    clasificacion_graso: "Adecuada",
    iaks: "1.03",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "58,1",
    graso_deseado: "12,4",
    somatotipo: "Ecto- Mesomorfa",
    electrocardiograma:
      "Ritmo sinusal, FC 35 lpm, Bloqueo incompleto de rama derecha, Bloqueo AV de primer grado,",
    electro_pdf: jessica_ekg,
    ergo_pdf: null,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Niega",
    antecedentes_osteomusculares: "Niega",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: null,
    watts_max_absoluto: null,
    watts_max_relativo: null,
    fc_max: null,
    vo2_vt2_relativo: null,
    watts_vt2_absoluto: null,
    watts_vt2_relativo: null,
    fc_vt2: null,

    Hemoglobina: 15.3,
    Hematocrito: 48,
    Leucocitos: 5030,
    Plaquetas: 207000,
    Ferritina: 62,
    Colesterol_total: 210,
    Colesterol_LDL: 154,
    Colesterol_HDL: 43.4,
    TAG: 63,
    Glucosa: 86.6,

    laboratorios_sericos:
      "Hemoglobina: 15,3g/dl Htcto:48%  Leucocitos:5030 Plaquetas:  207.000, Ferritina:62ug/L  Colesterol total: 210mg/dl LDL:154mg/dl HDL: 43.4mg/dl TAG:  63mg/dl Glucosa:86.6mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-17" },
    ciclista: 6,
    nombres: "Jessenia Alejandra",
    apellidos: "Meneses Gonzalez",
    cc: "1036660005",
    nacimiento: "6/18/1995",
    edad: 25,
    natural: null,
    procedente: null,
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "47.8",
    talla: "156.2",
    imc: "19.6",
    graso: "10.6",
    clasificacion_graso: " Adecuada",
    iaks: "1.08",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "47,8",
    graso_deseado: null,
    somatotipo: "Meso-Ectomorfa",
    electrocardiograma:
      "Ritmo sinusal, FC 49 lpm, no ondas bloqueos ni arritmias",
    electro_pdf: jessenia_ekg,
    ergo_pdf: jessenia_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Infeccion por covid-19 (Enero 2021)",
    antecedentes_osteomusculares: "Niega",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 60,
    watts_max_absoluto: 288,
    watts_max_relativo: 6.1,
    fc_max: 198,
    vo2_vt2_relativo: 55,
    watts_vt2_absoluto: 239,
    watts_vt2_relativo: 5.0,
    fc_vt2: 187,

    Hemoglobina: 16.1,
    Hematocrito: 47.9,
    Leucocitos: 4111,
    Plaquetas: 226000,
    Ferritina: 322,
    Colesterol_total: 219,
    Colesterol_LDL: 178,
    Colesterol_HDL: 30.4,
    TAG: 53,
    Glucosa: 85,

    conclusion_ergo:
      "1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 20%, al minuto dos 33%, al minuto tres 34%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 60 ml/min/kg ml/kg/min, a una carga de 288 W watts, logrando una carga relativa de 6,1\r\nW /kg, que sostiene por 46 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigeno",
    laboratorios_sericos:
      "Hemoglobina:16.1g/dl Htcto:47.9%  Leucocitos:4111  Plaquetas:226.000  Ferritina:322ug/L Colesterol total: 219mg/dl LDL:178mg/dl HDL:30.4mg/dl TAG: 53mg/dl Glucosa: 85mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-18" },
    ciclista: 7,
    nombres: "Lina Mabel",
    apellidos: "Rojas Zapata",
    cc: "1007297408",
    nacimiento: "10/30/2000",
    edad: 20,
    natural: "Bogotá, Colombia",
    procedente: "Bogotá, Colombia",
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "58.6",
    talla: "160",
    imc: "22.9",
    graso: "16.6",
    clasificacion_graso: "Alto",
    iaks: "1.19",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "55,9",
    graso_deseado: "15",
    somatotipo: "Endomorfa -Mesomorfa",
    electrocardiograma:
      "Ritmo sinusal FC 51 lpm, elevacion no significtava del segmento ST en V2.",
    electro_pdf: lina_ekg,
    ergo_pdf: lina_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Pielonefritis a los 8 años,",
    antecedentes_osteomusculares: "Resección escafoides accesorio (2015)",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 51,
    watts_max_absoluto: 325,
    watts_max_relativo: 5.5,
    fc_max: 191,
    vo2_vt2_relativo: 50,
    watts_vt2_absoluto: 279,
    watts_vt2_relativo: 4.8,
    fc_vt2: 181,

    Hemoglobina: 14.9,
    Hematocrito: 46.4,
    Leucocitos: 5990,
    Plaquetas: 333000,
    Ferritina: 35.1,
    Colesterol_total: 166,
    Colesterol_LDL: 112.9,
    Colesterol_HDL: 36.5,
    TAG: 83,
    Glucosa: 74.3,

    conclusion_ergo:
      "1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 15%, al minuto dos 28%, al minuto tres 32%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 51 ml/min/kg ml/kg/min, a una carga de 325 W watts, logrando una carga relativa de 5,5\r\nW /kg, que sostiene por 68 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigen",
    laboratorios_sericos:
      "Hemoglobina:14.9g/dl  Htcto: 46.4% Leucocitos:5990  Plaquetas:333.000 Ferritina:35.1ug/dl  Colesterol total: 166mg/dl LDL:112.9mg/dl  HDL: 36,5mg/dl TAG: 83mg/dl Glucosa:74.3mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-27" },
    ciclista: 8,
    nombres: "Liseth Daniela",
    apellidos: "Carrero Vasquez",
    cc: "1052392971",
    nacimiento: "4/18/2001",
    edad: 20,
    natural: null,
    procedente: null,
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "51.2",
    talla: "159.6",
    imc: "20.1",
    graso: "11.6",
    clasificacion_graso: "Adecuado",
    iaks: "1.11",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "51,2",
    graso_deseado: null,
    somatotipo: "Meso-Ectomorfa",
    electrocardiograma:
      "Bradicardia sinusal FC 51 lpm, eje normal, inversion de onda T en V1. No bloqueos ni hemibloqueos, no ondas de isquemia o lesión.",
    electro_pdf: daniela_ekg,
    ergo_pdf: lizeth_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Niega",
    antecedentes_osteomusculares: "Dolor gluteo mayor izquierdo.",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 52,
    watts_max_absoluto: 275,
    watts_max_relativo: 5.4,
    fc_max: 194,
    vo2_vt2_relativo: 51,
    watts_vt2_absoluto: 244,
    watts_vt2_relativo: 4.8,
    fc_vt2: 190,

    Hemoglobina: 16.2,
    Hematocrito: 51.4,
    Leucocitos: 6710,
    Plaquetas: 250000,
    Ferritina: 89.9,
    Colesterol_total: 82.6,
    Colesterol_LDL: 136.6,
    Colesterol_HDL: 32.8,
    TAG: 180,
    Glucosa: 82.6,

    conclusion_ergo:
      "1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 13%, al minuto dos 27%, al minuto tres 29%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 52 ml/min/kg ml/kg/min, a una carga de 275 W watts, logrando una carga relativa de 5,7\r\nW /kg, que sostiene por 00 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigen",
    laboratorios_sericos:
      "Hemoglobina:16.2mg/dl  Htcto:51.4% Leucocitos:6710  Plaquetas: 250.000, Ferritina:89.9ug/L Colesterol total: 82.6mg/dl LDL:136.6mg/dl  HDL: 32.8mg/dl TAG: 180mg/dl Glucosa: 82.6 mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-31" },
    ciclista: 9,
    nombres: "Yeny Lorena",
    apellidos: "Colmenares Colmenares",
    cc: "1052392971",
    nacimiento: "2/15/1991",
    edad: 30,
    natural: "Duitama, Boyacá",
    procedente: "Duitama, Boyacá",
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "54.7",
    talla: "160.5",
    imc: "21.2",
    graso: "14.2",
    clasificacion_graso: "Alto",
    iaks: "1.13",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "53,5",
    graso_deseado: "13",
    somatotipo: "Mesomorfa Balanceada",
    electrocardiograma:
      "Ritmo sinusal FC 61 lpm, inversion de onda T en V1, elevación transitoria del segmento ST en V2.",
    electro_pdf: yeny_ekg,
    ergo_pdf: yeny_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Niega",
    antecedentes_osteomusculares: "Niega",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 53,
    watts_max_absoluto: 325,
    watts_max_relativo: 6.0,
    fc_max: 190,
    vo2_vt2_relativo: 50,
    watts_vt2_absoluto: 262,
    watts_vt2_relativo: 4.8,
    fc_vt2: 182,

    Hemoglobina: 16.3,
    Hematocrito: 51.1,
    Leucocitos: 4680,
    Plaquetas: 270000,
    Ferritina: 109.3,
    Colesterol_total: 193,
    Colesterol_LDL: 126,
    Colesterol_HDL: 52.3,
    TAG: 69,
    Glucosa: 81,

    conclusion_ergo:
      "1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 13%, al minuto dos 24%, al minuto tres 31%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 53 ml/min/kg ml/kg/min, a una carga de 325 W watts, logrando una carga relativa de 6,0\r\nW /kg, que sostiene por 32 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigen",
    laboratorios_sericos:
      "Hemoglobina: 16.3g/dl Htcto: 51.1% Leucocitos:4680  Plaquetas:270.000 Ferritina:109.3ug/L Colesterol total: 193mg/dl LDL:126mg/dl  HDL:52.3mg/dl TAG: 69mg/dl Glucosa:81 mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-07" },
    ciclista: 10,
    nombres: "Jannie Milena",
    apellidos: "Salcedo Zambrano",
    cc: "1014192518",
    nacimiento: "5/14/1988",
    edad: 33,
    natural: null,
    procedente: null,
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "56.3",
    talla: "168.2",
    imc: "19.9",
    graso: "9.9",
    clasificacion_graso: "Adecuado",
    iaks: "1.07",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "56,3",
    graso_deseado: null,
    somatotipo: "Ecto- Mesomorfa",
    electrocardiograma:
      "Ritmo sinusal FC 58 lpm, bloqueo incompleto de rama derecha",
    electro_pdf: jannie_ekg,
    ergo_pdf: milena_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos:
      "Accidente cerebrovascular cardioembolico (2012) anticoagulada con Warfarina por 6 meses, Cierre de fosa oval percutaneo",
    antecedentes_osteomusculares:
      "Tendinopatia porcion larga biceps derecho, ruptura parcial del tendón del supraespinoso, trauma hombro izquierdo (2020)",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 55,
    watts_max_absoluto: 325,
    watts_max_relativo: 5.7,
    fc_max: 185,
    vo2_vt2_relativo: 52,
    watts_vt2_absoluto: 260,
    watts_vt2_relativo: 4.6,
    fc_vt2: 174,

    Hemoglobina: 14.8,
    Hematocrito: 46.9,
    Leucocitos: 7140,
    Plaquetas: 345000,
    Ferritina: 32.3,
    Colesterol_total: 259,
    Colesterol_LDL: 203.5,
    Colesterol_HDL: 40.1,
    TAG: 77,
    Glucosa: 84,

    conclusion_ergo:
      "1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 8%, al minuto dos 16%, al minuto tres 22%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 55 ml/min/kg ml/kg/min, a una carga de 325 W watts, logrando una carga relativa de 5,7\r\nW /kg, que sostiene por 71 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigen",
    laboratorios_sericos:
      "Hemoglobina: 14.8g/dl Htcto: 46.9% Leucocitos:7140  Plaquetas: 345.000 Ferritina:32.3ug/L  Colesterol total:259mg/dl LDL:203.5mg/dl  HDL: 40.1mg/dl TAG:77mg/dl  Glucosa: 84mg/dl",
  },
  {
    fecha_registro: { value: "2022-02-16" },
    ciclista: 11,
    nombres: "Paula Andrea",
    apellidos: "Carrasco Villalba",
    cc: "1073253458",
    nacimiento: "4/9/1999",
    edad: 22,
    natural: null,
    procedente: null,
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "54.1",
    talla: "159.8",
    imc: "21.2",
    graso: "13.3",
    clasificacion_graso: "Levemente elevado",
    iaks: "1.15",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "53,3",
    graso_deseado: "12,5",
    somatotipo: "Meso-Endomorfa",
    electrocardiograma:
      "Ritmo sinusal FC 49 lpm, eje normal inversion de onda T en V1 -V2-V3.",
    electro_pdf: paula_ekg,
    ergo_pdf: null,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos:
      "Trauma renal derecho grado IV, trauma abdominal cerrado,",
    antecedentes_osteomusculares: "Fractura de apofisis transverasL2-L3",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica:
      "No apto, no se recomienda la participación desde el punto de vista medico.",
    vo2_max_relativo: null,
    watts_max_absoluto: null,
    watts_max_relativo: null,
    fc_max: null,
    vo2_vt2_relativo: null,
    watts_vt2_absoluto: null,
    watts_vt2_relativo: null,
    fc_vt2: null,

    Hemoglobina: 15,
    Hematocrito: 48,
    Leucocitos: 8450,
    Plaquetas: 315000,
    Ferritina: 31.6,
    Colesterol_total: 162,
    Colesterol_LDL: 98.5,
    Colesterol_HDL: 51.1,
    TAG: 62,
    Glucosa: 79.7,

    laboratorios_sericos:
      "Hemoglobina: 15g/dl Htcto: 48% Leucocitos:8450   Plaquetas:315.000  Ferritina:31.6ug/L  Colesterol total: 162mg/dl  LDL: 98,5mg/dl HDL:51.1mg/dl TAG:62mg/dl, Glucosa:79.7mg/dl",
  },
  {
    fecha_registro: { value: "2022-01-17" },
    ciclista: 12,
    nombres: "Yennifer Tatiana",
    apellidos: "Ducuara Acevedo",
    cc: "1075295186",
    nacimiento: "1/31/1996",
    edad: 25,
    natural: null,
    procedente: null,
    deporte: "Ciclismo",
    modalidad: "Ruta",
    peso: "57.2",
    talla: "159.8",
    imc: "22.4",
    graso: "13.3",
    clasificacion_graso: "Alto",
    iaks: "1.22",
    clasificacion_muscular: "Adecuado",
    peso_deseado: "56,6",
    graso_deseado: "12,5",
    somatotipo: "Endomorfa-Mesomorfa",
    electrocardiograma:
      "Ritmo sinusal FC 65 lpm, Ligera desviación del eje a la derecha",
    electro_pdf: yennifer_ekg,
    ergo_pdf: yennifer_ergo,
    signos_vitales: { tas: 0, tad: 0, fc: 0, sato2: 0 },
    antecedentes_medicos: "Niega",
    antecedentes_osteomusculares: "Genovarum bilateral",
    antecedentes_cardiovasculares: "Niega",
    aptitud_medica: "Apta, Deportista que puede participar sin restricciones",
    vo2_max_relativo: 59,
    watts_max_absoluto: 325,
    watts_max_relativo: 5.8,
    fc_max: 201,
    vo2_vt2_relativo: 56,
    watts_vt2_absoluto: 273,
    watts_vt2_relativo: 4.9,
    fc_vt2: 189,

    Hemoglobina: 16.1,
    Hematocrito: 52.1,
    Leucocitos: 7300,
    Plaquetas: 309000,
    Ferritina: 74.2,
    Colesterol_total: 194,
    Colesterol_LDL: 151,
    Colesterol_HDL: 28.5,
    TAG: 69,
    Glucosa: 83,

    conclusion_ergo:
      "1.\tSe realiza test cumpliendo protocolos de bioseguridad\r\n2.\tEl registro electrocardiografico no present6  alteraciones  en  el ritmo, ni cambios significativos del segmento ST. Respuesta cronotrepica adecuada, indices de recuperaci6n  de la frecuencia cardiaca, al minuto uno 13%, al minuto dos 27%, al minuto tres 31%\r\n3.\tEl atleta logra un muy buen registro para el deporte y la modalidad con un V'O2max de 59 ml/min/kg ml/kg/min, a una carga de 325 W watts, logrando una carga relativa de 5,8\r\nW /kg, que sostiene por 36 seg., con umbrales ventilatorios muy cercanos al maxima consume de oxigen",
    laboratorios_sericos:
      "Hemoglobina:16.1g/dl  Htcto: 52.1%  Leucocitos:7300  Plaquetas:309.000  Ferritina:74.2ug/L    Colesterol total:194mg/dl LDL:151mg/dl HDL:28.5mg/dl  TAG: 69mg/dl Glucosa:83mg/dl",
  },
];
