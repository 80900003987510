import React from "react";

// Redux
import { useSelector } from "react-redux";

// Components
import MealCard from "./MealCard";

const MealConfirmation = () => {
  const mealForm = useSelector((state) => state.nutrition.formMeal);

  return (
    <div>
      <MealCard mealData={mealForm} hideId={true} />
    </div>
  );
};

export default MealConfirmation;
