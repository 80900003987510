import React, { useState } from "react";
import { Button, DatePicker } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";

const PreviewDate = ({ date, setDate }) => {
  const [allowEditDate, setAllowEditDate] = useState(false);

  const handleDateChange = (date, dateString) => {
    setDate(dateString);
    console.log(date, dateString);
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current >= moment().endOf("day");
  };

  return (
    <div>
      {allowEditDate ? (
        <div className="text-right">
          <DatePicker
            allowClear={false}
            defaultValue={moment(date, "DD-MM-YYYY")}
            value={moment(date, "DD-MM-YYYY")}
            disabledDate={disabledDate}
            format={"DD-MM-YYYY"}
            className="w-full rounded-lg shadow-neo-field my-2"
            onChange={handleDateChange}
          />
          <Button
            type="link"
            onClick={() => {
              setAllowEditDate(false);
            }}
            className="ml-auto"
            size="small"
          >
            Confirmar
          </Button>
        </div>
      ) : (
        <>
          <div style={{ fontStyle: "italic" }}>
            Los biomarcadores serán cargados con la siguiente fecha:{"  "}
          </div>
          <Text keyboard>{date}</Text>
          <Button
            type="link"
            onClick={() => {
              setAllowEditDate(true);
            }}
            size="small"
          >
            Cambiar fecha
          </Button>
        </>
      )}
    </div>
  );
};

export default PreviewDate;
