// HEADER

export const headerTitles = Object.freeze({
  //rol 1: admin, rol 2: patient
  passport: { 1: "Pasaportes", 2: "Mi pasaporte" },
  dashboard: { 1: "Dashboards", 2: "Mi dashboard" },
  calendar: { 1: "Calendarios", 2: "Mi calendario" },
  nutritional: { 1: "Planes nutrición", 2: "Mi plan nutricional" },
});

// NUTRITION

export const mainColors = Object.freeze({
  gray: { tag: "gray-300", val: "#D1D5DB" },
  orange: { tag: "orange-500", val: "#F97316" },
  yellow: { tag: "yellow-400", val: "#FBBF24" },
  lime: { tag: "lime-500", val: "#84CC16" },
  teal: { tag: "teal-500", val: "#14B8A6" },
  purple: { tag: "purple-600", val: "#9333EA" },
  fuchsia: { tag: "fuchsia-900", val: "#701A75" },
});

export const alimentMeasures = [
  { val: "Unidades", tag: "Unidad(es)" },
  { val: "Porciones diarias", tag: "Porción diaria" },
  { val: "Gramos", tag: "Gramos" },
  { val: "Mililitros", tag: "Mililitros" },
  { val: "Cucharadas", tag: "Cucharadas" },
  { val: "Vasos", tag: "Vasos" },
  { val: "Capsulas diarias", tag: "Capsulas diaria" },
];

export const mealNames = [
  { val: "Desayuno", tag: "Desayuno" },
  { val: "Medias nueves", tag: "Medias nueves" },
  { val: "Almuerzo", tag: "Almuerzo" },
  { val: "Merienda", tag: "Merienda" },
  { val: "Cena", tag: "Cena" },
  { val: "Recena", tag: "Recena" },
];

// CALENDARIO

export const somatotipo = [
  { val: "Ectomorfo", tag: "Ectomorfo" },
  { val: "Mesomorfo", tag: "Mesomorfo" },
  { val: "Endomorfo", tag: "Endomorfo" },
  { val: "Meso-Ectomorfa", tag: "Meso-Ectomorfa" },
  { val: "Endo-Ectomorfa", tag: "Endo-Ectomorfa" },
  { val: "Endomorfa-Balanceada", tag: "Endomorfa-Balanceada" },
  { val: "Endomorfa-Mesomorfa", tag: "Endomorfa-Mesomorfa" },
];

export const clasificacionMuscular = [
  { val: "Bajo", tag: "Bajo" },
  { val: "Normal", tag: "Normal" },
  { val: "Promedio", tag: "Promedio" },
  { val: "Alto", tag: "Alto" },
];

export const clasificacionGraso = [
  { val: "Bajo", tag: "Bajo" },
  { val: "Normal", tag: "Normal" },
  { val: "En forma", tag: "En forma" },
  { val: "Alto", tag: "Alto" },
];

export const taskActivities = [
  { val: "Acondicionamiento general", tag: "Acondicionamiento general" },
  { val: "Actividad física", tag: "Actividad física" },
  { val: "Ciclismo", tag: "Ciclismo" },
  { val: "Gimnasio", tag: "Gimnasio" },
  { val: "Gimnasia", tag: "Gimnasia" },
  { val: "Running", tag: "Running" },
];
export const taskMeasures = [
  { val: "Kilometros", tag: "Km." },
  { val: "Minutos", tag: "Min." },
  { val: "Pasos", tag: "Pasos" },
  { val: "Repeticiones", tag: "Repeticiones" },
  { val: "Series", tag: "Series" },
];

// BIBLIOTECAS

export const libraryGroups = Object.freeze({
  1: "Alimentos",
  2: "Tareas",
});

// BIOMARCADORES RANGOS

export const BIOMARCADORES_META = Object.freeze({
  hemoglobina: {
    suffix: "g/dl",
    ranges: {
      global: { min: 0, max: 20 },
      healthy: { min: 12, max: 16.4 },
    },
  },
  hematocrito: {
    suffix: "%",
    ranges: {
      global: { min: 0, max: 100 },
      healthy: { min: 36, max: 52 },
    },
  },
  leucocitos: {
    suffix: "",
    ranges: {
      global: { min: 0, max: 12000 },
      healthy: { min: 5000, max: 10000 },
    },
  },
  plaquetas: {
    suffix: "",
    ranges: {
      global: { min: 0, max: 500000 },
      healthy: { min: 150000, max: 450000 },
    },
  },
  ferritina: {
    suffix: "ug/L",
    ranges: { global: { min: 0, max: 500 }, healthy: { min: 30, max: 400 } },
  },
  colesterol_total: {
    suffix: "mg/dl",
    ranges: { global: { min: 0, max: 300 }, healthy: { min: 0, max: 200 } },
  },
  colesterol_ldl: {
    suffix: "mg/dl",
    ranges: { global: { min: 0, max: 300 }, healthy: { min: 0, max: 130 } },
  },
  colesterol_hdl: {
    suffix: "mg/dl",
    ranges: { global: { min: 0, max: 150 }, healthy: { min: 40, max: 100 } },
  },
  tag: {
    suffix: "mg/dl",
    ranges: { global: { min: 0, max: 200 }, healthy: { min: 0, max: 150 } },
  },
  glucosa: {
    suffix: "mg/dl",
    ranges: { global: { min: 0, max: 120 }, healthy: { min: 60, max: 100 } },
  },
});

/** ERGOESPIROMETRIA RANGOS */
export const ERGOESPIROMETIRA_META = Object.freeze({
  vo2_max_rel: {
    suffix: "ml/kg/min",
    ranges: {
      global: { min: 0, max: 100 },
    },
  },
  watts_max_rel: {
    suffix: "W/kg",
    ranges: {
      global: { min: 0, max: 10 },
    },
  },
  watts_max_abs: {
    suffix: "W",
    ranges: {
      global: { min: 0, max: 1000 },
    },
  },
  fc_max: {
    suffix: "lpm",
    ranges: {
      global: { min: 0, max: 220 },
    },
  },
  vo2_vt2_rel: {
    suffix: "ml/kg/min",
    ranges: {
      global: { min: 0, max: 100 },
    },
  },
  watts_vt2_abs: {
    suffix: "W",
    ranges: {
      global: { min: 0, max: 1000 },
    },
  },
  watts_vt2_rel: {
    suffix: "W/kg",
    ranges: {
      global: { min: 0, max: 10 },
    },
  },
  fc_vt2: {
    suffix: "lpm",
    ranges: {
      global: { min: 0, max: 220 },
    },
  },
});

// PACIENTE FORM

export const GRUPO_SANGUINEO_OPTIONS = [
  { val: "A", tag: "A" },
  { val: "B", tag: "B" },
  { val: "O", tag: "O" },
  { val: "AB", tag: "AB" },
  { val: "Ni", tag: "Niega" },
];

export const RH_OPTIONS = [
  { val: "+", tag: "Positivo (+)" },
  { val: "-", tag: "Negativo (-)" },
  { val: "Ni", tag: "Niega" },
];

export const GENERO_OPTIONS = [
  { val: "Masculino", tag: "Masculino" },
  { val: "Femenino", tag: "Femenino" },
];

export const DEPORTE_OPTIONS = [
  { val: "Paciente sedentario", tag: "Paciente sedentario" },
  { val: "Ciclismo", tag: "Ciclismo" },
  { val: "Atletismo", tag: "Atletismo" },
  { val: "Tenis", tag: "Tenis" },
  { val: "Otro", tag: "Otro" },
];

export const MODALIDAD_OPTIONS = [
  { val: "Sin Deporte", tag: "Sin Deporte" },
  { val: "Profesional", tag: "Profesional" },
  { val: "Actividad Física Regular", tag: "Actividad Física Regular" },
  { val: "Semifondo 10 - 20 k", tag: "(Atletismo) Semifondo 10 - 20 km" },
  { val: "Caminata ocasional", tag: "(Sedentario) Caminata ocasional." },
];
