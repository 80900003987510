import React, { useEffect } from "react";
import { Button, Col, Divider, Form, Input, InputNumber, Row } from "antd";
import { useSelector } from "react-redux";

// Global state
import { useCreateMedicalRecord } from "../useCreateRegister";

// Constants
import { ERGOESPIROMETIRA_META } from "../../../../constants";

const FormErgoespirometria = ({ form }) => {
  const selectedPatient = useSelector((state) => state.patient.selected);

  const {
    recordFormValues,
    onChangeRecordFormValue,
    handleCreateMedicalRecord,
    prevStep,
    loading,
  } = useCreateMedicalRecord();

  const onFinish = async () => {
    await handleCreateMedicalRecord(
      selectedPatient?.id_paciente,
      recordFormValues
    );
  };

  // Update form values from global storage
  useEffect(() => {
    form.setFieldsValue({
      vo2_max_rel: recordFormValues?.vo2_max_rel,
      watts_max_rel: recordFormValues?.watts_max_rel,
      watts_max_abs: recordFormValues?.watts_max_abs,
      fc_max: recordFormValues?.fc_max,
      vo2_vt2_rel: recordFormValues?.vo2_vt2_rel,
      watts_vt2_abs: recordFormValues?.watts_vt2_abs,
      watts_vt2_rel: recordFormValues?.watts_vt2_rel,
      fc_vt2: recordFormValues?.fc_vt2,
      ergo_pdf: recordFormValues?.ergo_pdf,
      conclusion_ergo: recordFormValues?.conclusion_ergo,
    });
  }, [recordFormValues, form]);

  return (
    <Form
      form={form}
      className="w-full"
      onFinish={onFinish}
      layout="vertcial"
      name="form-registro-medico-ergo"
      initialValues={recordFormValues}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="vo2_max_rel"
            label={`VO2 Max Relativo (${ERGOESPIROMETIRA_META.vo2_max_rel.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.vo2_max_rel.ranges.global.min,
                max: ERGOESPIROMETIRA_META.vo2_max_rel.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.vo2_max_rel.ranges.global.min}
              max={ERGOESPIROMETIRA_META.vo2_max_rel.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("vo2_max_rel", val);
              }}
              placeholder="VO2 Max Relativo..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="watts_max_rel"
            label={`Watts Max Relativo (${ERGOESPIROMETIRA_META.watts_max_rel.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.watts_max_rel.ranges.global.min,
                max: ERGOESPIROMETIRA_META.watts_max_rel.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.watts_max_rel.ranges.global.min}
              max={ERGOESPIROMETIRA_META.watts_max_rel.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("watts_max_rel", val);
              }}
              placeholder="Watts Max Relativo..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="watts_max_abs"
            label={`Watts Max Absoluto (${ERGOESPIROMETIRA_META.watts_max_abs.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.watts_max_abs.ranges.global.min,
                max: ERGOESPIROMETIRA_META.watts_max_abs.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.watts_max_abs.ranges.global.min}
              max={ERGOESPIROMETIRA_META.watts_max_abs.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("watts_max_abs", val);
              }}
              placeholder="Watts Max Absoluto..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="fc_max"
            label={`FC Max (${ERGOESPIROMETIRA_META.fc_max.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.fc_max.ranges.global.min,
                max: ERGOESPIROMETIRA_META.fc_max.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.fc_max.ranges.global.min}
              max={ERGOESPIROMETIRA_META.fc_max.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("fc_max", val);
              }}
              placeholder="FC Max..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="vo2_vt2_rel"
            label={`VO2 VT2 Relativo (${ERGOESPIROMETIRA_META.vo2_vt2_rel.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.vo2_vt2_rel.ranges.global.min,
                max: ERGOESPIROMETIRA_META.vo2_vt2_rel.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.vo2_vt2_rel.ranges.global.min}
              max={ERGOESPIROMETIRA_META.vo2_vt2_rel.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("vo2_vt2_rel", val);
              }}
              placeholder="VO2 VT2 Relativo..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="watts_vt2_abs"
            label={`Watts VT2 Absoluto (${ERGOESPIROMETIRA_META.watts_vt2_abs.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.watts_vt2_abs.ranges.global.min,
                max: ERGOESPIROMETIRA_META.watts_vt2_abs.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.watts_vt2_abs.ranges.global.min}
              max={ERGOESPIROMETIRA_META.watts_vt2_abs.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("watts_vt2_abs", val);
              }}
              placeholder="Watts VT2 Absoluto..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="watts_vt2_rel"
            label={`Watts VT2 Relativo (${ERGOESPIROMETIRA_META.watts_vt2_rel.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.watts_vt2_rel.ranges.global.min,
                max: ERGOESPIROMETIRA_META.watts_vt2_rel.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.watts_vt2_rel.ranges.global.min}
              max={ERGOESPIROMETIRA_META.watts_vt2_rel.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("watts_vt2_rel", val);
              }}
              placeholder="Watts VT2 Relativo..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="fc_vt2"
            label={`FC VT2 (${ERGOESPIROMETIRA_META.fc_vt2.suffix})`}
            rules={[
              {
                type: "number",
                min: ERGOESPIROMETIRA_META.fc_vt2.ranges.global.min,
                max: ERGOESPIROMETIRA_META.fc_vt2.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={ERGOESPIROMETIRA_META.fc_vt2.ranges.global.min}
              max={ERGOESPIROMETIRA_META.fc_vt2.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("fc_vt2", val);
              }}
              placeholder="FC VT2..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider plain>
        <span className="text-gray-400">Ergoespirometría</span>
      </Divider>
      <Form.Item
        name="ergo_pdf"
        label="Enlace de Ergo. (pdf)"
        rules={[
          {
            type: "url",
            message: "Debe ser una url valida",
          },
        ]}
      >
        <Input
          className="w-full rounded-lg shadow-neo-field"
          placeholder="Ej: https://bucket-name.s3.region.amazonaws.com/..."
          onChange={(e) => {
            onChangeRecordFormValue("ergo_pdf", e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item name="conclusion_ergo" label="Conclusión Ergo.">
        <Input.TextArea
          onChange={(e) => {
            onChangeRecordFormValue("conclusion_ergo", e.target.value);
          }}
          placeholder="Conclusión de los resultados de ergo."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>
      <Row justify="end" align="bottom">
        <Button
          className="mx-4 shadow-neo-button rounded-neo-button"
          onClick={prevStep}
          disabled={loading}
        >
          Anterior
        </Button>
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button"
          htmlType="submit"
          loading={loading}
        >
          Crear registro médico
        </Button>
      </Row>
    </Form>
  );
};

export default FormErgoespirometria;
