import React from "react";
import { Col, Row, Tooltip } from "antd";

// Redux
import { useDispatch } from "react-redux";
import { updateTaskFormField } from "../../features/calendar/calendarSlice";

const PresetTask = ({ preset, hideMenu }) => {
  const dispatch = useDispatch();
  const { titulo, descripcion, cantidad, unidades, deporte } = preset;
  const handleOnClick = () => {
    dispatch(
      updateTaskFormField({ fieldName: "descripcion", val: descripcion })
    );
    dispatch(updateTaskFormField({ fieldName: "cantidad", val: cantidad }));
    dispatch(updateTaskFormField({ fieldName: "unidades", val: unidades }));
    dispatch(updateTaskFormField({ fieldName: "deporte", val: deporte }));
    hideMenu();
  };

  return (
    <Tooltip
      placement="right"
      title={
        <>
          {titulo}
          <br />
          {deporte}
          <br />
          {cantidad} {unidades}
          <br />
          {descripcion}
        </>
      }
    >
      <>
        <div className="w-full cursor-pointer p-1px mb-2 shadow-neo-button-sm rounded-md bg-gradient-to-tr from-gray-300 to-gray-300 hover:from-cyan-600 hover:to-teal-200 ">
          <Row
            justify="space-between"
            //FIXME: make the last: pseudoclass work
            className="px-3 m py-1 h-full w-full bg-white last:border-b-0 odd:bg-gray-200 rounded-md"
            onClick={handleOnClick}
          >
            <Col
              span={18}
              className="overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {deporte}
            </Col>
            <Col
              span={6}
              className="text-sm overflow-hidden whitespace-nowrap overflow-ellipsis text-gray-400"
            >
              {cantidad} {unidades}
            </Col>
          </Row>
        </div>
      </>
    </Tooltip>
  );
};

export default PresetTask;
