import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import moment from "moment";

// Components
import PresetsMenu from "../presets/PresetsMenu";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearTaskFormState,
  updateTaskFormField,
} from "../../features/calendar/calendarSlice";

// Services
import { createTask } from "services/taskService";

// Utils
import { openNotification } from "utils/utils";

// Consts
import { taskActivities, taskMeasures } from "../../constants";

const { Option } = Select;
const { Title } = Typography;
const layout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
    md: 8,
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
    md: 16,
  },
};
const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Por favor seleccione una fecha!",
    },
  ],
};

const AddTaskForm = ({ setVisible, getPatientTasks }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selectedPatient = useSelector((state) => state.patient.selected);

  const taskForm = useSelector((state) => state.calendar.taskForm);

  const [createLoading, setCreateLoading] = useState(false);

  const handleCreateTask = async (userId, taskBody) => {
    setCreateLoading(true);
    try {
      await createTask(userId, taskBody);
      getPatientTasks(userId);
      dispatch(clearTaskFormState());
      openNotification("success", "Tarea creada!");
      form.resetFields();
      setVisible(false);
    } catch (e) {
      console.log("[AddTaskForm] - Error creando tarea: ", e);
      openNotification("error", "Error creando tarea.");
    } finally {
      setCreateLoading(false);
    }
  };

  const onFinish = () => {
    //Id_tarea: first 7 digits of uid "-" last 8 digits of current miliseconds
    let taskBody = {
      id_tarea:
        selectedPatient?.id_paciente.slice(0, 7) +
        "-" +
        String(moment().valueOf()).slice(-8),
      id_paciente: selectedPatient?.id_paciente,
      titulo: taskForm?.titulo.trim(),
      fecha_hora: moment(taskForm?.fecha_hora, "DD/MM/YYYY").toISOString(),
      descripcion: taskForm?.descripcion,
      cantidad: taskForm?.cantidad,
      unidades: taskForm?.unidades,
      deporte: taskForm?.deporte,
      progreso: 0,
    };
    handleCreateTask(selectedPatient?.id_paciente, taskBody);
  };

  const disabledDate = (current) => {
    // Disable days before today and today
    return current && current < moment().endOf("day");
  };

  const onChangeTaskField = (fieldName, val) => {
    dispatch(updateTaskFormField({ fieldName, val }));
  };

  useEffect(() => {
    // update form values from redux
    form.setFieldsValue({
      titulo: taskForm?.titulo,
      deporte: taskForm?.deporte,
      unidades: taskForm?.unidades,
      cantidad: taskForm?.cantidad,
      descripcion: taskForm?.descripcion,
    });
  }, [taskForm, form]);

  return (
    <div className=" w-full px-6 py-3 pb-0 rounded-2xl">
      <Row className="mb-4">
        <Col span={22}>
          <Title level={4} className="font-bold w-full text-center">
            Agregar tarea
          </Title>
        </Col>

        <Col span={2}>
          <PresetsMenu groupId={2} />
        </Col>
      </Row>

      <Row>
        <Form
          form={form}
          className="w-full"
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          initialValues={taskForm}
        >
          <Form.Item name="fecha_hora" label="Fecha" {...config}>
            <DatePicker
              className="w-full rounded-lg shadow-neo-field"
              format="DD/MMM/YYYY"
              disabledDate={disabledDate}
              onChange={(date) => {
                onChangeTaskField(
                  "fecha_hora",
                  date ? date.format("DD/MM/YYYY") : null
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="titulo"
            label="Título"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              maxLength={64}
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Ej: Entrenamiento básico..."
              onChange={(e) => {
                onChangeTaskField("titulo", e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              {
                type: "number",
                min: 1,
                required: true,
              },
            ]}
          >
            <InputNumber
              min={1}
              onChange={(val) => {
                onChangeTaskField("cantidad", val);
              }}
              placeholder="Cantidad de repeticiones"
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
          <Form.Item
            className="my-select-container"
            name="unidades"
            label="Unidades"
            rules={[
              {
                required: true,
                message: "Seleccione una opción!",
              },
            ]}
          >
            <Select
              placeholder="Seleccione unidades!"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                onChangeTaskField("unidades", val);
              }}
            >
              {taskMeasures.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="my-select-container"
            name="deporte"
            label="Tipo de actividad"
            rules={[
              {
                required: true,
                message: "Seleccione una opción!",
              },
            ]}
          >
            <Select
              placeholder="Seleccione un tipo de actividad"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                onChangeTaskField("deporte", val);
              }}
            >
              {taskActivities.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="descripcion" label="Descripción">
            <Input.TextArea
              onChange={(e) => {
                onChangeTaskField("descripcion", e.target.value);
              }}
              placeholder="Realización, consideraciones ..."
              className="rounded-lg shadow-neo-field"
            />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              className="mx-4 shadow-neo-button rounded-neo-button"
              onClick={() => {
                setVisible(false);
              }}
              disabled={createLoading}
            >
              Cancelar
            </Button>
            <Button
              className="shadow-neo-button rounded-neo-button"
              type="primary"
              htmlType="submit"
              loading={createLoading}
            >
              Agregar
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </div>
  );
};

export default AddTaskForm;
