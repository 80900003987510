import React, { useState } from "react";
import { Layout, ConfigProvider } from "antd";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import es_ES from "antd/es/locale/es_ES"; // Spanish version of AntDesing components

// Utils
import useWindowDimensions from "./utils/window";

// Components
import ErrorBoundary from "./pages/ErrorBoundary";
import Main from "./pages/Main";
import Landing from "./pages/Landing";

const App = () => {
  const { height } = useWindowDimensions();

  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [logged, setLogged] = useState(false);

  return (
    <Router>
      <Redirect to="/" />
      <ConfigProvider locale={es_ES}>
        <ErrorBoundary>
          <Layout
            style={{ minHeight: height }}
            onClick={() => {
              if (visibleDropdown) {
                setVisibleDropdown(false);
              }
            }}
          >
            {logged ? (
              <Main
                visibleDropdown={visibleDropdown}
                setVisibleDropdown={setVisibleDropdown}
              />
            ) : (
              <Landing logged={logged} setLogged={setLogged} />
            )}
          </Layout>
        </ErrorBoundary>
      </ConfigProvider>
    </Router>
  );
};

export default App;
