import React, { useState } from "react";
import { Button, Popconfirm, Space, Spin } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

// Redux
import { useSelector } from "react-redux";

// Services
import { deleteAliment, updateAliment } from "services/alimentService";

// Utils
import { openNotification } from "utils/utils";

const ActionColumn = ({
  record,
  mealData,
  getPlanByPatient,

  form,
  isEditing,
  editingKey,
  setEditingKey,

  loadingEditAliment,
  setLoadingEditAliment,
}) => {
  const selectedPatient = useSelector((state) => state.patient.selected);

  const editable = isEditing(record);

  const [deletingKey, setDeletingKey] = useState("");
  const [loadingDeleteAliment, setLoadingDeleteAliment] = useState(false);

  const editRow = (record) => {
    form.setFieldsValue({
      descripcion: "",
      intercambios: "",
      ...record,
    });
    setEditingKey(record.id_alimento);
  };

  const forgetChanges = () => {
    setEditingKey("");
  };

  const handleDeleteAliment = async (record) => {
    setDeletingKey(record.id_alimento);
    setLoadingDeleteAliment(true);
    try {
      await deleteAliment(mealData?.id_comida, record?.id_alimento);
      openNotification(
        "success",
        "Alimento eliminado!",
        "El alimento se ha eliminado correctamente"
      );
      getPlanByPatient(selectedPatient?.id_paciente);
    } catch (e) {
      console.log("[alimentTable - DeleteAliment]", e);
      openNotification("error", "Error eliminando alimento.");
    } finally {
      setLoadingDeleteAliment(false);
      setDeletingKey("");
    }
  };

  const handleUpdateAliment = async (id_alimento, row) => {
    setLoadingEditAliment(true);
    setEditingKey(id_alimento);
    try {
      await updateAliment(id_alimento, row);
      openNotification("success", "Alimento actualizado!");
      getPlanByPatient(selectedPatient?.id_paciente);
    } catch (e) {
      console.log("Validate Failed:", e);
      console.log("[alimentTable - UpdateAliment]", e);
      openNotification("error", "Error actualizando alimento.");
    } finally {
      setLoadingEditAliment(false);
      setDeletingKey("");
    }
  };

  const onFinishEdit = async (id_alimento) => {
    let clearData = await form.validateFields();
    if (!clearData.intercambios || clearData.intercambios.trim() === "") {
      clearData.intercambios = null;
    } else {
      clearData.intercambios = clearData.intercambios.trim();
    }
    if (clearData.descripcion) {
      clearData.descripcion = clearData?.descripcion.trim();
    } else {
      clearData.descripcion = null;
    }
    await handleUpdateAliment(id_alimento, clearData);
  };

  return (
    <Space size="small">
      {editable ? (
        <Spin
          spinning={loadingEditAliment && editingKey === record?.id_alimento}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 18,
              }}
              spin
            />
          }
        >
          <span>
            <Button
              type="link"
              size="small"
              onClick={() => onFinishEdit(record?.id_alimento)}
            >
              Guardar
            </Button>
            <Popconfirm title="¿Cancelar cambios?" onConfirm={forgetChanges}>
              <Button type="link" size="small" danger>
                Cancelar
              </Button>
            </Popconfirm>
          </span>
        </Spin>
      ) : (
        <Button
          icon={<EditOutlined />}
          disabled={deletingKey !== "" || editingKey !== ""}
          onClick={() => editRow(record)}
          type="link"
          size="small"
        />
      )}

      {(editingKey === "" || editingKey !== record?.id_alimento) && (
        <Popconfirm
          title="¿Eliminar alimento?"
          onConfirm={() => handleDeleteAliment(record)}
          okText="Sí"
          cancelText="No"
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
        >
          <Button
            icon={<DeleteOutlined />}
            disabled={deletingKey !== "" || editingKey !== ""}
            type="link"
            size="small"
            danger
            loading={
              loadingDeleteAliment && deletingKey === record?.id_alimento //this way only displays loading animation to the icon of the row thats being deleted
            }
          />
        </Popconfirm>
      )}
    </Space>
  );
};

export default ActionColumn;
