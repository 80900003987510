import React from "react";
import { Col, Row, Typography } from "antd";

const { Paragraph, Title } = Typography;

// Vo2 table explanation and references sent by David

const VO2TableReferences = () => {
  return (
    <div className="w-full bg-white p-6 rounded-2xl shadow-neo-card">
      <Title level={3}>Entendiento tus resultados</Title>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12} className="text-justify">
          <Title level={5}>¿Es mi resultado bueno o malo?</Title>

          <Paragraph>
            Tener una capacidad cardiorrespiratoria (VO2max) elevada es un
            factor protector ante el desarrollo de enfermedades crónicas no
            transmisibles y la mortalidad por cualquier causa. Aquellas personas
            con una capacidad cardiorrespiratoria baja (VO2max) para su edad y
            sexo tienen mayor riesgo de desarrollar enfermedad cardiovascular,
            diabetes, resistencia a la insulina entre muchas otras enfermedades.
            Este es un parámetro que puede mejorar con el entrenamiento
            adecuadamente estructurado, la practica de ejercicio regular,
            acompañado a su vez de mejoría en los hábitos nutricionales.
          </Paragraph>
        </Col>
        <Col xs={24} md={12}>
          <Title level={5}>Referencias</Title>
          <Paragraph className="text-justify">
            <ul>
              <li>
                Mandsager K, Harb S et al: Relación de la aptitud
                cardiorrespiratoria con la mortalidad a largo plazo entre
                adultos sometidos a la prueba de ejercicio en cinta ﻿JAMA
                Network Open. 2018;1(6)
              </li>
              <li>
                Al-Mallah MH, Juraschek SP, Whelton S, et al. Sex differences in
                cardiorespiratory fitness and all-cause mortality: the Henry
                Ford Exercise Testing (FIT) Project. Mayo Clin Proc.
                2016;91(6):755-762.
              </li>
              <li>
                Sui X, Laditka JN, Hardin JW, Blair SN. Estimated functional
                capacity predicts mortality in older adults. J Am Geriatr Soc.
                2007;55(12):1940-1947.
              </li>
            </ul>
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default VO2TableReferences;
