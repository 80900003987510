import React, { useEffect } from "react";
import { Button, Form, Input, Row } from "antd";

// Custom Hook
import { useCreatePatient } from "../useCreatePatient";
import { useSelector } from "react-redux";

const FormPatientMedical = ({ form }) => {
  const databaseUser = useSelector((state) => state.user.databaseUser);

  const {
    formValues,
    handleOnChangeFormValue,
    prevStep,
    handleOnCreatePatient,
    loading,
  } = useCreatePatient();

  const onFinish = () => {
    handleOnCreatePatient(databaseUser.uid);
  };

  useEffect(() => {
    // Update form values from redux

    form.setFieldsValue({
      aptitud_med: formValues?.aptitud_med,
      antec_med: formValues?.antec_med,
      antec_familiares: formValues?.antec_familiares,
      antec_cardiovasculares: formValues?.antec_cardiovasculares,
      antec_osteomusculares: formValues?.antec_osteomusculares,
      examen_fisico: formValues?.examen_fisico,
    });
  }, [formValues, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="w-full"
      name="form-patient"
      style={{
        maxWidth: 600,
      }}
      layout="vertical"
    >
      <Form.Item name="aptitud_med" label="Aptitud médica">
        <Input.TextArea
          onChange={(event) => {
            handleOnChangeFormValue("aptitud_med", event.target.value);
          }}
          placeholder="Aptitud médica..."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>

      <Form.Item name="antec_med" label="Antecedentes médicos">
        <Input.TextArea
          onChange={(event) => {
            handleOnChangeFormValue("antec_med", event.target.value);
          }}
          placeholder="Antecedentes médicos..."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>

      <Form.Item name="antec_familiares" label="Antecedentes familiares">
        <Input.TextArea
          onChange={(event) => {
            handleOnChangeFormValue("antec_familiares", event.target.value);
          }}
          placeholder="Antecedentes familiares..."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>

      <Form.Item
        name="antec_cardiovasculares"
        label="Antecedentes cardiovasculares"
      >
        <Input.TextArea
          onChange={(event) => {
            handleOnChangeFormValue(
              "antec_cardiovasculares",
              event.target.value
            );
          }}
          placeholder="Antecedentes cardiovasculares..."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>

      <Form.Item
        name="antec_osteomusculares"
        label="Antecedentes osteomusculares"
      >
        <Input.TextArea
          onChange={(event) => {
            handleOnChangeFormValue(
              "antec_osteomusculares",
              event.target.value
            );
          }}
          placeholder="Antecedentes osteomusculares..."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>

      <Form.Item name="examen_fisico" label="Exámen físico">
        <Input.TextArea
          onChange={(event) => {
            handleOnChangeFormValue("examen_fisico", event.target.value);
          }}
          placeholder="Exámen físico..."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>

      <Row justify="end" align="bottom">
        <Button
          htmlType="button"
          className="mx-4 shadow-neo-button rounded-neo-button"
          onClick={prevStep}
          disabled={loading}
        >
          Anterior
        </Button>
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button"
          htmlType="submit"
          loading={loading}
        >
          Crear paciente
        </Button>
      </Row>
    </Form>
  );
};

export default FormPatientMedical;
