import React from "react";

const Container = ({ children }) => {
  return (
    <div
      className={
        "w-full max-w-xs m-auto my-auto flex flex-col items-center bg-gray-100 p-6 rounded-2xl shadow-neo-card"
      }
    >
      {children}
    </div>
  );
};

export default Container;
