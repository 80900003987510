import React from "react";
import {
  CheckCircleFilled,
  LoadingOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Col, Progress, Row, Spin, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";

const PreviewBiomarker = ({ name, found, val, suffix, loading }) => {
  return (
    <Row>
      <Col span={13}>{name}</Col>
      {loading ? (
        <Col span={8}>
          <Progress
            strokeColor={{
              from: "#e2edf5",
              to: "#e2f5e4",
            }}
            percent={100}
            showInfo={false}
            status="active"
          />
        </Col>
      ) : (
        <>
          <Col span={8} className="text-right">
            {found ? (
              <Text className="text-sm mr-1 font-bold">{val}</Text>
            ) : (
              <Text className="text-xs mr-1 text-gray-500 italic">
                No encontrado
              </Text>
            )}
            <Text className="text-xs text-gray-500">{suffix}</Text>
          </Col>
        </>
      )}

      <Col span={3} className="text-right">
        {loading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 14,
                }}
                spin
              />
            }
          />
        ) : found ? (
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        ) : (
          <Tooltip
            placement="right"
            title={"Biomarcador no encontrado"}
            color={"#fa8c16"}
            className="rounded-neo-button"
          >
            <WarningFilled style={{ color: "#fa8c16" }} />
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default PreviewBiomarker;
