import React, { useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  Button,
  Divider,
  Space,
  Col,
} from "antd";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addAlimentToMeal,
  removeAlimentFromMeal,
  updateAlimentField,
} from "../../features/nutrition/mealFormSlice";

// Components
import PresetsMenu from "../presets/PresetsMenu";

// Utils
import { alimentMeasures } from "../../constants";
import { mapHourToColor } from "../../utils/utils";

// Const
const { Option } = Select;
const { Title } = Typography;
const layout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
    md: 6,
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
    md: 18,
  },
};

const NutritionForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const mealForm = useSelector((state) => state.nutrition.formMeal);

  const cardColorTag = mapHourToColor(mealForm.hora, "tag");
  const cardClassName = `w-full bg-white p-6 pb-0 mb-4 rounded-2xl shadow-neo-card border-2 border-${cardColorTag}`;
  const onFinish = (fieldsValue) => {
    console.log(fieldsValue);
  };

  const onChangeAlimentField = (val, id, fieldName) => {
    dispatch(addAlimentToMeal(id));
    dispatch(updateAlimentField({ id, val, fieldName }));
  };

  useEffect(() => {
    // update form values from redux
    form.setFieldsValue({ alimentos: mealForm?.alimentos });
  }, [mealForm, form]);

  return (
    <div
      className={cardClassName}
      style={{
        height: "fit-content",
      }}
    >
      <Row className="mb-4">
        <Col span={22}>
          <Title level={4} className="font-bold w-full text-center">
            Agregar alimentos
          </Title>
        </Col>

        <Col span={2}></Col>
      </Row>
      <Row>
        <Form
          form={form}
          name="dynamic_form_nest_meal"
          className="w-full text-center"
          {...layout}
          onFinish={onFinish}
          initialValues={{
            alimentos: mealForm.alimentos,
          }}
        >
          <Form.List name="alimentos">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => {
                  // name is a natural number, it's used to count the field.
                  const { key, name, ...restField } = field;
                  return (
                    <Space
                      key={key}
                      className="w-full"
                      direction="vertical"
                      size={0}
                    >
                      {key !== 0 && (
                        <Divider>
                          Alimento adicional {fields.indexOf(field)}
                        </Divider>
                      )}
                      <Row justify="space-between">
                        <Col span={22} className={"mb-2"}>
                          <PresetsMenu groupId={1} formId={name} />
                        </Col>
                        <Col span={2}>
                          {key !== 0 && (
                            <div className="text-right -mt-2 mb-2">
                              <CloseCircleOutlined
                                className="text-gray-500 text-lg"
                                onClick={() => {
                                  remove(name);
                                  dispatch(removeAlimentFromMeal(name));
                                }}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Form.Item
                        {...restField}
                        name={[name, "descripcion"]}
                        label="Alimento"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input.TextArea
                          onChange={(e) => {
                            onChangeAlimentField(
                              e.target.value,
                              name,
                              "descripcion"
                            );
                          }}
                          placeholder="Descripción del alimento y preparación ..."
                          className="rounded-lg shadow-neo-field"
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "porcion"]}
                        label="Porción"
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          onChange={(val) => {
                            onChangeAlimentField(val, name, "porcion");
                          }}
                          placeholder="Cantidad de porciones"
                          className="w-full rounded-lg shadow-neo-field"
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "medida"]}
                        className="my-select-container"
                        label="Medida"
                      >
                        <Select
                          onChange={(val) => {
                            onChangeAlimentField(val, name, "medida");
                          }}
                          placeholder="Seleccione una medida"
                          className="rounded-lg text-left shadow-neo-field"
                        >
                          {alimentMeasures.map((e) => (
                            <Option key={e.val + key} value={e.val}>
                              {e.tag}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "intercambios"]}
                        label="Intercambios"
                      >
                        <Input.TextArea
                          onChange={(e) => {
                            onChangeAlimentField(
                              e.target.value,
                              name,
                              "intercambios"
                            );
                          }}
                          className="rounded-lg shadow-neo-field"
                          placeholder="Alimentos alternativos ..."
                        />
                      </Form.Item>
                    </Space>
                  );
                })}
                <Button
                  type="dashed"
                  className="mx-auto mb-4 rounded-2xl"
                  onClick={() => {
                    add();
                    dispatch(addAlimentToMeal(fields.length));
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Alimento adicional {fields.length}
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Row>
    </div>
  );
};

export default NutritionForm;
