import React, { useEffect } from "react";
import { Button, Col, Form, InputNumber, Row } from "antd";

// Globl state
import { useCreateMedicalRecord } from "../useCreateRegister";
import { BIOMARCADORES_META } from "../../../../constants";

const FormBiomarcadores = ({ form }) => {
  const { recordFormValues, onChangeRecordFormValue, prevStep, nextStep } =
    useCreateMedicalRecord();

  const onFinish = () => {
    nextStep();
  };

  // Update form values from global storage
  useEffect(() => {
    form.setFieldsValue({
      hemoglobina: recordFormValues?.hemoglobina,
      hematocitos: recordFormValues?.hematocitos,
      leucocitos: recordFormValues?.leucocitos,
      plaquetas: recordFormValues?.plaquetas,
      ferritina: recordFormValues?.ferritina,
      colesterol_total: recordFormValues?.colesterol_total,
      colesterol_ldl: recordFormValues?.colesterol_ldl,
      colesterol_hdl: recordFormValues?.colesterol_hdl,
      tag: recordFormValues?.tag,
      glucosa: recordFormValues?.glucosa,
    });
  }, [recordFormValues, form]);

  return (
    <Form
      form={form}
      className="w-full"
      onFinish={onFinish}
      layout="vertical"
      name="form-registro-medico-biomarcadores"
      initialValues={recordFormValues}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="hemoglobina"
            label={`Hemoglobina (${BIOMARCADORES_META.hemoglobina.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.hemoglobina.ranges.global.min,
                max: BIOMARCADORES_META.hemoglobina.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.hemoglobina.ranges.global.min}
              max={BIOMARCADORES_META.hemoglobina.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("hemoglobina", val);
              }}
              placeholder="Hemoglobina..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="hematocitos"
            label={`Hematocitos (${BIOMARCADORES_META.hematocrito.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.hematocrito.ranges.global.min,
                max: BIOMARCADORES_META.hematocrito.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.hematocrito.ranges.global.min}
              max={BIOMARCADORES_META.hematocrito.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("hematocitos", val);
              }}
              placeholder="Hematocitos..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="leucocitos"
            label={`Leucocitos `}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.leucocitos.ranges.global.min,
                max: BIOMARCADORES_META.leucocitos.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.leucocitos.ranges.global.min}
              max={BIOMARCADORES_META.leucocitos.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("leucocitos", val);
              }}
              placeholder="Leucocitos..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="plaquetas"
            label={`Plaquetas `}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.plaquetas.ranges.global.min,
                max: BIOMARCADORES_META.plaquetas.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.plaquetas.ranges.global.min}
              max={BIOMARCADORES_META.plaquetas.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("plaquetas", val);
              }}
              placeholder="Plaquetas..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="ferritina"
            label={`Ferritina (${BIOMARCADORES_META.ferritina.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.ferritina.ranges.global.min,
                max: BIOMARCADORES_META.ferritina.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.ferritina.ranges.global.min}
              max={BIOMARCADORES_META.ferritina.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("ferritina", val);
              }}
              placeholder="Ferritina..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="colesterol_total"
            label={`Colesterol Total (${BIOMARCADORES_META.colesterol_total.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.colesterol_total.ranges.global.min,
                max: BIOMARCADORES_META.colesterol_total.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.colesterol_total.ranges.global.min}
              max={BIOMARCADORES_META.colesterol_total.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("colesterol_total", val);
              }}
              placeholder="Colesterol total..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="colesterol_ldl"
            label={`Colesterol Ldl (${BIOMARCADORES_META.colesterol_ldl.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.colesterol_ldl.ranges.global.min,
                max: BIOMARCADORES_META.colesterol_ldl.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.colesterol_ldl.ranges.global.min}
              max={BIOMARCADORES_META.colesterol_ldl.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("colesterol_ldl", val);
              }}
              placeholder="Colesterol Ldl..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="colesterol_hdl"
            label={`Colesterol Hdl (${BIOMARCADORES_META.colesterol_hdl.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.colesterol_hdl.ranges.global.min,
                max: BIOMARCADORES_META.colesterol_hdl.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.colesterol_hdl.ranges.global.min}
              max={BIOMARCADORES_META.colesterol_hdl.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("colesterol_hdl", val);
              }}
              placeholder="Colesterol Hdl..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="tag"
            label={`Tag Hdl (${BIOMARCADORES_META.tag.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.tag.ranges.global.min,
                max: BIOMARCADORES_META.tag.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.tag.ranges.global.min}
              max={BIOMARCADORES_META.tag.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("tag", val);
              }}
              placeholder="Tag..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="glucosa"
            label={`Glucosa (${BIOMARCADORES_META.glucosa.suffix})`}
            rules={[
              {
                type: "number",
                min: BIOMARCADORES_META.glucosa.ranges.global.min,
                max: BIOMARCADORES_META.glucosa.ranges.global.max,
              },
            ]}
          >
            <InputNumber
              min={BIOMARCADORES_META.glucosa.ranges.global.min}
              max={BIOMARCADORES_META.glucosa.ranges.global.max}
              onChange={(val) => {
                onChangeRecordFormValue("glucosa", val);
              }}
              placeholder="Glucosa..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" align="bottom">
        <Button
          className="mx-4 shadow-neo-button rounded-neo-button"
          onClick={prevStep}
        >
          Anterior
        </Button>
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button"
          htmlType="submit"
        >
          Siguiente
        </Button>
      </Row>
    </Form>
  );
};

export default FormBiomarcadores;
