import React, { useState, useEffect, useRef } from "react";
import { Button, Carousel, Col, Image, Row, Skeleton } from "antd";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

// Config
import { storage } from "../../../config/firebase-config";

// Redux
import { useSelector } from "react-redux";

const UTCCarousel = () => {
  const carousel = useRef(null);

  const firebaseUser = useSelector((state) => state.user.firebaseUser);
  const databaseUser = useSelector((state) => state.user.databaseUser);
  const selectedPatient = useSelector((state) => state.patient.selected);

  const [loading, setLoading] = useState(true);
  const [utcImgList, setUtcImgList] = useState([]);

  const carouselButtonClass = `${
    utcImgList.length !== 0 &&
    "rounded-full border-1 shadow-md text-blue-400 hover:border-blue-500 hover:text-blue-500 target-on-hover-prev-floating"
  }`;

  const getUTCPhotos = async (uid) => {
    setLoading(true);
    //get all the images from a storage directory
    try {
      let res = await listAll(ref(storage, `${uid}/utc/`));
      if (res?.items?.length) {
        let promises = res.items.map((itemRef) => getDownloadURL(itemRef));
        let urls = await Promise.all(promises);
        let images = urls.map((url) => (
          <Image
            key={url}
            className="bg-gray-400 max-h-64 sm:max-h-56"
            src={url}
            preview={false}
          />
        ));
        setUtcImgList(images);
      } else {
        setUtcImgList([]);
      }
    } catch (e) {
      console.log("No hay imagenes UTC para mostrar.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      databaseUser.rol === 1 &&
      selectedPatient &&
      selectedPatient?.id_paciente !== ""
    ) {
      getUTCPhotos(selectedPatient.id_paciente);
    }

    if (databaseUser.rol === 2 && firebaseUser && firebaseUser?.uid !== "") {
      getUTCPhotos(firebaseUser?.uid);
    }
    return () => {
      setUtcImgList([]);
    };
  }, [databaseUser, firebaseUser, selectedPatient]);

  return (
    <Row className="mx-auto text-center flex justify-center items-center pl-4 pt-8 pb-4 sm:py-2">
      <Col xs={4} sm={4} className="trigger-on-hover">
        <Button
          className={carouselButtonClass}
          type="link"
          icon={<FaArrowLeft className="mx-auto" size={16} />}
          onClick={() => carousel.current.prev()}
          disabled={utcImgList.length === 0}
        />
      </Col>
      <Col xs={15} sm={16} className="h-56 my-auto">
        {!loading ? (
          <Carousel dots={false} ref={carousel}>
            {utcImgList.length !== 0 ? (
              utcImgList
            ) : (
              <div className="h-56 my-auto">
                <h3
                  key="no-utc-images"
                  className="h-full flex justify-center items-center text-gray-500 text-center bg-gray-100 rounded-lg shadow-neo-card-inset"
                >
                  No hay Images UTC
                </h3>
              </div>
            )}
          </Carousel>
        ) : (
          <Skeleton.Image
            active
            className="h-56 my-auto flex justify-center items-center "
          />
        )}
      </Col>
      <Col xs={4} sm={4} className="trigger-on-hover">
        <Button
          className={carouselButtonClass}
          type="link"
          icon={<FaArrowRight className="mx-auto" size={16} />}
          onClick={() => carousel.current.next()}
          disabled={utcImgList.length === 0}
        />
      </Col>
    </Row>
  );
};

export default UTCCarousel;
