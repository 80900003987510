import React from "react";

// short tag to display info on iterable data instead of console.log

const DevInfo = ({ tag, value }) => {
  return (
    process.env.NODE_ENV === "development" && (
      <span className="text-xs text-gray-400">
        {tag}: <span className="text-cyan-600 select-all">{value}</span>
      </span>
    )
  );
};

export default DevInfo;
