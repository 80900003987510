import React from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const CustomPopConfirm = ({ title, onConfirm, confirmLoading, children }) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okButtonProps={{ loading: confirmLoading }}
      icon={
        <QuestionCircleOutlined
          style={{
            color: "red",
          }}
        />
      }
      okText="Sí"
      cancelText="No"
      placement="bottom"
    >
      {children}
    </Popconfirm>
  );
};

const DeleteButton = ({ title, onConfirm, confirmLoading }) => {
  return (
    <CustomPopConfirm
      title={title}
      onConfirm={() => onConfirm?.()}
      confirmLoading={confirmLoading}
    >
      <Button
        danger
        type="text"
        shape="circle"
        icon={<DeleteOutlined />}
        loading={confirmLoading}
        size="small"
      />
    </CustomPopConfirm>
  );
};

export default DeleteButton;
