// Reference: https://redux.js.org/tutorials/quick-start
import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialState";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState().navigation,
  reducers: {
    updateWelcome: (state, action) => {
      state.showWelcome = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateWelcome } = navigationSlice.actions;

export default navigationSlice.reducer;
