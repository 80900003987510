import React from "react";
import { Col, Row, Spin, Tooltip, Typography } from "antd";
import {
  // ArrowUpOutlined,
  // ArrowDownOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";

const { Title, Text } = Typography;

const KPICard = ({
  prefix,
  suffix,
  value,
  title,
  subtitle,
  width,
  color,
  loading,
  info,
}) => {
  return (
    <div
      className="bg-white rounded-xl"
      style={{
        height: "fit-content",
        lineHeight: "1",
        width: width,
        boxShadow: `5px 5px 10px #c9cdd8,
                -5px -5px 10px #ffffff`,
      }}
    >
      <Row>
        {/* SIDE BAR COLOR */}
        <Col
          span={1}
          className="rounded-l-xl h-18"
          style={{ background: color }}
        />
        {/* INFORMATION */}
        <Col span={22} offset={1} className="p-2">
          <Row justify="space-between">
            <Col>
              <Text className="my-2 text-sm text-gray-600">{title}</Text>
            </Col>
            <Col>
              {info && (
                <Tooltip placement="top" title={info}>
                  <InfoCircleOutlined className="text-gray-400 text-sm" />
                </Tooltip>
              )}
            </Col>
          </Row>
          <Row>
            {subtitle && (
              <Text className="text-xs text-gray-400">{subtitle}</Text>
            )}
          </Row>
          {loading ? (
            <Row>
              <Spin
                indicator={
                  <LoadingOutlined
                    className="mt-2"
                    style={{
                      fontSize: 24,
                    }}
                  />
                }
              />
            </Row>
          ) : (
            <Title
              level={3}
              style={{
                margin: "4px 8px 0px 0px",
                fontWeight: 900,
              }}
              className="text-gray-800"
            >
              {value ? prefix + value : "-"}

              {suffix !== "" && (
                <Text className="m-0 text-xs ml-1 font-semibold">
                  {value ? suffix : ""}
                </Text>
              )}
            </Title>
          )}
        </Col>
      </Row>
    </div>
  );
};

KPICard.defaultProps = {
  color: "#8EE4AF",
  value: 0,
  title: "Título",
  width: 200,
  arrow: "up",
  prefix: "",
  suffix: "",
  loading: false,
  info: null,
};

KPICard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  arrow: PropTypes.oneOf(["up", "down"]),
  prefix: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  color: PropTypes.oneOfType([PropTypes.string]),
  loading: PropTypes.oneOfType([PropTypes.bool]),
  info: PropTypes.oneOfType([PropTypes.string]),
};

export default KPICard;
