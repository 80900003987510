import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateFirebaseUser } from "../../features/user/userSlice";
import { updateWelcome } from "../../features/navigation/navigationSlice";

// Config
import { auth } from "../../config/firebase-config";

// Assets
import { ReactComponent as Image } from "../../assets/img/MOEBIUS-LOGO-COLOR.svg";
import moebiusText from "../../assets/img/moebius-text.png";
import LandingCard from "./LandingCard";

// Utils
import { openNotification } from "utils/utils";

const Login = ({ logged, setLogged, setRecoverPassword }) => {
  const dispatch = useDispatch();
  const firebaseUser = useSelector((state) => state.user.firebaseUser);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onPressLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      openNotification("success", "Usuario o contraseña incorrectos");
      setEmail("");
      setPassword("");
    }
  };

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser && !logged) {
      if (currentUser?.accessToken !== firebaseUser.accessToken) {
        const parsedUser = {
          uid: currentUser.uid,
          email: currentUser.email,
          photoUrl: currentUser.photoUrl,
          accessToken: currentUser?.accessToken,
        };
        dispatch(updateFirebaseUser(parsedUser));
        dispatch(updateWelcome(true));

        setLogged(true);
      }
    } else {
      setLogged(false);
      dispatch(updateWelcome(false));
      dispatch(updateFirebaseUser(null));
    }
  });

  return (
    <LandingCard>
      <div>
        <img
          className="w-16 mx-auto mb-4"
          src={moebiusText}
          alt="moebius healt"
        />
        <Image className="login-svg h-36 md:h-28 mb-8" />
      </div>
      <div className="w-full text-3xl font-bold" style={{ color: "#305567" }}>
        Bienvenido
      </div>
      <Form
        className={"w-full mt-6"}
        layout="vertical"
        name="basic"
        // initialValues={{
        //   remember: true,
        // }}
        // onFinish={onPressLogin}
        autoComplete="off"
      >
        <Form.Item
          className={"w-full outline-none"}
          label="Correo"
          name="email"
          rules={[
            {
              required: true,
              message: "Ingrese su correo",
            },
          ]}
        >
          <Input
            className="rounded-lg shadow-neo-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          className={"w-full mb-6 outline-none p-0"}
          label="Contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: "Ingrese su contraseña",
            },
          ]}
        >
          <Input.Password
            className="rounded-lg shadow-neo-field"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item className="w-1/2 mx-auto text-center mb-2">
          <Button
            className="rounded-xl text-xs w-full shadow-neo-button-md"
            type="primary"
            htmlType="submit"
            onClick={() => {
              onPressLogin();
            }}
          >
            INGRESAR
          </Button>
        </Form.Item>
      </Form>
      <Button
        className="rounded-xl text-xs w-1/2"
        type="link"
        onClick={() => {
          setRecoverPassword(true);
        }}
      >
        Recuperar contraseña
      </Button>
    </LandingCard>
  );
};

export default Login;
