import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { FaRegEdit } from "react-icons/fa";

// Components
import UTCCarousel from "./analisis_corporal/UTCCarousel";
import CorporalCompTable from "./analisis_corporal/CorporalCompTable";

// Redux
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const renderCorporalAttributes = (
  clasificacion_graso,
  clasificacion_muscular,
  somatotipo
) => {
  return (
    <>
      <Col xs={8} sm={6} md={8} className="text-center">
        <Title level={5} className="mb-0 font-normal">
          Grasa
        </Title>
        <Text className="font-light text-base">
          {clasificacion_graso ? clasificacion_graso : "-"}
        </Text>
      </Col>
      <Col xs={8} sm={7} md={8} className="text-center">
        <Title level={5} className="mb-0 font-normal">
          Masa Muscular
        </Title>
        <Text className="font-light text-base">
          {clasificacion_muscular ? clasificacion_muscular : "-"}
        </Text>
      </Col>
      <Col xs={8} sm={11} md={8} className="text-center">
        <Title level={5} className="mb-0 font-normal">
          Somatotipo
        </Title>
        <Text className="font-light text-base">
          {somatotipo ? somatotipo : "-"}
        </Text>
      </Col>
    </>
  );
};

const CorporalCompAndElectroSection = ({ setOnClickElectro }) => {
  const selectedRecord = useSelector((state) => state.record.selected);
  const {
    clasificacion_graso,
    clasificacion_muscular,
    somatotipo,

    electro,
    electro_pdf,
  } = selectedRecord;

  return (
    <Row className="mb-6" gutter={[24, 16]}>
      <Col xs={24} sm={24} md={16} className="mx-auto">
        <div className="w-full bg-white p-6 rounded-2xl shadow-neo-card">
          <Row className="justify-center my-6">
            <Title level={2} className="font-bold">
              Análisis de composición corporal
            </Title>
          </Row>
          <Row gutter={[{ xs: 8, md: 0 }]}>
            <Col xs={24} sm={14} className="justify-center">
              <Row className="mb-6">
                {renderCorporalAttributes(
                  clasificacion_graso,
                  clasificacion_muscular,
                  somatotipo
                )}
              </Row>
              <Row>
                <CorporalCompTable />
              </Row>
            </Col>
            <Col xs={24} sm={10}>
              <UTCCarousel />
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} style={{ display: "flex" }}>
        <div className="w-full bg-white p-6 rounded-2xl shadow-neo-card">
          <Row justify="center" align="middle" className="my-6">
            <Title level={2} className="font-bold">
              Electro.
            </Title>

            <Button
              type="link"
              className="p-0 font-medium text-xl absolute top-8 right-12"
            >
              <FaRegEdit className="text-xl" />
            </Button>
          </Row>

          <div
            className="flex flex-col justify-between"
            style={{ height: "65%" }}
          >
            <Row>
              <Text
                className="text-justify text-base mx-auto mb-6"
                style={{ maxWidth: "280px" }}
              >
                {!electro ? "Pendiente." : electro}
              </Text>
            </Row>
            <Row className="justify-center mb-3">
              <Button
                className="shadow-neo-button rounded-neo-button"
                type="primary"
                disabled={!electro_pdf || electro_pdf.length === 0}
                onClick={() => setOnClickElectro(true)}
              >
                Click para ver el documento
              </Button>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CorporalCompAndElectroSection;
