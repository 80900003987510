// Reference: https://redux.js.org/tutorials/quick-start
import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialState";

export const calendarSlice = createSlice({
  name: "calendar",
  initialState: initialState().calendar,
  reducers: {
    clearTaskFormState: (state) => {
      state.taskForm = initialState().calendar.taskForm;
    },

    updateTaskFormField: (state, action) => {
      const { fieldName, val } = action.payload;
      state.taskForm[fieldName] = val;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearTaskFormState, updateTaskFormField } =
  calendarSlice.actions;

export default calendarSlice.reducer;
