import React, { useState } from "react";
import { Collapse, Empty, Row, Spin } from "antd";

// Service
import { getPresetsByCategory } from "services/libraryService";

// Components
import PresetTask from "./PresetTask";
import PresetAliment from "./PresetAliment";

// Consts
const { Panel } = Collapse;

const panelTitle = (title) => {
  return <span className="font-bold">{title}</span>;
};

const noPresets = (
  <Row className="text-center mb-4">
    <span className="text-gray-400 w-full italic">Sin prestablecidos</span>
  </Row>
);

const CategoriesCollapse = ({
  groupId,
  categories,
  loading,
  hideMenu,
  formId,
}) => {
  const [presets, setPresets] = useState([]);
  const [loadingPresets, setLoadingPresets] = useState(false);

  const getPresetsFromCategory = async (categoryId) => {
    if (categoryId) {
      setLoadingPresets(true);
      try {
        let data = await getPresetsByCategory(categoryId);

        //FIXME: hacerlo funcionar para ambas bibliotecas
        // merge data
        data = data.bibliotecaTarea.concat(data.bibliotecaAlimento);
        let presetsMenuItems = data.map((preset) => {
          return groupId === 1 ? (
            <PresetAliment
              key={preset.id_biblioteca_alimento}
              preset={preset}
              hideMenu={hideMenu}
              formId={formId}
            />
          ) : (
            <PresetTask
              key={preset.id_biblioteca_tarea}
              preset={preset}
              hideMenu={hideMenu}
            />
          );
        });
        setPresets(presetsMenuItems);
      } catch (error) {
        console.log("Categoria sin presets", error);
        setPresets([]);
      } finally {
        setLoadingPresets(false);
      }
    }
  };

  return (
    <Row className="justify-center w-80 mb-4 bg-gray-50 p-3 rounded-xl">
      {loading ? (
        <Spin className="mx-auto my-4" />
      ) : categories.length === 0 ? (
        <Empty description={"Sin categorias"} />
      ) : (
        <Collapse
          accordion
          ghost
          expandIconPosition={"right"}
          className="w-full bg-gray-100 collapse-small-headers"
          onChange={(activeKey) => {
            getPresetsFromCategory(activeKey);
          }}
        >
          {categories.map((category) => {
            return (
              <Panel
                header={panelTitle(category.nombre)}
                key={category.id_categoria}
                className="bg-white rounded-lg mb-2 p-0 shadow-neo-card"
                style={{
                  borderRadius: "8px", // onlu bc the last child need this
                }}
              >
                {!loadingPresets ? (
                  presets.length ? (
                    presets
                  ) : (
                    noPresets
                  )
                ) : (
                  <Row>
                    <Spin size="small" className="mx-auto my-2" />
                  </Row>
                )}
              </Panel>
            );
          })}
        </Collapse>
      )}
    </Row>
  );
};

export default CategoriesCollapse;
