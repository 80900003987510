import React, { useState, useEffect } from "react";
import { Alert, Col, Layout, Row, Select, Spin, Tooltip } from "antd";

import axios from "axios";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";

// Config
import { api_enpoint } from "../../config/config";
import "moment/locale/es";

// Utils
import { BIKERS } from "../../utils/data"; //Debug porpouses

// Components
import VO2TableReferences from "./VO2TableReferences";
import Vo2Table from "./VO2Table";
import Vo2Chart from "./VO2Chart";

// Redux
import { useSelector } from "react-redux";

moment.locale("es");
const { Content } = Layout;
const { Option } = Select;

const Dashboards = () => {
  const selectedPatient = useSelector((state) => state.patient.selected);

  // Patient records and vitals
  const [patientRecords, setPatientRecords] = useState([]);
  const [loadingRecords, setLoadingRecords] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordOptions, setRecordOptions] = useState([]);

  useEffect(() => {
    // Get patients records to fill the options select
    const getPatientRecords = async () => {
      setLoadingRecords(true);
      await axios
        .get(
          `${api_enpoint}/pacientes/${selectedPatient?.id_paciente}/registros`
        )
        .then(async (res) => {
          let data = res.data.data;
          setPatientRecords(data);
          setSelectedRecord(data[0]);
          const recordSelectOptions = data.map((row) => (
            <Option key={row.fecha_registro}>
              {moment(row.fecha_registro).add(1, "days").format("DD MMM YYYY")}
            </Option>
          ));
          setRecordOptions(recordSelectOptions);
          setLoadingRecords(false);
        })
        .catch((err) => {
          console.log(err);
          setPatientRecords([]);
          setSelectedRecord(null);
          setLoadingRecords(false);
        });
    };
    getPatientRecords();
  }, [selectedPatient]);

  const handleRecordChange = (value) => {
    setSelectedRecord(
      patientRecords.find((ele) => ele.fecha_registro === value)
    );
  };
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Content style={{ background: "#dce5ff" }} className="mt-0 md:mt-8">
      <Layout
        className="pt-0 md:pt-12"
        style={{
          background: "#dce5ff",
        }}
      >
        <Content className="mx-6 mb-2">
          <Col>
            <Row className={"w-full mb-1"} gutter={[6, 6]}>
              <Col xs={24}>
                {/* Select record for patient */}
                <span className="leading-8">
                  Registros de paciente tomados el día:
                  {!loadingRecords ? (
                    <Select
                      placeholder="Seleccione registro"
                      onChange={handleRecordChange}
                      bordered={false}
                      disabled={!patientRecords.length}
                      defaultValue={selectedRecord?.fecha_registro}
                      style={{ width: 130 }}
                    >
                      {recordOptions}
                    </Select>
                  ) : (
                    <Spin size="small" className="mx-12" />
                  )}
                  <Tooltip title="Visualice su clasificación según edad y vo2 max">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              </Col>
            </Row>
            <Row className={"w-full mt-4 mb-6"} gutter={[24, 16]}>
              <Col xs={24} xl={12}>
                {selectedPatient?.genero && selectedPatient?.edad ? (
                  <Vo2Table
                    loading={loadingRecords}
                    patientRecord={selectedRecord}
                  />
                ) : (
                  <Alert
                    message="Datos faltantes"
                    description="La tabla de Rendimiento por VO2Max no puede ser visualizada correctamente debido a la falta de alguno de los siguientes datos en la información del deportista: género, edad o registros de vo2max."
                    type="warning"
                    showIcon
                  />
                )}
              </Col>
              <Col xs={24} xl={12}>
                <Vo2Chart bikers={BIKERS} />
              </Col>
            </Row>
            <Row className="pr-6">
              <VO2TableReferences />
            </Row>
          </Col>
        </Content>
      </Layout>
    </Content>
  );
};

export default Dashboards;
