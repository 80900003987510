import React, { useState } from "react";
import {
  Progress,
  Button,
  Row,
  Badge,
  Popover,
  Typography,
  Divider,
  Spin,
} from "antd";
import { MinusOutlined, PlusOutlined, CheckOutlined } from "@ant-design/icons";

// Components
import DevInfo from "components/@D4D/DevInfo";
import DeleteButton from "components/@D4D/DeleteButton";

// Redux
import { useSelector } from "react-redux";

// Utils
import { taskProgressToColor, openNotification } from "utils/utils";

// Services
import {
  completeProgressTask,
  decreaseProgressTask,
  deleteTask,
  increaseProgressTask,
} from "services/taskService";

// Consts
const { Paragraph, Text, Title } = Typography;

const CalendarTask = ({
  idx,
  event,
  updateLoading,
  setUpdateLoading,
  loadingTasks,
  reloadTasks,
}) => {
  // User info
  const databaseUser = useSelector((state) => state.user.databaseUser);
  const [loadingDeleteTask, setLoadingDeleteTask] = useState(false);

  const handleDecreaseProgress = async () => {
    setUpdateLoading(true);
    try {
      await decreaseProgressTask(event?.id_tarea);
    } catch (e) {
      console.log(e);
      openNotification("error", "Error actualizando el progreso.");
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleIncreaseProgress = async () => {
    setUpdateLoading(true);
    try {
      await increaseProgressTask(event?.id_tarea);
    } catch (e) {
      console.log(e);
      openNotification("error", "Error actualizando el progreso.");
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleCompleteProgress = async () => {
    setUpdateLoading(true);
    try {
      await completeProgressTask(event?.id_tarea);
      openNotification(
        "success",
        "Tarea completada!",
        "Felicitaciones, sigue así, en el camino a la grandeza.."
      );
    } catch (e) {
      console.log(e);
      openNotification("error", "Error actualizando el progreso.");
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleDeleteTask = async () => {
    setLoadingDeleteTask(true);
    try {
      await deleteTask(event?.id_tarea);
      openNotification(
        "success",
        "Tarea eliminada!",
        "La tarea se ha eliminado correctamente"
      );
      reloadTasks();
    } catch (e) {
      openNotification("error", "Error eliminando tarea.");
    } finally {
      setLoadingDeleteTask(false);
    }
  };

  return (
    <li className="calendar-task text-left cursor-pointer text-xs w-full h-full rounded-lg">
      <Popover
        title={
          <Row justify="space-between">
            <Typography>
              <Title level={5}>{event?.titulo}</Title>
            </Typography>

            {databaseUser?.rol === 1 && (
              <DeleteButton
                title={"Eliminar tarea"}
                onConfirm={handleDeleteTask}
                confirmLoading={loadingDeleteTask}
              />
            )}
          </Row>
        }
        trigger="click"
        content={
          <div className="p-2">
            <Row>
              <DevInfo tag={"id_tarea"} value={event?.id_tarea} />
            </Row>

            <Row className="z-10">
              {updateLoading || loadingTasks ? (
                <Spin size="small" className="mx-auto" />
              ) : (
                <Progress
                  percent={event?.progreso}
                  style={{ margin: "auto" }}
                />
              )}
            </Row>

            <Row className="mt-2 justify-evenly">
              <Button
                size="small"
                disabled={event?.progreso <= 0}
                className="w-full"
                icon={<MinusOutlined />}
                onClick={handleDecreaseProgress}
                danger
              />
              <Button
                size="small"
                className="w-full"
                disabled={event?.progreso >= 100}
                icon={<PlusOutlined />}
                onClick={handleIncreaseProgress}
                type="primary"
                ghost
              />
              <Button
                type="primary"
                disabled={event?.progreso >= 100}
                size="small"
                icon={<CheckOutlined />}
                onClick={handleCompleteProgress}
              >
                Completar
              </Button>
            </Row>
            <Row
              className="block mx-auto text-justify"
              style={{
                minWidth: "220px",
                maxWidth: "250px",
              }}
            >
              <Typography>
                <Divider orientation="left" className="font-bold text-xs">
                  Descripción
                </Divider>
                <Paragraph className="text-xs ml-1">
                  {event?.descripcion ? (
                    <Text>{event?.descripcion}</Text>
                  ) : (
                    <Text type="secondary">Sin descripción</Text>
                  )}
                </Paragraph>
              </Typography>
            </Row>
            <Row className="mt-4">
              <Badge
                className="mr-1"
                style={{ backgroundColor: `#45b6bc` }}
                count={event?.deporte}
              />
              <Badge
                style={{ backgroundColor: `#45b6bc` }}
                count={event?.cantidad + " " + event?.unidades}
              />
            </Row>
          </div>
        }
        icon={null}
      >
        <Row className="p-1">
          <Badge
            style={{
              backgroundColor: `${taskProgressToColor(event?.progreso)}`,
            }}
            count={event?.progreso + "%"}
          />
          <span className="ml-2 text-black">{event?.titulo}</span>
        </Row>
      </Popover>
    </li>
  );
};

export default CalendarTask;
