import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { sendPasswordResetEmail } from "firebase/auth";

// Config
import { auth } from "../../config/firebase-config";

// Components
import LandingCard from "./LandingCard";

// Utils
import { openNotification } from "utils/utils";

const ForgotPassword = ({ setRecoverPassword }) => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const onRecoverPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email, {
        url:
          process.env.NODE_ENV === "production"
            ? `https://app.moebius.health`
            : `http://localhost:3000`,
      });
      openNotification(
        "success",
        "Enlace de recuperación enviado, revisa tu correo y en la bandeja de spam."
      );
      form.resetFields();
    } catch (error) {
      console.log(error.message);
      openNotification("error", "Falló el envío: " + error.message);
    }
  };
  return (
    <LandingCard>
      <div
        className="w-full text-xl text-center font-bold"
        style={{ color: "#305567" }}
      >
        ¿Olvidaste tu contraseña?
      </div>
      <Form
        form={form}
        className={"w-full mt-6"}
        layout="vertical"
        name="basic"
        autoComplete="off"
      >
        <Form.Item
          className={"w-full outline-none"}
          label="Correo"
          name="email"
          rules={[
            {
              required: true,
              message: "Ingrese su correo",
            },
          ]}
        >
          <Input
            className="rounded-lg shadow-neo-field"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item className="w-3/5 mx-auto text-center mb-2">
          <Button
            className={"rounded-xl text-xs w-full shadow-neo-button-md"}
            htmlType="submit"
            type="primary"
            ghost
            onClick={onRecoverPassword}
          >
            Reestablecer contraseña
          </Button>
        </Form.Item>
      </Form>
      <Button
        className={"rounded-xl text-xs w-1/2"}
        type="link"
        onClick={() => {
          setRecoverPassword(false);
        }}
      >
        Volver
      </Button>
    </LandingCard>
  );
};

export default ForgotPassword;
