import { createRequest, throwErrors } from "./globalServices";

export const deleteAliment = async (mealId, alimentId) => {
  try {
    const response = await createRequest().delete(
      `/nutricional/comidas/${mealId}/alimentos/${alimentId}`
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const updateAliment = async (alimentId, alimentBody) => {
  try {
    const response = await createRequest().put(
      `alimentos/${alimentId}`,
      alimentBody
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};
