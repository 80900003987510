import React, { useRef } from "react";
import { Row, Col, Typography, Carousel, Button } from "antd";
import { FaArrowRight, FaArrowLeft, FaRegEdit } from "react-icons/fa";

// Components
import KPICard from "./KPICard";

// Utils
import { useSelector } from "react-redux";

// Constants
import { ERGOESPIROMETIRA_META } from "../../constants";
const { Title, Text } = Typography;

const Ergoespirometria = ({ setOnClickErgo }) => {
  const carousel = useRef(null);
  const selectedRecord = useSelector((state) => state.record.selected);
  const {
    conclusion_ergo,
    vo2_max_rel,
    watts_max_abs,
    watts_max_rel,
    fc_max,
    vo2_vt2_rel,
    watts_vt2_abs,
    watts_vt2_rel,
    fc_vt2,
    ergo_pdf,
  } = selectedRecord;

  return (
    <div className="bg-white py-6 w-full rounded-2xl shadow-neo-card">
      <Row className="justify-center my-6">
        <Title level={2} className="font-bold text-center">
          Ergoespirometría
        </Title>
      </Row>
      <Row className="flex items-center">
        <Col xs={4} sm={3} className="text-center">
          <Button
            type="link"
            size="large"
            icon={<FaArrowLeft className="mx-auto" size={28} />}
            onClick={() => carousel.current.prev()}
          />
        </Col>
        <Col xs={16} sm={18}>
          <Carousel dots={false} ref={carousel}>
            <div>
              <Row className="justify-center py-4 w-full" gutter={[8, 8]}>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="Vo2"
                    subtitle="Max relativo"
                    value={vo2_max_rel}
                    suffix={ERGOESPIROMETIRA_META.vo2_max_rel.suffix}
                    width={160}
                    color="#3fbea1"
                  />
                </Col>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="Watts"
                    subtitle="Max relativo"
                    value={watts_max_rel}
                    suffix={ERGOESPIROMETIRA_META.watts_max_rel.suffix}
                    width={160}
                    color="#00bcdb"
                  />
                </Col>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="Watt"
                    subtitle="Max absoluto"
                    value={watts_max_abs}
                    suffix={ERGOESPIROMETIRA_META.watts_max_abs.suffix}
                    width={160}
                    color="#53b2f5"
                  />
                </Col>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="FC"
                    subtitle="Max"
                    value={fc_max}
                    suffix={ERGOESPIROMETIRA_META.fc_max.suffix}
                    width={160}
                    color="#aba1ee"
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row className="justify-center py-4 w-full" gutter={[16, 16]}>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="Vo2"
                    subtitle="VT2 relativo"
                    value={vo2_vt2_rel}
                    suffix={ERGOESPIROMETIRA_META.vo2_vt2_rel.suffix}
                    width={160}
                    color="#3fbea1"
                  />
                </Col>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="Watts"
                    subtitle="VT2 absoluto"
                    value={watts_vt2_abs}
                    suffix={ERGOESPIROMETIRA_META.watts_vt2_abs.suffix}
                    width={160}
                    color="#53b2f5"
                  />
                </Col>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="Watts"
                    subtitle="VT2 relativo"
                    value={watts_vt2_rel}
                    suffix={ERGOESPIROMETIRA_META.watts_vt2_rel.suffix}
                    width={160}
                    color="#00bcdb"
                  />
                </Col>
                <Col xs={24} sm={12} lg={6} className="flex justify-center">
                  <KPICard
                    title="FC"
                    subtitle="VT2"
                    value={fc_vt2}
                    suffix={ERGOESPIROMETIRA_META.fc_vt2.suffix}
                    width={160}
                    color="#aba1ee"
                  />
                </Col>
              </Row>
            </div>
          </Carousel>
        </Col>
        <Col xs={4} sm={3} className="text-center">
          <Button
            type="link"
            icon={<FaArrowRight className="mx-auto" size={28} />}
            onClick={() => carousel.current.next()}
          />
        </Col>
      </Row>
      <Row className="justify-center mt-4">
        <div className="bg-white p-4" style={{ width: "90%" }}>
          <Row justify="center" align="middle">
            <Title level={3} className="font-bold my-6">
              Conclusiones
            </Title>

            <Button type="link" className="p-0 text-xl absolute top-8 right-12">
              <FaRegEdit className="text-xl" />
            </Button>
          </Row>

          <div
            className="flex flex-col justify-between"
            style={{ height: "70%" }}
          >
            <Row className="mx-auto my-6" style={{ maxWidth: "550px" }}>
              {!conclusion_ergo
                ? "Pendiente."
                : conclusion_ergo.split("\n").map((texto, index) => (
                    <Text
                      key={texto + index}
                      className="text-base text-justify"
                    >
                      {texto}
                    </Text>
                  ))}
            </Row>
            <Row className="justify-center my-3">
              <Button
                className="shadow-neo-button rounded-neo-button"
                type="primary"
                disabled={!ergo_pdf || ergo_pdf.length === 0}
                onClick={() => setOnClickErgo(true)}
              >
                Click para ver el documento
              </Button>
            </Row>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Ergoespirometria;
