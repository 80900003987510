import moment from "moment";

const initialState = () => ({
  user: {
    loading: false,
    databaseUser: {
      activo: "",
      apellidos: "",
      nombres: "",
      rol: null,
    },
    firebaseUser: {
      accessToken: "",
      photoUrl: null,
      email: null,
      uid: "",
    },
  },
  patient: {
    isReady : false,
    loading: false,
    selected: {},
    list: [],
    patientForm: {
      visible: false,
      loading: false,
      error: null,

      step: 0,
      values: {
        /** Step 1 - Información general */
        nombres: null,
        apellidos: null,
        email: null,
        personal_id: null,

        genero: null,
        telefono: null,
        edad: null,
        fecha_nacimiento: null,
        lugar_nacimiento: null,
        residencia: null,
        rh: null,
        grupo_sanguineo: null,

        /** Step 2 - Información Deportiva */
        deporte: null,
        modalidad: null,
        edad_deportiva: null,

        /** Step 3 - Antecedentes médicos*/
        aptitud_med: null,
        antec_med: null,
        antec_familiares: null,
        antec_cardiovasculares: null,
        antec_osteomusculares: null,
        examen_fisico: null,

        /** No editable */
        id_paciente: null,
        id_usuario: null,
      },
    },
  },
  record: {
    loading: false,
    selected: null,
    list: [],
    recordForm: {
      visible: false,
      loading: false,
      error: null,

      step: 0,
      values: {
        fecha_registro: moment().format("DD/MM/YYYY"),

        /** Step 1 - Datos básicos*/
        altura: null,
        peso: null,
        imc: null,
        peso_deseado: null,
        graso: null,
        graso_deseado: null,

        clasificacion_graso: null,
        clasificacion_muscular: null,
        somatotipo: null,

        electro: null,
        electro_pdf: null,

        /** Step 2 - Ergo espirometría*/
        // Carrousel 1
        vo2_max_rel: null,
        watts_max_rel: null,
        watts_max_abs: null,
        fc_max: null,

        // Carrousel 2
        vo2_vt2_rel: null,
        watts_vt2_abs: null,
        watts_vt2_rel: null,
        fc_vt2: null,

        ergo_pdf: null,
        conclusion_ergo: null,

        /** Step 3 - Biomarcadores */
        hemoglobina: null,
        hematocitos: null,
        leucocitos: null,
        plaquetas: null,
        ferritina: null,
        colesterol_total: null,
        colesterol_ldl: null,
        colesterol_hdl: null,
        tag: null,
        glucosa: null,

        // No sé en qué parte del front se visualiza
        iaks: null,
        lab_sericos: null,
      },
    },
  },
  navigation: {
    showWelcome: false,
  },
  nutrition: {
    formMeal: {
      key: "redux-meal",
      step: 0,
      disableNextStep: false,
      tipo: "Desayuno",
      hora: 480,
      alimentos: [
        {
          id_alimento: "form-aliment-0",
          descripcion: null,
          porcion: null,
          medida: null,
          intercambios: null,
        },
      ],
    },
  },
  calendar: {
    taskForm: {
      fecha_hora: null,
      titulo: null,
      cantidad: null,
      unidades: null,
      deporte: null,
      descripcion: null,
    },
  },
});
export default initialState;
