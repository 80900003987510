import React from "react";
import { Col, Row, Skeleton } from "antd";

// Utils
import { mapHourToColor } from "../../utils/utils";

const MealCardLoading = () => {
  const cardColorTag = mapHourToColor(-1, "tag");
  const cardClassName = `w-full bg-white rounded-2xl mb-4 shadow-neo-card border-2 border-${cardColorTag}`;

  return (
    <div className={cardClassName}>
      {/* Color stripe */}
      <div
        className={`h-4 rounded-t-xl bg-${cardColorTag}`}
        style={{ background: cardColorTag }}
      />
      <div className="p-6 pt-3">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col span={20}>
            <Skeleton.Button active size={"small"} />
          </Col>
          <Col span={4} className="text-right">
            <Skeleton.Button active size={"small"} />
          </Col>
        </Row>

        <div
          className="flex flex-col justify-between"
          style={{ height: "85%" }}
        >
          <Row>
            <Col span={13} className="px-2">
              <Skeleton active />
            </Col>
            <Col span={4} className="px-2">
              <Skeleton active />
            </Col>
            <Col span={7} className="px-2">
              <Skeleton active />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MealCardLoading;
