import React, { useEffect, useState } from "react";
import { Button, message, Modal, Row, Upload } from "antd";
import { CloudUploadOutlined, EyeOutlined } from "@ant-design/icons";

// Components
import PreviewUploadCard from "./uploadExams/PreviewUploadCard";
import moment from "moment";

const UploadExams = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false); //cloud button

  const [showPreview, setShowPreview] = useState(false);
  const [uploading, setUploading] = useState(false); //preview items
  const [date, setDate] = useState(moment().format("DD-MM-YYYY"));

  const handleConfirm = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    setUploading(true);
    // You can use any AJAX library you like
    fetch("https://www.mocky.io/v2/5cc8019d300000980a055e76", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        message.success("Datos subidos correctamente.");
      })
      .catch(() => {
        setFileList([]);
        message.success("Datos subidos correctamente.");
        // message.error("upload failed.");
      })
      .finally(() => {
        setUploading(false);
        setShowPreview(false);
      });
  };

  const props = {
    // name: "file",
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    // headers: {
    //   authorization: "authorization-text",
    // },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} archivo subido exitosamente`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} error subiendo archivo.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(0))}%`,
    },

    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const newFileList = [file];
      setFileList(newFileList);
      return false;
    },
  };

  useEffect(() => {
    if (fileList.length !== 0) {
      setLoading(true);
      setTimeout(() => {
        setShowPreview(true);
        setLoading(false);
      }, 1200);
    }
  }, [fileList]);

  return (
    <>
      <Row className="w-1/3 justify-center">
        <Upload accept=".pdf" maxCount={1} {...props} className="text-center">
          <Button
            icon={<CloudUploadOutlined />}
            type="primary"
            loading={loading}
            className="shadow-neo-button rounded-neo-button mx-auto"
          >
            {fileList.length === 0 ? "Cargar exámen" : "Cargar otro exámen"}
          </Button>
        </Upload>
      </Row>
      {fileList.length !== 0 && (
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button mx-auto mt-6"
          ghost
          onClick={() => setShowPreview(true)}
          icon={<EyeOutlined />}
        >
          Previsualizar valores
        </Button>
      )}

      <Modal
        maskClosable={false}
        visible={showPreview}
        footer={null}
        className="rounded-lg biomarkers-preview-card"
        closable={false}
        width={400}
      >
        <PreviewUploadCard
          date={date}
          setDate={setDate}
          uploading={uploading}
          handleConfirm={handleConfirm}
          showPreview={setShowPreview}
          setShowPreview={setShowPreview}
        />
      </Modal>
    </>
  );
};

export default UploadExams;
