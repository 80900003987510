import React from "react";
import { Col, Row, Tooltip } from "antd";

// Redux
import { useDispatch } from "react-redux";
import { updateAlimentField } from "../../features/nutrition/mealFormSlice";

const PresetAliment = ({ preset, formId, hideMenu }) => {
  const { descripcion, porcion, unidades, intercambios } = preset;
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(
      updateAlimentField({
        id: formId,
        fieldName: "descripcion",
        val: descripcion,
      })
    );
    dispatch(
      updateAlimentField({
        id: formId,
        fieldName: "porcion",
        val: porcion,
      })
    );
    dispatch(
      updateAlimentField({
        id: formId,
        fieldName: "medida", // keep an eye on this
        val: unidades, // keep an eye on this gotta be renamed in db
      })
    );
    dispatch(
      updateAlimentField({
        id: formId,
        fieldName: "intercambios",
        val: intercambios,
      })
    );
    hideMenu();
  };

  return (
    <Tooltip
      placement="right"
      title={
        <>
          {descripcion}
          <br />
          {porcion} {unidades}
          <br />
          {intercambios}
        </>
      }
    >
      <>
        <div className="w-full cursor-pointer p-1px mb-2 shadow-neo-button-sm rounded-md bg-gradient-to-tr from-gray-300 to-gray-300 hover:from-cyan-600 hover:to-teal-200 ">
          <Row
            onClick={handleOnClick}
            justify="space-between"
            //FIXME: make the last: pseudoclass work
            className="px-3 m py-1 h-full w-full bg-white last:border-b-0 odd:bg-gray-200 rounded-md"
          >
            <Col
              span={18}
              className="text-sm overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {descripcion}
            </Col>
            <Col
              span={5}
              className="text-sm overflow-hidden whitespace-nowrap overflow-ellipsis text-gray-400"
            >
              {porcion} {unidades}
            </Col>
          </Row>
        </div>
      </>
    </Tooltip>
  );
};

export default PresetAliment;
