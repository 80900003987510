import { createRequest, throwErrors } from "./globalServices";

export const getUserInfo = async (userId) => {
  try {
    const response = await createRequest().get(`/usuarios/${userId}`);
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const getPatientsInfo = async (userId) => {
  try {
    const response = await createRequest().get(`/usuarios/pacientes/${userId}`);
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};
