import React, { useState, useEffect } from "react";
import { Avatar, Upload, Tooltip, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

// Config
import { storage } from "../../config/firebase-config";
import { useSelector } from "react-redux";

// Utils
import { openNotification } from "utils/utils";
/* 
  Rounded avatar used to display profile photo and the update photo botton.
*/
const CustomAvatar = ({ photoUrl, size, loading, allowEdit }) => {
  return (
    <>
      {loading ? (
        <Skeleton.Avatar active={true} size={size} shape={"circle"}>
          <LoadingOutlined />
        </Skeleton.Avatar>
      ) : photoUrl ? (
        <Avatar
          size={size}
          alt="profile-picture"
          src={photoUrl}
          className={allowEdit ? "shadow-neo-blue" : ""}
        />
      ) : (
        <Avatar
          size={size}
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
          className="shadow-neo-blue"
          src={photoUrl}
        />
      )}
    </>
  );
};

const ProfilePhoto = ({
  size = 48,
  photoUrl,
  setPhotoUrl,
  allowEdit = false,
}) => {
  const firebaseUser = useSelector((state) => state.user.firebaseUser);

  const [loading, setLoading] = useState(false);

  const beforeUpload = (file) => {
    //check file type matches img
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      openNotification("error", `${file.name} no es un tipo valido de imagen`);
      return null;
    }

    //check file size
    const isSizeLessThan5Mb = file.size / 1024 / 1024 < 5;
    if (!isSizeLessThan5Mb) {
      openNotification("error", "La imagen debe pesar menos de 5Mb!");
      return null;
    }
    return isImage && isSizeLessThan5Mb;
  };
  /*
    Summary: 
    - Create filerefernce to Firestore Storage route.
    - Convert image to blob file (requiered to upload it to Storage)
    - Upload blob file, then gets the url.
    - Set the global photo url state 
 */
  const uploadImage = async (file) => {
    let metadata = {
      contentType: "image/",
    };
    const fileRef = ref(
      storage,
      `${firebaseUser?.uid}/profile_picture_${firebaseUser?.uid}.jpg`
    );

    // Convert to blob because is the only filtype accepted by Firebase Storage
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", file, true);
      xhr.send(null);
    });

    setLoading(true);

    // Upload image and get url

    uploadBytes(fileRef, blob, metadata)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setPhotoUrl(url);
        });
        console.log("Uploaded image!");
        setTimeout(
          () => openNotification("success", "Foto de perfil actualizada"),
          1000
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert("error updating file", error);
        console.log(error);
      });
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    setLoading(true);

    if (info.file.status !== "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        uploadImage(imageUrl);
        setLoading(false);
      });
    }
  };
  /*
    Gets firestorage profile photo url. Or use default avatar.
 */
  useEffect(() => {
    if (firebaseUser?.uid !== "") {
      setLoading(true);
      getDownloadURL(
        ref(
          storage,
          `${firebaseUser?.uid}/profile_picture_${firebaseUser?.uid}.jpg`
        )
      )
        .then((url) => {
          setPhotoUrl(url);
          setLoading(false);
        })
        .catch(() => {
          console.info("No profile picture. Using default avatar.");
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser]);
  return (
    <div className="mx-2">
      {allowEdit ? (
        <Tooltip
          title={`Click para cambiar foto de perfil`}
          className="cursor-pointer"
        >
          <Upload
            onChange={handleChange}
            name="avatar"
            showUploadList={false}
            beforeUpload={beforeUpload}
            className="text-left"
          >
            <CustomAvatar
              photoUrl={photoUrl}
              size={size}
              loading={loading}
              allowEdit={allowEdit}
            />
          </Upload>
        </Tooltip>
      ) : (
        <CustomAvatar photoUrl={photoUrl} size={size} loading={loading} />
      )}
    </div>
  );
};

export default ProfilePhoto;
