import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Tooltip, Card, Button, Space } from "antd";

// Components
import Title from "antd/lib/typography/Title";
import PreviewDate from "./PreviewDate";
import PreviewBiomarker from "./PreviewBiomarker";
// Constants
const fakeEmptyData = [
  {
    key: 1,
    name: "Hemoglobina",
  },
  {
    key: 2,
    name: "Hematocrito",
  },
  {
    key: 3,
    name: "Leucocitos",
  },
  {
    key: 4,
    name: "Plaquetas",
  },
  {
    key: 5,
    name: "Ferritina",
  },
  {
    key: 6,
    name: "Colesterol total",
  },
  {
    key: 7,
    name: "Colesterol LDL",
  },
  {
    key: 8,
    name: "Colesterol HDL",
  },
  {
    key: 9,
    name: "TAG",
  },
  {
    key: 10,
    name: "Glucosa",
  },
];
const fakeData = [
  {
    key: 1,
    name: "Hemoglobina",
    found: true,
    loading: false,
    val: 123,
    suffix: "g/dl",
  },
  {
    key: 2,
    name: "Hematocrito",
    found: true,
    loading: false,
    val: 123,
    suffix: "%",
  },
  {
    key: 3,
    name: "Leucocitos",
    found: false,
    loading: false,
    val: 123,
    suffix: "",
  },
  {
    key: 4,
    name: "Plaquetas",
    found: true,
    loading: false,
    val: 258977,
    suffix: "",
  },
  {
    key: 5,
    name: "Ferritina",
    found: true,
    loading: true,
    val: 123,
    suffix: "ug/L",
  },
  {
    key: 6,
    name: "Colesterol total",
    found: true,
    loading: false,
    val: 123,
    suffix: "mg/dl",
  },
  {
    key: 7,
    name: "Colesterol LDL",
    found: false,
    loading: true,
    val: 123,
    suffix: "mg/dl",
  },
  {
    key: 8,
    name: "Colesterol HDL",
    found: true,
    loading: false,
    val: 123,
    suffix: "mg/dl",
  },
  {
    key: 9,
    name: "TAG",
    found: false,
    loading: true,
    val: 123,
    suffix: "mg/dl",
  },
  {
    key: 10,
    name: "Glucosa",
    found: true,
    loading: true,
    val: 123,
    suffix: "mg/dl",
  },
];
const PreviewUploadCard = ({
  handleConfirm,
  showPreview,
  setShowPreview,
  uploading,
  date,
  setDate,
}) => {
  const [data, setData] = useState(fakeEmptyData);
  const [loadingFakeData, setLoadingFakeData] = useState(false);

  useEffect(() => {
    setLoadingFakeData(true);

    setTimeout(() => {
      setData(fakeData);
      setLoadingFakeData(false);
    }, 1800);
  }, []);

  useEffect(() => {
    if (!showPreview) {
      setData(fakeEmptyData);
    } else {
      setData(fakeData);
    }
  }, [showPreview]);

  return (
    <Card
      className="rounded-lg"
      bordered={false}
      title={<Title level={5}>Previsualización de biomarcadores</Title>}
      style={{
        width: "100%",
      }}
      size="small"
      actions={[
        <Tooltip key="accept" title={"Aceptar"}>
          <Button
            loading={uploading}
            className="w-full"
            type="text"
            onClick={() => handleConfirm()}
            icon={<CheckOutlined style={{ color: "#52c41a" }} />}
          />
        </Tooltip>,
        <Tooltip key="discard" title={"Descartar"}>
          <Button
            type="text"
            className="w-full"
            onClick={() => setShowPreview(false)}
            icon={<CloseOutlined style={{ color: "#ff4d4f" }} />}
          />
        </Tooltip>,
      ]}
    >
      <PreviewDate date={date} setDate={setDate} />
      <Space direction="vertical" size={4} className="w-full mt-4">
        {data.map(({ key, name, val, suffix, found }) => (
          <PreviewBiomarker
            key={key}
            name={name}
            val={val}
            suffix={suffix}
            found={found}
            loading={loadingFakeData}
          />
        ))}
      </Space>
    </Card>
  );
};

export default PreviewUploadCard;
