import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCSaMrkaYCLdbJdIZTk8N6rt8BBYZfdigM",
  authDomain: "moebius-330919.firebaseapp.com",
  projectId: "moebius-330919",
  storageBucket: "moebius-330919.appspot.com",
  messagingSenderId: "276866994235",
  appId: "1:276866994235:web:8e5cbb85cddd5d7c048c1e",
  measurementId: "G-73QQQ9VZY3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
