import { notification } from "antd";
import { mainColors } from "../constants";

/**
 * @description
 * @param {Integer} minPastMidnight
 * @returns {String}
 */
export const mapHourToColor = (mins, type = "val") => {
  if (mins === -1) return mainColors.gray[type];
  let fixed = mins % 1440; //maximun minutes in a day
  if (fixed >= 360 && fixed < 540) return mainColors.orange[type]; //orange-500 6:00 - 9:00 breakfast
  if (fixed >= 540 && fixed < 690) return mainColors.yellow[type]; //yellow-400 9:00 - 11:30 brunch

  if (fixed >= 690 && fixed < 840) return mainColors.lime[type]; //lime-500 11:30 - 14:00 lunch

  if (fixed >= 840 && fixed < 1080) return mainColors.teal[type]; //teal-500 14:30 - 18:00  idk
  if (fixed >= 1080 && fixed < 1260) return mainColors.purple[type]; //purple-600 18:30 - 21:00  dinner
  if (fixed >= 1260 || fixed < 360) return mainColors.fuchsia[type]; //fuchsia-900 21:30 - 6:00 late nite
};

/**
 * @description
 * @param {Integer} minPastMidnight
 * @returns {String}
 */
export const mapMinsToTime = (mins) => {
  if (mins) {
    let fixed = mins % 1440; //maximun minutes in a day
    let hh = Math.floor(fixed / 60);
    let mm = `${fixed % 60}`.padEnd(2, "0");
    return `${hh}:${mm}`;
  }
  return "--:--";
};

/**
 * @description
 * @param {Integer} value in percentage range [0,100]
 * @returns {String} hex color value
 */

export const taskProgressToColor = (value) => {
  const colorGray = "#d9d9d9";

  if (value > 0 && value < 25) {
    // red-6
    return "#f5222d";
  } else if (value >= 25 && value < 50) {
    // orange-6
    return "#fa8c16";
  } else if (value >= 50 && value < 75) {
    // yellow-6
    return "#fadb14";
  } else if (value >= 75 && value <= 100) {
    // green
    return "#52c41a";
  } else {
    return colorGray;
  }
};

export const openNotification = (type, title, description = null) => {
  notification[type]({
    message: title,
    description: description,
    top: 6,
    duration: 3,
  });
};

export const calculateIMC = (_altura_cm, _peso_kg) => {
  if (_altura_cm && _peso_kg) {
    return parseFloat((_peso_kg / Math.pow(_altura_cm / 100, 2)).toFixed(2));
  } else {
    return null;
  }
};
