import { createRequest, throwErrors } from "./globalServices";

export const deleteMeal = async (mealId) => {
  try {
    const response = await createRequest().delete(
      `/nutricional/comidas/${mealId}`
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};
