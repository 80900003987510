import React from "react";
import { Col, Row, Typography } from "antd";

// Utils
import useWindowDimensions from "../../../utils/window";

// Redux
import { useSelector } from "react-redux";

const { Text } = Typography;

const CorporalCompTable = () => {
  const { width } = useWindowDimensions();
  const selectedRecord = useSelector((state) => state.record.selected);
  const { graso, peso_deseado, altura, peso, imc, iaks } = selectedRecord;

  const borderDivider = (color) => {
    return {
      padding: "4px 0",
      borderRight: `${width < 576 ? "none" : "1.5px solid " + color}`,
      borderBottom: `${width < 576 ? "1.5px solid " + color : "none"}`,
    };
  };
  return (
    <Col
      className="rounded-xl w-full"
      style={{
        boxShadow: `5px 5px 10px #cacaca,
            -5px -5px 10px #ffffff`,
      }}
    >
      <Row
        justify="center"
        className="w-full p-4 pt-2 rounded-t-xl"
        style={{
          border: "1.5px solid #45b6bc",
        }}
      >
        <Col xs={24} sm={8} style={borderDivider("#2784FF")}>
          <Row justify="center">
            <Text className="text-xl font-bold text-center">
              {!peso ? "-" : peso}
            </Text>
          </Row>
          <Row justify="center">
            <Text className="text-sm font-light text-center">Peso (kg)</Text>
          </Row>
        </Col>
        <Col xs={24} sm={8} style={borderDivider("#2784FF")}>
          <Row justify="center">
            <Text className="text-xl font-bold text-center">
              {!altura ? "-" : altura}
            </Text>
          </Row>
          <Row justify="center">
            <Text className="text-sm font-light text-center">Talla (cm)</Text>
          </Row>
        </Col>
        <Col xs={24} sm={8}>
          <Row justify="center">
            <Text className="text-xl font-bold text-center">
              {!peso_deseado ? "-" : peso_deseado}
            </Text>
          </Row>
          <Row justify="center">
            <Text className="text-sm font-light text-center">
              Peso Deseado (kg)
            </Text>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        className="w-full p-4 pt-2 rounded-b-xl"
        style={{
          background: "linear-gradient(185deg, #0a78b2, #45b6bc)",
          boxShadow: `inset 20px 20px 60px #2170d9,
                      inset -20px -20px 60px #2d98ff`,
        }}
      >
        <Col xs={24} sm={8} style={borderDivider("#FFF")}>
          <Row justify="center">
            <Text className="text-xl font-bold text-center text-white">
              {!imc ? "-" : imc}
            </Text>
          </Row>
          <Row justify="center">
            <Text className="text-sm font-light text-center text-white">
              IMC
            </Text>
          </Row>
        </Col>
        <Col xs={24} sm={8} style={borderDivider("#FFF")}>
          <Row justify="center">
            <Text className="text-xl font-bold text-center text-white">
              {!graso ? "-" : graso}
            </Text>
          </Row>
          <Row justify="center">
            <Text className="text-sm font-light text-center text-white">
              Grasa Corporal
            </Text>
          </Row>
        </Col>
        <Col xs={24} sm={8}>
          <Row justify="center">
            <Text className="text-xl font-bold text-center text-white">
              {!iaks ? "-" : iaks}
            </Text>
          </Row>
          <Row justify="center">
            <Text className="text-sm font-light text-center text-white">
              Grasa Visceral
            </Text>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default CorporalCompTable;
