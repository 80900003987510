import React from "react";
import { Button, Col, Form, Modal, Row, Steps, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Custom hook
import { useCreatePatient } from "./useCreatePatient";

// Components
import FormPatientBasicData from "./steps/FormPatientBasicData";
import FormPatientSport from "./steps/FormPatientSport";
import FormPatientMedical from "./steps/FormPatientMedical";

// Constants
const { Title } = Typography;
const { Step } = Steps;

const CreatePatient = () => {
  /** Forms intanced here to clear the error messages when closing modal on x button */
  const [formBasic] = Form.useForm();
  const [formSport] = Form.useForm();
  const [formMedical] = Form.useForm();

  const { visibleModal, setVisibleModal, formStep, handleOnClose } =
    useCreatePatient();

  const steps = [
    {
      title: "Datos Básicos",
      content: <FormPatientBasicData form={formBasic} />,
    },
    {
      title: "Deporte",
      content: <FormPatientSport form={formSport} />,
    },
    {
      title: "Valoración médica",
      content: <FormPatientMedical form={formMedical} />,
    },
  ];
  const handleOnCancel = () => {
    handleOnClose();
    /** Clear input errors for each step form */
    formBasic.resetFields();
    formSport.resetFields();
    formMedical.resetFields();
  };
  return (
    <div className="flex flex-col">
      <Button
        className="shadow-neo-button rounded-neo-button mx-auto mt-3"
        type="primary"
        onClick={() => setVisibleModal(true)}
        icon={<PlusOutlined />}
      >
        Crear Paciente
      </Button>

      <Modal
        maskClosable={false}
        footer={null}
        onCancel={handleOnCancel}
        width={600}
        visible={visibleModal}
      >
        <Col>
          <Row className="mb-4">
            <Title level={4} className="font-bold w-full text-center">
              Creación de paciente
            </Title>
          </Row>
          <Steps
            current={formStep}
            className="mt-4 h-full"
            responsive
            size="small"
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div className="w-full mt-6">{steps[formStep].content}</div>
        </Col>
      </Modal>
    </div>
  );
};

export default CreatePatient;
