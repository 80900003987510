import React, { useState } from "react";
import { Form, Table } from "antd";

// Redux
import { useSelector } from "react-redux";

//Components
import EditableAlimentCell from "./EditableAlimentRow";
import ActionColumn from "./ActionColumn";

const renderNoInfo = () => {
  return <span className="text-gray-400 italic">Sin inforamción</span>;
};

const AlimentTable = ({ hideId, editable, mealData, getPlanByPatient }) => {
  const databaseUser = useSelector((state) => state.user.databaseUser);

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");
  const [loadingEditAliment, setLoadingEditAliment] = useState(false);

  const isEditing = (record) => record.id_alimento === editingKey;

  // Shows aliment ID to admin and development mode
  const showIdRow = (rol) => {
    const columns = [];
    if (!hideId && (rol === 1 || process.env.NODE_ENV === "development"))
      columns.push({
        title: "ID",
        dataIndex: "id_alimento",
        key: "id_alimento",
        width: 55,
        fixed: "left",
      });

    return columns;
  };

  const showEditRow = (rol) => {
    const columns = [];
    if (editable && rol === 1) {
      columns.push({
        title: "Acción",
        dataIndex: "accion",
        width: 80,
        fixed: "right",
        render: (_, record) => {
          return (
            <ActionColumn
              record={record}
              mealData={mealData}
              form={form}
              isEditing={isEditing}
              getPlanByPatient={getPlanByPatient}
              editingKey={editingKey}
              setEditingKey={setEditingKey}
              loadingEditAliment={loadingEditAliment}
              setLoadingEditAliment={setLoadingEditAliment}
            />
          );
        },
      });
    }

    return columns;
  };

  const columns = [
    ...showIdRow(databaseUser.rol),

    {
      title: "Alimento",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "40%",

      editable: true,
      render: (val) => val ?? renderNoInfo(),
    },
    {
      title: "Porción",
      dataIndex: "porcion",
      key: "porcion",
      editable: true,
      width: "20%",
      render: (text, record) =>
        record.porcion || record.medida ? (
          <span>
            {record.porcion} {record.medida ?? ""}
          </span>
        ) : (
          renderNoInfo()
        ),
    },
    {
      title: "Intercambios",
      dataIndex: "intercambios",
      key: "intercambios",
      editable: true,
      render: (val) => val ?? renderNoInfo(),
    },
    ...showEditRow(databaseUser.rol),
  ];

  // If the column is editable, add the necessary props in order to correct functionality of <EditableRow />
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        scroll={{ x: 700 }}
        components={{
          body: {
            cell: EditableAlimentCell,
          },
        }}
        className="w-full"
        size="small"
        columns={mergedColumns}
        dataSource={mealData.alimentos}
        pagination={false}
        rowKey="id_alimento"
      />
    </Form>
  );
};

export default AlimentTable;
