import React from "react";
import { Form, Row, Select, TimePicker, Typography } from "antd";
import moment from "moment";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateFormMealTime,
  updateFormMealType,
} from "../../features/nutrition/mealFormSlice";

// Utils
import { mapHourToColor } from "../../utils/utils";
import { mealNames } from "../../constants";

// Const
const { Title } = Typography;
const { Option } = Select;
const layout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
    md: 6,
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
    md: 18,
  },
};
const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Por favor seleccione una hora!",
    },
  ],
};

const MealForm = () => {
  const dispatch = useDispatch();
  const mealForm = useSelector((state) => state.nutrition.formMeal);

  const cardColorTag = mapHourToColor(mealForm.hora, "tag");
  const cardClassName = `w-full bg-white border-2 rounded-2xl shadow-neo-card border-${cardColorTag}`;

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      "time-picker": fieldsValue["time-picker"].format("HH:mm:ss"),
    };
    console.log(values);
  };
  const onChangeTime = (time) => {
    if (time) {
      let startOfDay = moment().startOf("day");
      let selectedTimeParsed = time?.diff(startOfDay, "minutes");
      dispatch(updateFormMealTime(selectedTimeParsed));
    } else {
      dispatch(updateFormMealTime(-1));
    }
  };
  const onChangeType = (val) => {
    dispatch(updateFormMealType(val));
  };

  return (
    <div
      className={cardClassName}
      style={{
        height: "fit-content",
      }}
    >
      {/* Color stripe */}
      <div
        className={`h-4 rounded-t-xl bg-${cardColorTag}`}
        style={{ background: mapHourToColor(mealForm.hora) }}
      />
      <div className="px-6 py-3 pb-0">
        <Row className="mb-4">
          <Title level={4} className="font-bold w-full text-center">
            Agregar comida
          </Title>
        </Row>
        <Row>
          <Form
            className="w-full"
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            initialValues={{
              tipo: mealForm.tipo,
              "time-picker": moment
                .utc()
                .startOf("day")
                .add(mealForm.hora, "minutes"),
            }}
          >
            <Form.Item
              className="my-select-container"
              name="tipo"
              label="Tipo"
              rules={[
                {
                  required: true,
                  message: "Seleccione una opción!",
                },
              ]}
            >
              <Select
                placeholder="Seleccione el nombre"
                className="rounded-lg shadow-neo-field"
                value={mealForm.tipo}
                onChange={onChangeType}
              >
                {mealNames.map((e) => (
                  <Option key={e.val} value={e.val}>
                    {e.tag}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="time-picker" label="Hora" {...config}>
              <TimePicker
                format="HH:mm"
                className="w-full rounded-lg shadow-neo-field"
                minuteStep={15}
                onChange={onChangeTime}
              />
            </Form.Item>
          </Form>
        </Row>
      </div>
    </div>
  );
};

export default MealForm;
