import React, { useState } from "react";
import { Alert, Table, Tooltip } from "antd";
import { useSelector } from "react-redux";

//Table data is at the end of the file
const VO2Table = ({ patientRecord, loading }) => {
  const [vo2Header, setVo2Header] = useState();
  const selectedPatient = useSelector((state) => state.patient.selected);

  const source =
    selectedPatient?.genero[0].toLowerCase() === "m" ? dataMen : dataWomen;
  const checkValueInRange = (val, rng) => {
    let range = rng?.split("-");
    if (val >= parseInt(range[0]) && val <= parseInt(range[1])) {
      return true;
    }
    return false;
  };

  const checkColHeaderHighlight = (_row, _vo2Range) => {
    Object.keys(_row).forEach((key) => {
      if (
        _row[key] === _vo2Range &&
        checkValueInRange(selectedPatient?.edad, _row.ageRange)
      ) {
        setVo2Header(key);
      }
    });
  };

  const renderColCells = (vo2Range) => {
    const row = source.find((r) => Object.values(r).includes(vo2Range));
    let range;
    let vo2maxIsInRange = false;

    if (row) {
      // Check if vo2max patient value is in any possible range of the table -
      // to highligth the row and the column of it (line 67).
      if (vo2Range.includes("-")) {
        if (checkValueInRange(patientRecord?.vo2_max_rel, vo2Range)) {
          vo2maxIsInRange = true;
          checkColHeaderHighlight(row, vo2Range);
        }
      } else if (vo2Range.includes("<")) {
        range = vo2Range.slice(-2);
        if (patientRecord?.vo2_max_rel < parseInt(range)) {
          vo2maxIsInRange = true;
          checkColHeaderHighlight(row, vo2Range);
        }
      } else if (vo2Range.includes("≥")) {
        range = vo2Range.slice(-2);
        if (patientRecord?.vo2_max_rel >= parseInt(range)) {
          vo2maxIsInRange = true;
          checkColHeaderHighlight(row, vo2Range);
        }
      }
      // Renders cell style
      if (
        vo2maxIsInRange &&
        checkValueInRange(selectedPatient?.edad, row?.ageRange)
      ) {
        return {
          props: {
            style: {
              background: vo2maxIsInRange ? "#c3ecff" : "#fff",
              border: vo2maxIsInRange ? "1px solid #91d5ff" : "none",
              color: vo2maxIsInRange ? "#096dd9" : "#000",
            },
          },
          children: (
            <Tooltip title={patientRecord?.vo2_max_rel}>
              <span className="font-bold">{vo2Range}</span>
            </Tooltip>
          ),
        };
      }
    }
    return {
      children: <span>{vo2Range}</span>,
    };
  };

  const colsTitles = {
    low: "Bajo",
    belowAvg: "Menor al promedio",
    aboveAvg: "Mayor al promedio",
    high: "Alto",
    elite: "Elite",
  };

  const renderColHeader = (dataIndex) => {
    let isThisTheRange = colsTitles[vo2Header] === colsTitles[dataIndex];
    return (
      <div
        className={`${
          isThisTheRange ? "table-col-header-highligth" : "table-col-header"
        }`}
      >
        {colsTitles[dataIndex]}
      </div>
    );
  };
  const columns = [
    {
      title: "Edad",
      width: 80,
      dataIndex: "ageRange",
      key: "ageRange",
      fixed: "left",
    },
    {
      title: `Desempeño agrupado por VO2 Max - ${selectedPatient?.genero}`,
      children: [
        {
          title: renderColHeader("low"),
          width: 80,
          dataIndex: "low",
          key: "low",
          render: renderColCells,
        },
        {
          title: renderColHeader("belowAvg"),
          dataIndex: "belowAvg",
          key: "belowAvg",
          render: renderColCells,
        },
        {
          title: renderColHeader("aboveAvg"),
          dataIndex: "aboveAvg",
          key: "aboveAvg",
          render: renderColCells,
        },
        {
          title: renderColHeader("high"),
          width: 80,
          dataIndex: "high",
          key: "high",
          render: renderColCells,
        },
        {
          title: renderColHeader("elite"),
          width: 80,
          dataIndex: "elite",
          key: "elite",
          render: renderColCells,
        },
      ],
    },
  ];
  return (
    <div className="w-full bg-white p-6 rounded-2xl shadow-neo-card">
      {/* Its necessary to have age and gender to display the functional vo2max table.
       Otherwise, the table is useless */}
      {selectedPatient?.genero && selectedPatient?.edad ? (
        <Table
          loading={loading}
          scroll={{ x: 520 }}
          columns={columns}
          dataSource={source}
          rowKey={(record) => record.ageRange}
          rowClassName={(record) => {
            if (checkValueInRange(selectedPatient?.edad, record.ageRange)) {
              return "bg-blue-50";
            }
          }}
          size="small"
          pagination={false}
        />
      ) : (
        <Alert
          message="No es posible mostrar la tabla V02"
          description="El paciente no registra genero o edad."
          type="warning"
          showIcon
        />
      )}
    </div>
  );
};

export default VO2Table;

// Reference data extracted from Standart V02 Max vs Age table. Common in bike sports
const dataMen = [
  {
    ageRange: "18-19",
    low: "<38",
    belowAvg: "38-45",
    aboveAvg: "46-49",
    high: "50-57",
    elite: "≥ 58",
  },
  {
    ageRange: "20-29",
    low: "<36",
    belowAvg: "36-42",
    aboveAvg: "43-48",
    high: "49-55",
    elite: "≥ 56",
  },
  {
    ageRange: "30-39",
    low: "<35",
    belowAvg: "35-39",
    aboveAvg: "40-45",
    high: "46-52",
    elite: "≥ 53",
  },
  {
    ageRange: "40-49",
    low: "<34",
    belowAvg: "34-38",
    aboveAvg: "39-43",
    high: "44-51",
    elite: "≥ 52",
  },
  {
    ageRange: "50-59",
    low: "<29",
    belowAvg: "29-35",
    aboveAvg: "36-40",
    high: "41-49",
    elite: "≥ 50",
  },
  {
    ageRange: "60-69",
    low: "<25",
    belowAvg: "25-29",
    aboveAvg: "30-35",
    high: "36-45",
    elite: "≥ 46",
  },
  {
    ageRange: "70-79",
    low: "<21",
    belowAvg: "21-24",
    aboveAvg: "25-29",
    high: "30-40",
    elite: "≥ 41",
  },
  {
    ageRange: "≥ 80",
    low: "<18",
    belowAvg: "18-22",
    aboveAvg: "23-25",
    high: "26-35",
    elite: "≥ 36",
  },
];
const dataWomen = [
  {
    ageRange: "18-19",
    low: "< 35",
    belowAvg: "35-39",
    aboveAvg: "40-45",
    high: "46-52",
    elite: "≥ 53",
  },
  {
    ageRange: "20-29",
    low: "< 28",
    belowAvg: "28-35",
    aboveAvg: "36-40",
    high: "41-50",
    elite: "≥ 51",
  },
  {
    ageRange: "30-39",
    low: "< 27",
    belowAvg: "27-33",
    aboveAvg: "34-38",
    high: "39-48",
    elite: "≥ 49",
  },
  {
    ageRange: "40-49",
    low: "< 26",
    belowAvg: "26-31",
    aboveAvg: "32-36",
    high: "37-46",
    elite: "≥ 47",
  },
  {
    ageRange: "50-59",
    low: "< 25",
    belowAvg: "25-28",
    aboveAvg: "29-35",
    high: "36-45",
    elite: "≥ 46",
  },
  {
    ageRange: "60-69",
    low: "< 21",
    belowAvg: "21-24",
    aboveAvg: "25-29",
    high: "30-39",
    elite: "≥ 40",
  },
  {
    ageRange: "70-79",
    low: "< 18",
    belowAvg: "18-21",
    aboveAvg: "22-24",
    high: "25-35",
    elite: "≥ 36",
  },
  {
    ageRange: "≥ 80",
    low: "< 15",
    belowAvg: "15-19",
    aboveAvg: "20-22",
    high: "23-29",
    elite: "≥ 30",
  },
];
