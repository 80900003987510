import { createRequest, throwErrors } from "./globalServices";

export const createTask = async (userId, taskBody) => {
  try {
    const response = await createRequest().post(
      `/tareas/pacientes/${userId}`,
      taskBody
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const deleteTask = async (taskId) => {
  try {
    const response = await createRequest().delete(`/tareas/${taskId}`);
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const increaseProgressTask = async (taskId) => {
  try {
    const response = await createRequest().patch(
      `/tareas/aumentar-progreso/${taskId}`
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const decreaseProgressTask = async (taskId) => {
  try {
    const response = await createRequest().patch(
      `/tareas/disminuir-progreso/${taskId}`
    );
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const completeProgressTask = async (taskId) => {
  try {
    const response = await createRequest().patch(`/tareas/completar/${taskId}`);
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};
