import React, { useEffect, useState } from "react";
import { Col, Layout, Menu, Row, Select, Tooltip, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedPatient } from "../../features/patient/patientSlice";

// Components
import CreatePatient from "./formPatient/CreatePatient";

//Utils
import useWindowDimensions from "../../utils/window";

//Consts
const { Option } = Select;
const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

const CustomSider = () => {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();

  const patientList = useSelector((state) => state.patient.list);
  const selectedPatient = useSelector((state) => state.patient.selected);
  const databaseUser = useSelector((state) => state.user.databaseUser);

  const [patientOptions, setPatientOptions] = useState([]);
  const handleChangePatient = (id_paciente) => {
    dispatch(
      updateSelectedPatient(
        patientList.find((p) => p.id_paciente === id_paciente)
      )
    );
    window.scrollTo(0, 0);
  };

  // Update patients select options
  useEffect(() => {
    const options = patientList.map((patient) => (
      <Option key={patient.id_paciente} value={patient.id_paciente}>
        {patient.nombres + " " + patient.apellidos}
      </Option>
    ));
    setPatientOptions(options);
  }, [patientList]);

  return (
    <>
      {/* Patient list , or "back" button when displaying pdfs. */}
      {width > 768 ? (
        <Sider
          width={180}
          style={{
            display: databaseUser?.rol === 2 ? "none" : null,
            boxShadow: `2px 2px 10px #c9cdd8`,
            border: "none",
          }}
          theme="light"
          trigger={null}
          className="menu"
        >
          <div className="fixed top-16 h-full">
            <CreatePatient />
            <Menu
              style={{
                borderRight: 0,
                height: "100vh",
                width: "180px",
              }}
              selected={selectedPatient.id_paciente}
              defaultOpenKeys={["pacientes"]}
              defaultSelectedKeys={selectedPatient.id_paciente}
              mode="inline"
              theme="light"
              onClick={(e) => {
                handleChangePatient(e.key);
              }}
            >
              <SubMenu
                key="pacientes"
                title={
                  <span>
                    <UserOutlined />
                    <span>Pacientes</span>
                  </span>
                }
              >
                {patientList.map(({ nombres, apellidos, id_paciente }) => {
                  return (
                    <Menu.Item
                      style={{ height: "28px" }}
                      key={id_paciente}
                      className="text-left"
                    >
                      <Tooltip
                        placement="right"
                        title={nombres + " " + apellidos}
                      >
                        <span>
                          {nombres.split(" ")[0] +
                            " " +
                            apellidos.split(" ")[0]}
                        </span>
                      </Tooltip>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            </Menu>
          </div>
        </Sider>
      ) : (
        <Col className="mx-6 mb-2 mt-20">
          {databaseUser?.rol === 1 ? (
            <>
              <Row> Seleccione un paciente</Row>
              <Row className="my-2 my-select-container">
                <Select
                  size="large"
                  onChange={handleChangePatient}
                  defaultValue={selectedPatient?.id_paciente}
                  showSearch
                  style={{ borderRadius: "50px" }}
                  className="w-72"
                  placeholder="Seleccionar paciente"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.includes(input)
                  }
                >
                  {patientOptions}
                </Select>
              </Row>
            </>
          ) : (
            <Typography>
              <Title level={3}>
                {selectedPatient.nombres + " " + selectedPatient.apellidos}
              </Title>
            </Typography>
          )}
        </Col>
      )}
    </>
  );
};

export default CustomSider;
