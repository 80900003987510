// Reference: https://redux.js.org/tutorials/quick-start
import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";
import initialState from "../initialState";

export const patientSlice = createSlice({
  name: "patient",
  initialState: initialState().patient,
  reducers: {
    clearPatientState: (state) => {
      state.selected = initialState().patient.selected;
      state.list = initialState().patient.list;
    },
    updateSelectedPatient: (state, action) => {
      state.selected = action.payload;
    },
    updatePatientList: (state, action) => {
      state.list = action.payload;
    },

    includeNewPatient: (state, action) => {
      let prevPatientList = [...state.list];
      prevPatientList.push(action.payload);

      const patientList = prevPatientList.sort((a, b) => {
        const nombreA = a.nombres.toUpperCase();
        const nombreB = b.nombres.toUpperCase();
        return nombreA < nombreB ? -1 : nombreA > nombreB ? 1 : 0;
      });

      state.list = [...patientList];
    },
    updateIsReady: (state, action) => {
      state.isReady = action.payload;
    },

    /** Form */
    updateVisible: (state, action) => {
      state.patientForm.visible = action.payload;
    },
    updateError: (state, action) => {
      state.patientForm.error = action.payload;
    },

    updateLoading: (state, action) => {
      state.patientForm.loading = action.payload;
    },

    clearPatientFormState: (state) => {
      state.patientForm = initialState().patient.patientForm;
    },

    updatePatientFormField: (state, action) => {
      const { fieldName, value } = action.payload;
      if (fieldName === "fecha_nacimiento") {
        const todayDate = moment();
        const edad = todayDate.diff(moment(value, "DD/MM/YYYY"), "years");
        state.patientForm.values.edad = edad;
      }
      // TODO: not so sure about using current here:
      state.patientForm.values = {
        ...current(state).patientForm.values,
        [fieldName]: value,
      };
    },

    nextFormStep: (state) => {
      if (state.patientForm.step < 3) {
        state.patientForm.step += 1;
      }
    },
    prevFormStep: (state) => {
      if (state.patientForm.step > 0) {
        state.patientForm.step -= 1;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateIsReady,
  clearPatientState,
  updateSelectedPatient,
  updatePatientList,
  includeNewPatient,
  /** Form */
  updateVisible,
  updateError,
  updateLoading,
  clearPatientFormState,
  nextFormStep,
  prevFormStep,
  updatePatientFormField,
} = patientSlice.actions;

export default patientSlice.reducer;
