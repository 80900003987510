import React, { useEffect, useState } from "react";
import { Button, Popover } from "antd";
import { MenuOutlined } from "@ant-design/icons";

// Components
import CategoriesCollapse from "./CategoriesCollapse";

// Services
import { getCategories } from "services/libraryService";

// Consts
import { libraryGroups } from "../../constants";

const PresetsMenu = ({ groupId, formId }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const hideMenu = () => {
    setShowMenu(false);
  };
  const handleOpenChange = (newOpen) => {
    setShowMenu(newOpen);
  };

  useEffect(() => {
    const handleGetCategories = async (groupId) => {
      setLoadingCategories(true);
      try {
        let res = await getCategories(groupId);
        setCategories(res.data);
      } catch (e) {
        console.log("Grupo sin categorias", e);
        setCategories([]);
      } finally {
        setLoadingCategories(false);
      }
    };

    handleGetCategories(groupId);
  }, [groupId]);

  return (
    <Popover
      className="rounded-2xl"
      placement="bottomLeft"
      trigger="click"
      visible={showMenu}
      onVisibleChange={handleOpenChange}
      content={
        <CategoriesCollapse
          groupId={groupId}
          categories={categories}
          loading={loadingCategories}
          hideMenu={hideMenu}
          formId={formId}
        />
      }
      title={
        <div className="text-base my-1 font-bold w-full text-center">
          {libraryGroups[groupId]} prestablecidas
        </div>
      }
    >
      <Button
        className="text-xs text-gray-500 shadow-neo-button-sm"
        shape="circle"
        icon={<MenuOutlined size={"small"} />}
      />
    </Popover>
  );
};

export default PresetsMenu;
