import React from "react";
import Chart from "react-apexcharts";

// 2D Graph to display the current user vo2max vs age.
// The main David's idea was to have a "highligthed healthy reference area of the chart"
// and a point that describes the atlethe current status.
const VO2Chart = ({ bikers }) => {
  let series = [
    {
      // SCATTER GRAPH
      name: "Nombre del deportista",
      type: "scatter",
      data: bikers.map(({ graso, vo2_max_relativo, fecha_registro }) => {
        return {
          x: !vo2_max_relativo ? 0 : vo2_max_relativo,
          y: !graso ? 0 : parseFloat(graso.replace(",", ".")),
          z: !fecha_registro ? 0 : fecha_registro.value,
        };
      }),
    },
    {
      // LINE GRAPH
      // Points that describe the healty area of the chart
      name: "Zona de referencia",
      type: "area",
      data: [
        {
          x: 20,
          y: 9,
        },
        {
          x: 25,
          y: 9.5,
        },
        {
          x: 30,
          y: 9.7,
        },
        {
          x: 45,
          y: 10,
        },
        {
          x: 50,
          y: 10.3,
        },
        {
          x: 55,
          y: 10.9,
        },
        {
          x: 60,
          y: 11.5,
        },
        {
          x: 65,
          y: 11.9,
        },
        {
          x: 70,
          y: 13,
        },
        {
          x: 75,
          y: 14,
        },
      ],
    },
  ];

  const options = {
    title: {
      // Valores tomados de data.js con cada punto correspondietnes a 12 deportistas diferetnes.
      // Lo ideal sería que cada punto corresponda a un registro diferente de un deportista en particular.
      text: "VO2 Máx vs. % Graso - DEMO DATA",
    },
    chart: {
      zoom: {
        enabled: false,
        type: "line",
      },
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [0, 3],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: [1, 0.2],
    },
    markers: {
      size: [8, 0],
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      y: {
        formatter: undefined,
        title: {
          formatter: "% Graso: ",
        },
      },
      x: {
        formatter: undefined,
        title: "VO2 Máx Relativo: ",
      },
      z: {
        formatter: undefined,
        title: "Fecha del registro: ",
      },
    },
    legend: {
      show: true,
    },

    yaxis: {
      title: {
        text: "% Graso",
      },
      min: 5,
    },
    xaxis: {
      title: {
        text: "VO2 Máx",
        align: "center",
      },
      min: 20,
    },
  };

  return (
    <div
      className="w-full bg-white p-6 rounded-2xl h-full shadow-neo-card"
      style={{
        minHeight: "420px",
      }}
    >
      <Chart options={options} series={series} type="line" height={"100%"} />
    </div>
  );
};

export default VO2Chart;
