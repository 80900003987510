import React, { useEffect } from "react";
import CalendarTask from "./CalendarTask";

const CalendarDayCell = ({
  date,
  eventList,
  updateLoading,
  setUpdateLoading,
  loadingTasks,
  reloadTasks,
}) => {
  const today = new Date();
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div
      className="cursor-default mx-1 px-1 pb-1 text-right"
      // Style to distinct the today cell from the rest of the cells in the calendar
      style={{
        backgroundColor:
          date.format("D") === today.getDate().toString() &&
          date.format("M") === (today.getMonth() + 1).toString()
            ? "#e6f7ff"
            : "white",
        borderTop:
          date.format("D") === today.getDate().toString() &&
          date.format("M") === (today.getMonth() + 1).toString()
            ? "2px solid #1890ff"
            : "2px solid #f0f0f0",
      }}
    >
      <div className="text-base font-bold pl-1">{date.format("D")}</div>
      <div
        style={{
          minHeight: "90px",
        }}
      >
        <div>
          <ul>
            {/* Render all the events in the calendar as TaskCell */}
            {eventList.map((item, idx) => (
              <CalendarTask
                key={item.id_tarea}
                d={date.format("DD-MMM-YY")}
                event={item}
                eventIdx={idx}
                updateLoading={updateLoading}
                setUpdateLoading={setUpdateLoading}
                loadingTasks={loadingTasks}
                reloadTasks={reloadTasks}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CalendarDayCell;
