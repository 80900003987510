import React, { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import moment from "moment";

// Consts
import {
  clasificacionGraso,
  clasificacionMuscular,
  somatotipo,
} from "../../../../constants";

// Global state
import { useCreateMedicalRecord } from "../useCreateRegister";

const { Option } = Select;

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Por favor seleccione una fecha!",
    },
  ],
};

const FormRegistroBasico = ({ form }) => {
  const { recordFormValues, onChangeRecordFormValue, handleOnClose, nextStep } =
    useCreateMedicalRecord();

  // Disable days after today
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = () => {
    nextStep();
  };

  // Update form values from global storage
  useEffect(() => {
    form.setFieldsValue({
      fecha_registro: moment(recordFormValues?.fecha_registro, "DD/MM/YYYY"),
      altura: recordFormValues?.altura,
      peso: recordFormValues?.peso,
      imc: recordFormValues?.imc,
      peso_deseado: recordFormValues?.peso_deseado,
      graso: recordFormValues?.graso,
      graso_deseado: recordFormValues?.graso_deseado,
      clasificacion_graso: recordFormValues?.clasificacion_graso,
      clasificacion_muscular: recordFormValues?.clasificacion_muscular,
      somatotipo: recordFormValues?.somatotipo,
      electro_pdf: recordFormValues?.electro_pdf,
      electro: recordFormValues?.electro,
    });
  }, [recordFormValues, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="w-full"
      layout="vertical"
      name="form-registro-medico-basico"
      initialValues={{
        ...recordFormValues,
        fecha_registro: moment(recordFormValues?.fecha_registro, "DD/MM/YYYY"),
      }}
    >
      <Row justify="center">
        <Col span={8}>
          <Form.Item
            name="fecha_registro"
            label="Fecha del registro"
            {...config}
            className="mb-1"
          >
            <DatePicker
              allowClear={false}
              className="w-full rounded-lg shadow-neo-field"
              format="DD/MMM/YYYY"
              disabledDate={disabledDate}
              onChange={(date) => {
                onChangeRecordFormValue(
                  "fecha_registro",
                  date ? date.format("DD/MM/YYYY") : null
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider plain>
        <span className="text-gray-400">Datos básicos</span>
      </Divider>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="altura"
            label="Estatura (cm)"
            rules={[
              {
                type: "number",
                min: 1,
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <InputNumber
              min={1}
              onChange={(val) => {
                onChangeRecordFormValue("altura", val);
              }}
              placeholder="Estatura en centimetros..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="peso"
            label="Peso (kg)"
            rules={[
              {
                type: "number",
                min: 1,
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <InputNumber
              min={1}
              onChange={(val) => {
                onChangeRecordFormValue("peso", val);
              }}
              placeholder="Peso..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="imc"
            label="IMC"
            rules={[
              {
                type: "number",
                min: 1,
                required: true,
                message: "Ingresa peso y estatura!",
              },
            ]}
          >
            <InputNumber
              min={1}
              disabled
              onChange={(val) => {
                onChangeRecordFormValue("imc", val);
              }}
              placeholder="Ingresa peso y estatura..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="peso_deseado"
            label="Peso deseado (kg)"
            rules={[
              {
                type: "number",
                min: 1,
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <InputNumber
              min={1}
              onChange={(val) => {
                onChangeRecordFormValue("peso_deseado", val);
              }}
              placeholder="Peso deseado..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="graso"
            label="Nivel graso"
            rules={[
              {
                type: "number",
                min: 0,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              formatter={(value) => (value ? `${value}%` : "")}
              parser={(value) => value.replace("%", "")}
              onChange={(val) => {
                onChangeRecordFormValue("graso", val);
              }}
              placeholder="Nivel graso..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="graso_deseado"
            label="Nivel graso deseado"
            rules={[
              {
                type: "number",
                min: 0,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              formatter={(value) => (value ? `${value}%` : "")}
              parser={(value) => value.replace("%", "")}
              onChange={(val) => {
                onChangeRecordFormValue("graso_deseado", val);
              }}
              placeholder="Nivel graso deseado..."
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            className="my-select-container"
            name="clasificacion_muscular"
            label="Clasificación muscular"
          >
            <Select
              placeholder="Seleccione una clasificación"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                onChangeRecordFormValue("clasificacion_muscular", val);
              }}
            >
              {clasificacionMuscular.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="my-select-container"
            name="clasificacion_graso"
            label="Clasificación graso"
          >
            <Select
              placeholder="Seleccione una clasificación!"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                onChangeRecordFormValue("clasificacion_graso", val);
              }}
            >
              {clasificacionGraso.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="my-select-container"
            name="somatotipo"
            label="Somatotipo"
          >
            <Select
              placeholder="Seleccione un somatotipo"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                onChangeRecordFormValue("somatotipo", val);
              }}
            >
              {somatotipo.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider plain>
        <span className="text-gray-400">Electrocardiograma</span>
      </Divider>
      <Form.Item
        name="electro_pdf"
        label="Enlace del Electro. (pdf)"
        rules={[
          {
            type: "url",
            message: "Debe ser una url valida",
          },
        ]}
      >
        <Input
          className="w-full rounded-lg shadow-neo-field"
          placeholder="Ej: https://bucket-name.s3.region.amazonaws.com/..."
          onChange={(e) => {
            onChangeRecordFormValue("electro_pdf", e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item name="electro" label="Conclusión Electro.">
        <Input.TextArea
          onChange={(e) => {
            onChangeRecordFormValue("electro", e.target.value);
          }}
          placeholder="Conclusión de los resultados del electro."
          className="rounded-lg shadow-neo-field"
        />
      </Form.Item>
      <Row justify="end" align="bottom">
        <Button
          className="mx-4 shadow-neo-button rounded-neo-button hover:border-red-500 hover:text-red-500"
          onClick={() => {
            handleOnClose();
            form.resetFields();
          }}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button"
          htmlType="submit"
        >
          Siguiente
        </Button>
      </Row>
    </Form>
  );
};

export default FormRegistroBasico;
