// Reference: https://redux.js.org/tutorials/quick-start
import { createSlice, current } from "@reduxjs/toolkit";

// Utils
import { calculateIMC } from "utils/utils";
import initialState from "../initialState";

export const recordSlice = createSlice({
  name: "record",
  initialState: initialState().record,
  reducers: {
    clearRecordState: (state) => {
      state.selected = initialState().record.selected;
      state.list = initialState().record.list;
    },
    updateSelectedRecord: (state, action) => {
      state.selected = action.payload;
    },
    updateRecordList: (state, action) => {
      state.list = action.payload;
    },

    /** Form */
    updateVisible: (state, action) => {
      state.recordForm.visible = action.payload;
    },
    updateError: (state, action) => {
      state.recordForm.error = action.payload;
    },

    updateLoading: (state, action) => {
      state.recordForm.loading = action.payload;
    },
    clearRecordFormState: (state) => {
      state.recordForm = initialState().record.recordForm;
    },
    updateRecordFormField: (state, action) => {
      const { fieldName, value } = action.payload;

      if (fieldName === "peso" || fieldName === "altura") {
        recordSlice.caseReducers.updateIMC(state, action);
      }

      // TODO: not so sure about using current here:
      state.recordForm.values = {
        ...current(state).recordForm.values,
        [fieldName]: value,
      };
    },
    updateIMC: (state, action) => {
      const { fieldName, value } = action.payload;

      if (fieldName === "peso") {
        const _altura = state.recordForm.values.altura;
        state.recordForm.values.imc = calculateIMC(_altura, value);
      }
      if (fieldName === "altura") {
        const _peso = state.recordForm.values.peso;
        state.recordForm.values.imc = calculateIMC(value, _peso);
      }
    },

    nextFormStep: (state) => {
      if (state.recordForm.step < 3) {
        state.recordForm.step += 1;
      }
    },
    prevFormStep: (state) => {
      if (state.recordForm.step > 0) {
        state.recordForm.step -= 1;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearRecordState,
  updateSelectedRecord,
  updateRecordList,
  /** Form */

  updateVisible,
  updateError,
  updateLoading,

  nextFormStep,
  prevFormStep,
  clearRecordFormState,
  updateRecordFormField,
} = recordSlice.actions;

export default recordSlice.reducer;
