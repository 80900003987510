import React, { useEffect } from "react";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";

// Custom Hook
import { useCreatePatient } from "../useCreatePatient";

// Constants
import { DEPORTE_OPTIONS, MODALIDAD_OPTIONS } from "../../../../constants";
const { Option } = Select;

const FormPatientSport = ({ form }) => {
  const { formValues, handleOnChangeFormValue, prevStep, nextStep } =
    useCreatePatient();

  const onFinish = () => {
    nextStep();
  };

  useEffect(() => {
    // Update form values from redux

    form.setFieldsValue({
      deporte: formValues?.deporte,
      modalidad: formValues?.modalidad,
      edad_deportiva: formValues?.edad_deportiva,
    });
  }, [formValues, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="w-full"
      name="form-patient"
      style={{
        maxWidth: 600,
      }}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            className="my-select-container"
            name="deporte"
            label="Deporte"
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <Select
              placeholder="Seleccione un deporte"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                handleOnChangeFormValue("deporte", val);
              }}
            >
              {DEPORTE_OPTIONS.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="my-select-container"
            name="modalidad"
            label="Modalidad deportiva"
          >
            <Select
              placeholder="Seleccione una modalidad"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                handleOnChangeFormValue("modalidad", val);
              }}
            >
              {MODALIDAD_OPTIONS.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="edad_deportiva"
            label="Edad deportiva (años)"
            rules={[
              {
                type: "number",
              },
            ]}
          >
            <InputNumber
              min={0}
              onChange={(val) => {
                handleOnChangeFormValue("edad_deportiva", val);
              }}
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Edad deportiva..."
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" align="bottom">
        <Button
          htmlType="button"
          className="mx-4 shadow-neo-button rounded-neo-button"
          onClick={prevStep}
        >
          Anterior
        </Button>
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button"
          htmlType="submit"
        >
          Siguiente
        </Button>
      </Row>
    </Form>
  );
};

export default FormPatientSport;
