import React, { useState, useEffect } from "react";
import { Button, Calendar, Layout, Modal, Row } from "antd";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";

// Styles
import "antd/dist/antd.css";

// Redux
import { useSelector } from "react-redux";

// Components
import CalendarDayCell from "./CalendarDayCell";
import AddTaskForm from "./AddTaskForm";

// Services
import { getTasksByPatient } from "services/patientService";

// Utils
import { openNotification } from "utils/utils";

// Consts
const { Content } = Layout;

const Calendario = () => {
  // User info
  const databaseUser = useSelector((state) => state.user.databaseUser);
  const firebaseUser = useSelector((state) => state.user.firebaseUser);

  const selectedPatient = useSelector((state) => state.patient.selected);

  // Tasks
  const [tasks, setTasks] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [visibleAddTask, setVisibleAddTask] = useState(false);

  const getPatientTasks = async (userId) => {
    setLoadingTasks(true);
    try {
      let res = await getTasksByPatient(userId);
      setTasks(res.data);
      openNotification("success", "Tareas actualizadas!");
    } catch (e) {
      console.log("Usuario sin tareas", e);
      openNotification("info", "Paciente sin tareas");
      setTasks([]);
    } finally {
      setLoadingTasks(false);
    }
  };

  const reloadTasks = () => {
    if (databaseUser?.rol === 2) {
      getPatientTasks(firebaseUser?.uid);
    } else {
      getPatientTasks(selectedPatient.id_paciente);
    }
  };

  const cellRenderFunc = (date) => {
    const formatedDate = date.format("yyyy-MM-D");
    const evs =
      tasks.filter(
        (task) => moment(task.fecha_hora).format("yyyy-MM-D") === formatedDate
      ) ?? [];
    return (
      <CalendarDayCell
        date={date}
        loadingTasks={loadingTasks}
        eventList={evs}
        updateLoading={updateLoading}
        setUpdateLoading={setUpdateLoading}
        reloadTasks={reloadTasks}
      />
    );
  };

  // Get task by himselft as patient
  useEffect(() => {
    if (databaseUser?.rol === 2) {
      getPatientTasks(firebaseUser?.uid);
    }
  }, [databaseUser, firebaseUser]);

  // Get task from patient as admin
  useEffect(() => {
    if (!updateLoading) getPatientTasks(selectedPatient.id_paciente);
  }, [selectedPatient, updateLoading]);

  return (
    <Content style={{ background: "#dce5ff" }} className="mt-0 md:mt-8">
      <Layout
        className="pt-0 md:pt-12 px-6 my-select-container"
        style={{
          background: "#dce5ff",
        }}
      >
        <Row className="mb-4">
          {databaseUser?.rol === 1 && (
            <Button
              type="primary"
              className="mr-4 shadow-neo-button rounded-neo-button"
              onClick={() => {
                setVisibleAddTask(true);
              }}
              icon={<PlusOutlined />}
            >
              Añadir Tarea
            </Button>
          )}
          <Button
            className="shadow-neo-button rounded-neo-button"
            loading={loadingTasks}
            icon={<ReloadOutlined />}
            onClick={reloadTasks}
          >
            Recargar Tareas
          </Button>
        </Row>

        <Calendar
          className="rounded-2xl mb-4"
          onSelect={null}
          dateFullCellRender={cellRenderFunc}
        />
      </Layout>
      <Modal
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setVisibleAddTask(false);
        }}
        visible={visibleAddTask}
      >
        <AddTaskForm
          setVisible={setVisibleAddTask}
          getPatientTasks={getPatientTasks}
        />
      </Modal>
    </Content>
  );
};
export default Calendario;
