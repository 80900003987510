import { configureStore } from "@reduxjs/toolkit";

// Slices
import userReducer from "../features/user/userSlice";
import patientReducer from "../features/patient/patientSlice";
import recordReducer from "../features/record/recordSlice";
import navigationReducer from "../features/navigation/navigationSlice";

// Pages Slices
import nutritionReducer from "../features/nutrition/mealFormSlice";
import calendarReducer from "../features/calendar/calendarSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    patient: patientReducer,
    record: recordReducer,
    navigation: navigationReducer,
    nutrition: nutritionReducer,
    calendar: calendarReducer,
  },
});
