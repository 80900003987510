// Reference: https://redux.js.org/tutorials/quick-start
import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialState";

export const userSlice = createSlice({
  name: "user",
  initialState: initialState().user,
  reducers: {
    clearUserState: (state) => {
      state.firebaseUser = initialState().user.firebaseUser;
      state.databaseUser = initialState().user.databaseUser;
    },
    updateFirebaseUser: (state, action) => {
      //update the whole object
      state.firebaseUser = action.payload;
    },
    updateDatabaseUser: (state, action) => {
      //update the whole object
      state.databaseUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearUserState, updateFirebaseUser, updateDatabaseUser } =
  userSlice.actions;

export default userSlice.reducer;
