import React, { useState } from "react";
import { Button, Steps } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addValueToFormStep } from "../../features/nutrition/mealFormSlice";

// Components
import MealForm from "./MealForm";
import AlimentForm from "./AlimentForm";
import MealConfirmation from "./MealConfirmation";

// Services
import {
  createMeal,
  createNutritionalPlanWithMeal,
} from "services/patientService";

// Utils
import { openNotification } from "utils/utils";

// Constants
const { Step } = Steps;
const steps = [
  {
    title: "Añadir comida",
    content: <MealForm />,
  },
  {
    title: "Añadir alimentos",
    content: <AlimentForm />,
  },
  {
    title: "Previsualización",
    content: <MealConfirmation />,
  },
];

const MealSteps = ({ handleOnClose, getPatientPlan, nutritionalPlan }) => {
  const dispatch = useDispatch();
  const selectedPatient = useSelector((state) => state.patient.selected);

  const mealForm = useSelector((state) => state.nutrition.formMeal);
  const [createLoading, setCreateLoading] = useState(false);

  const next = () => {
    dispatch(addValueToFormStep(1));
  };
  const prev = () => {
    dispatch(addValueToFormStep(-1));
  };

  const handleCreatePlanWithMeal = async (userId, mealBody) => {
    setCreateLoading(true);
    try {
      await createNutritionalPlanWithMeal(userId, mealBody);
      handleOnClose();
      openNotification("success", "Plan nutricional actualizado!");
      getPatientPlan(selectedPatient?.id_paciente); //hot reload when adding data
    } catch (e) {
      openNotification("error", "Error actualizando plan nutricional!");
      console.log("Error actualizando plan nutricional: ", e);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleCreateMeal = async (userId, planId, mealBody) => {
    setCreateLoading(true);
    try {
      await createMeal(userId, planId, mealBody);
      handleOnClose();
      openNotification("success", "Plan nutricional actualizado!");
      getPatientPlan(selectedPatient?.id_paciente); //hot reload when adding data
    } catch (e) {
      openNotification("error", "Error actualizando plan nutricional!");
      console.log("Error actualizando plan nutricional: ", e);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleOnFinish = () => {
    let mealBody = {
      create: [
        {
          tipo: mealForm?.tipo,
          hora: mealForm?.hora,
          alimentos: {
            create: mealForm?.alimentos.map((ele) => {
              return {
                descripcion: ele?.descripcion,
                porcion: ele?.porcion,
                medida: ele?.medida,
                intercambios: ele?.intercambios,
              };
            }),
          },
        },
      ],
    };
    nutritionalPlan?.id_plan_nutricional
      ? handleCreateMeal(
          selectedPatient?.id_paciente,
          nutritionalPlan?.id_plan_nutricional,
          mealBody
        )
      : handleCreatePlanWithMeal(selectedPatient.id_paciente, mealBody);
  };

  return (
    <>
      <Steps current={mealForm.step} className="mt-4" responsive size="small">
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div className="mt-4">{steps[mealForm.step].content}</div>

      <div className="mt-4">
        {mealForm.step === 0 && (
          <Button
            className="mx-4 shadow-neo-button rounded-neo-button hover:border-red-500 hover:text-red-500"
            onClick={handleOnClose}
          >
            Cancelar
          </Button>
        )}
        {mealForm.step > 0 && (
          <Button
            className="mx-4 shadow-neo-button rounded-neo-button"
            onClick={prev}
            disabled={createLoading}
          >
            Anterior
          </Button>
        )}
        {mealForm.step < steps.length - 1 && (
          <Button
            className="shadow-neo-button rounded-neo-button"
            type="primary"
            disabled={mealForm.disableNextStep}
            onClick={next}
          >
            Siguiente
          </Button>
        )}
        {mealForm.step === steps.length - 1 && (
          <Button
            className="shadow-neo-button rounded-neo-button"
            type="primary"
            onClick={handleOnFinish}
            loading={createLoading}
          >
            Finalizar
          </Button>
        )}
      </div>
    </>
  );
};

export default MealSteps;
