import { createRequest, throwErrors } from "./globalServices";

export const getCategories = async (groupId) => {
  try {
    const response = await createRequest().get(`/biblioteca/grupos/${groupId}`);
    return response.data;
  } catch (e) {
    return throwErrors(e);
  }
};

export const getPresetsByCategory = async (categoryId) => {
  try {
    const response = await createRequest().get(
      `/biblioteca/categorias/${categoryId}/prestablecidos`
    );
    return response.data.data;
  } catch (e) {
    return throwErrors(e);
  }
};
