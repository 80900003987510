import React, { useEffect, useState } from "react";
import { Button, Col, Layout, Modal, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { PlusOutlined } from "@ant-design/icons";

// Components
import MealCard from "./MealCard";
import MealSteps from "./MealSteps";
import MealCardLoading from "./MealCardLoading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { clearMealFormState } from "../../features/nutrition/mealFormSlice";

// Services
import { getNutritionalPlanByPatient } from "services/patientService";

const Nutricional = () => {
  const dispatch = useDispatch();
  const selectedPatient = useSelector((state) => state.patient.selected);
  const databaseUser = useSelector((state) => state.user.databaseUser);

  const [nutritionalPlan, setNutritionalPlan] = useState(null);

  const [loadingPlan, setLoadingPlan] = useState(false);
  const [showMealForm, setShowMealForm] = useState(false);

  const getPlanByPatient = async (userId) => {
    setLoadingPlan(true);
    try {
      let res = await getNutritionalPlanByPatient(userId);
      setNutritionalPlan(res.data);
    } catch (e) {
      console.log("Error obteniendo plan nutricional:", e);
      setNutritionalPlan(null);
    } finally {
      setLoadingPlan(false);
    }
  };

  const handleOnClose = () => {
    setShowMealForm(false);
    dispatch(clearMealFormState());
  };

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    getPlanByPatient(selectedPatient?.id_paciente);
  }, [selectedPatient]);

  return (
    <Content style={{ background: "#dce5ff" }} className="mt-0 md:mt-8">
      <Layout
        className="pt-0 md:pt-12 px-6"
        style={{
          background: "#dce5ff",
        }}
      >
        <Col className="mb-2 mx-auto w-full">
          <Row>
            {databaseUser?.rol === 1 && (
              <Button
                className="mx-auto mb-6 rounded-neo-button shadow-neo-button"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowMealForm(true);
                }}
                loading={loadingPlan}
              >
                {nutritionalPlan?.id_plan_nutricional
                  ? "Añadir comida"
                  : "Crear plan nutricional"}
              </Button>
            )}
          </Row>
          <Row className="mb-6">
            {loadingPlan ? (
              <>
                <MealCardLoading />
                <MealCardLoading />
              </>
            ) : nutritionalPlan ? (
              nutritionalPlan.comidas?.map((meal) => (
                <MealCard
                  getPlanByPatient={getPlanByPatient}
                  allowDelete={databaseUser?.rol === 1}
                  key={meal.id_comida}
                  mealData={meal}
                  editable
                />
              ))
            ) : (
              emptyPlan.map((meal) => (
                <MealCard key={meal.id_comida} mealData={meal} />
              ))
            )}
          </Row>
        </Col>
        <Modal
          maskClosable={false}
          style={{ padding: "0", top: 20, width: "800px" }}
          centered
          visible={showMealForm}
          onCancel={handleOnClose}
          footer={null}
          zIndex={1003}
          width={650}
        >
          <MealSteps
            getPatientPlan={getPlanByPatient}
            handleOnClose={handleOnClose}
            nutritionalPlan={nutritionalPlan}
          />
        </Modal>
      </Layout>
    </Content>
  );
};

export default Nutricional;
const emptyPlan = [
  {
    id_comida: "001",
    tipo: "Sin esquema nutricional",
    alimentos: [],
  },
];
