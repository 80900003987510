import React, { useState, useEffect } from "react";
import { Col, Button, Typography, Spin } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ref, getDownloadURL } from "firebase/storage";

import { storage } from "../../../config/firebase-config";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import "../../../styles/pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Title } = Typography;

const PDFViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    getDownloadURL(ref(storage, `${pdf}`))
      .then((url) => {
        setPdfUrl(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pdf]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <Col
      xs={24}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="document">
        <Button
          type="primary"
          shape="circle"
          icon={<LeftOutlined />}
          disabled={pageNumber <= 1}
          onClick={previousPage}
          style={{ zIndex: 1002 }}
        />
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div
              style={{
                height: "200px",
                width: "200px",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 40,
                      position: "absolute",
                      top: "50%",
                      left: "49%",
                    }}
                  />
                }
              />
            </div>
          }
        >
          <Page
            pageNumber={pageNumber}
            scale={1.3}
            renderAnnotationLayer={false}
          />
        </Document>
        <Button
          type="primary"
          shape="circle"
          icon={<RightOutlined />}
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          style={{ zIndex: 1002 }}
        />
      </div>
      <Title level={5}>
        Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
      </Title>
    </Col>
  );
};

export default PDFViewer;
