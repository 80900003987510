import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as Logo } from "../img/d4d.svg";

const Loading = () => {
  return (
    <div
      className={"flex flex-col bg-gray-200 w-full h-screen  m-auto mt-52 "}
      style={{
        margin: "auto",
      }}
    >
      <Logo className="m-auto loading-svg" />
      <Spin
        indicator={
          <LoadingOutlined
            type="loading"
            className="rounded-full"
            style={{
              fontSize: 50,
              position: "absolute",
              marginTop: "-54vh",
              marginLeft: "-23px",
              color: "white",
            }}
          />
        }
      />
    </div>
  );
};

export default Loading;
