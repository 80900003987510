import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Collapse, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";

// Redux
import { useSelector } from "react-redux";

// Components
import KPICard from "./KPICard";

// Utils
moment.locale("es");
const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const panelTitle = (title) => {
  return <span className="font-semibold">{title}</span>;
};

const ValoracionMedica = ({ patientVitals }) => {
  const selectedPatient = useSelector((state) => state.patient.selected);

  const [selectedVitals, setSelectedVitals] = useState(null);
  const [options, setOptions] = useState([]);
  const {
    antec_med,
    antec_osteomusculares,
    antec_cardiovasculares,
    antec_familiares,
    examen_fisico,
    aptitud_med,
  } = selectedPatient;

  useEffect(() => {
    setSelectedVitals(patientVitals[0]);
    const dateMapOptions = patientVitals.map((row) => (
      <Option key={row.fecha_vitales}>
        {moment(row.fecha_vitales).add(1, "days").format("DD MMM YYYY")}
      </Option>
    ));
    setOptions(dateMapOptions);
  }, [patientVitals, selectedPatient]);

  const handleDateChange = (value) => {
    setSelectedVitals(patientVitals.find((ele) => ele.fecha_vitales === value));
  };

  return (
    <Row className="justify-center mb-6 bg-white p-6 rounded-2xl shadow-neo-card">
      <Col xs={23} className="flex flex-col justify-center items-center">
        <Row className="justify-center my-6 w-full">
          <Col flex="auto">
            <Title level={2} className="font-bold text-center">
              Valoración Médica
            </Title>
          </Col>
          <Col flex="160px">
            <div className="ml-auto my-select-container">
              <Select
                placeholder="Seleccione registro"
                onChange={handleDateChange}
                disabled={!patientVitals.length}
                value={patientVitals[0]?.fecha_vitales}
                style={{ width: 130 }}
                className="my-auto"
              >
                {options}
              </Select>
              <Tooltip
                title="Visualice los signos vitales por fecha"
                className="mx-2 mb-2"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row className="justify-center mb-8 w-4/5" gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6} className="flex justify-center">
            <KPICard
              title="TAS"
              subtitle={"Tensión Arterial Sistólica"}
              value={selectedVitals?.tas}
              width={180}
              color="#3fbea1"
            />
          </Col>
          <Col xs={24} sm={12} md={6} className="flex justify-center">
            <KPICard
              title="TAD"
              subtitle={"Tensión Arterial Diastólica"}
              value={selectedVitals?.tad}
              width={180}
              color="#00bcdb"
            />
          </Col>
          <Col xs={24} sm={12} md={6} className="flex justify-center">
            <KPICard
              title="FC"
              subtitle={"Frecuencia Cardiáca"}
              value={selectedVitals?.fc}
              suffix="lpm"
              width={180}
              color="#53b2f5"
            />
          </Col>
          <Col xs={24} sm={12} md={6} className="flex justify-center">
            <KPICard
              title="SATO2"
              subtitle={"Saturación Oxígeno"}
              suffix="%"
              value={selectedVitals?.sato2}
              width={180}
              color="#aba1ee"
            />
          </Col>
        </Row>
        <Row className="justify-center w-full mb-4 bg-gray-100 p-3 rounded-xl">
          <Collapse accordion ghost className="w-full bg-gray-100">
            <Panel
              header={panelTitle("Antecedentes Médicos")}
              key="1"
              className="bg-white rounded-lg mb-3 shadow-neo-card"
            >
              <p className="ml-6 font-normal">{antec_med}</p>
            </Panel>
            <Panel
              header={panelTitle("Antecedentes Familiares")}
              key="2"
              className="bg-white rounded-lg mb-3 shadow-neo-card"
            >
              <p className="ml-6">
                {antec_familiares ?? "Sin antecedentes familiares"}
              </p>
            </Panel>
            <Panel
              header={panelTitle("Antedcedentes Cardiovasculares")}
              key="3"
              className="bg-white rounded-lg mb-3 shadow-neo-card"
            >
              <p className="ml-6">{antec_cardiovasculares}</p>
            </Panel>
            <Panel
              header={panelTitle("Antecedentes Osteomusculares")}
              key="4"
              className="bg-white rounded-lg mb-3 shadow-neo-card"
            >
              <p className="ml-6">{antec_osteomusculares}</p>
            </Panel>
            <Panel
              header={panelTitle("Examen físico")}
              key="5"
              className="bg-white rounded-lg mb-3 shadow-neo-card"
            >
              <p className="ml-6">{examen_fisico ?? "Sin examen físico"}</p>
            </Panel>
            <Panel
              header={panelTitle("Análisis")}
              key="6"
              className="bg-white shadow-neo-card"
              style={{
                borderRadius: "8px",
              }}
            >
              <p className="ml-6">{aptitud_med ?? "Sin análisis"}</p>
            </Panel>
          </Collapse>
        </Row>
      </Col>
    </Row>
  );
};

export default ValoracionMedica;
