import React, { useState } from "react";
import { Layout } from "antd";

// Components
import Login from "../components/landing/Login";
import ForgotPassword from "../components/landing/ForgotPassword";
import Footer from "../components/footer/Footer";

const LogIn = ({ logged, setLogged }) => {
  const [recoverPassword, setRecoverPassword] = useState(false);

  return (
    <Layout className="h-full bg-gray-100">
      {recoverPassword ? (
        <ForgotPassword setRecoverPassword={setRecoverPassword} />
      ) : (
        <Login
          logged={logged}
          setLogged={setLogged}
          setRecoverPassword={setRecoverPassword}
        />
      )}
      <Footer />
    </Layout>
  );
};

export default LogIn;
