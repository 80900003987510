import React, { useEffect } from "react";
import moment from "moment";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";

// Custom Hook
import { useCreatePatient } from "../useCreatePatient";

// Constants
import {
  GENERO_OPTIONS,
  GRUPO_SANGUINEO_OPTIONS,
  RH_OPTIONS,
} from "../../../../constants";
const { Option } = Select;

const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Por favor seleccione una fecha!",
    },
  ],
};

const FormPatientBasicData = ({ form }) => {
  const { handleOnClose, formValues, handleOnChangeFormValue, nextStep } =
    useCreatePatient();

  const onFinish = () => {
    nextStep();
  };

  const disabledDate = (current) => {
    // Disable days after today
    return current && current > moment().endOf("day");
  };

  useEffect(() => {
    // Update form values from redux

    form.setFieldsValue({
      nombres: formValues?.nombres,
      apellidos: formValues?.apellidos,

      email: formValues?.email,
      personal_id: formValues?.personal_id,

      lugar_nacimiento: formValues?.lugar_nacimiento,
      residencia: formValues?.residencia,

      genero: formValues?.genero,
      telefono: formValues?.telefono,

      fecha_nacimiento: formValues.fecha_nacimiento
        ? moment(formValues.fecha_nacimiento, "DD/MM/YYYY")
        : null,
      edad: formValues?.edad,

      rh: formValues?.rh,
      grupo_sanguineo: formValues?.grupo_sanguineo,
    });
  }, [formValues, form]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="w-full"
      name="form-patient"
      style={{
        maxWidth: 600,
      }}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="nombres"
            label="Nombres"
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <Input
              onChange={(event) => {
                handleOnChangeFormValue("nombres", event.target.value);
              }}
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Andrés"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="apellidos"
            label="Apellidos"
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <Input
              onChange={(event) => {
                handleOnChangeFormValue("apellidos", event.target.value);
              }}
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Rodriguez"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Debe ser un email válido",
              },
            ]}
          >
            <Input
              onChange={(event) => {
                handleOnChangeFormValue("email", event.target.value);
              }}
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Ej: ejemplo@gmail.com"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="personal_id"
            label="Identificación personal"
            rules={[
              {
                required: true,
                message: "Obligatorio",
              },
            ]}
          >
            <Input
              onChange={(event) => {
                handleOnChangeFormValue("personal_id", event.target.value);
              }}
              placeholder="Ej: 1234567890"
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="fecha_nacimiento"
            label="Fecha de nacimiento"
            {...config}
          >
            <DatePicker
              allowClear={false}
              className="w-full rounded-lg shadow-neo-field"
              format="DD/MMM/YYYY"
              disabledDate={disabledDate}
              onChange={(date) => {
                handleOnChangeFormValue(
                  "fecha_nacimiento",
                  date ? date.format("DD/MM/YYYY") : null
                );
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="edad"
            label="Edad"
            rules={[
              {
                type: "number",
                min: 1,
                required: true,
                message: "Debe ser mayor que 1",
              },
            ]}
          >
            <InputNumber
              min={1}
              disabled
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Selecciona fecha de nacimiento..."
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="telefono" label="Telefono contacto">
            <Input
              onChange={(event) => {
                handleOnChangeFormValue("telefono", event.target.value);
              }}
              placeholder="300 855 8555"
              className="w-full rounded-lg shadow-neo-field"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="my-select-container" name="genero" label="Sexo">
            <Select
              placeholder="Seleccione una opción"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                handleOnChangeFormValue("genero", val);
              }}
            >
              {GENERO_OPTIONS.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="lugar_nacimiento" label="Lugar de nacimiento">
            <Input
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Bogotá D.C."
              onChange={(event) => {
                handleOnChangeFormValue("lugar_nacimiento", event.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="residencia" label="Lugar de residencia">
            <Input
              className="w-full rounded-lg shadow-neo-field"
              placeholder="Bogotá D.C."
              onChange={(event) => {
                handleOnChangeFormValue("residencia", event.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            className="my-select-container"
            name="grupo_sanguineo"
            label="Grupo sanguineo"
          >
            <Select
              placeholder="Seleccione un grupo sanguineo!"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                handleOnChangeFormValue("grupo_sanguineo", val);
              }}
            >
              {GRUPO_SANGUINEO_OPTIONS.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="my-select-container" name="rh" label="RH">
            <Select
              placeholder="Seleccione un RH"
              className="rounded-lg shadow-neo-field"
              onChange={(val) => {
                handleOnChangeFormValue("rh", val);
              }}
            >
              {RH_OPTIONS.map((e) => (
                <Option key={e.val} value={e.val}>
                  {e.tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" align="bottom">
        <Button
          htmlType="button"
          className="mx-4 shadow-neo-button rounded-neo-button hover:border-red-500 hover:text-red-500"
          onClick={() => {
            handleOnClose();
            form.resetFields();
          }}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          className="shadow-neo-button rounded-neo-button"
          htmlType="submit"
        >
          Siguiente
        </Button>
      </Row>
    </Form>
  );
};

export default FormPatientBasicData;
