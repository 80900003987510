import moment from "moment";
import { useCallback } from "react";
import { openNotification } from "utils/utils";

// Redux Toolkit
import { useDispatch, useSelector } from "react-redux";
import {
  clearPatientFormState,
  includeNewPatient,
  nextFormStep,
  prevFormStep,
  updateError,
  updateLoading,
  updatePatientFormField,
  updateVisible,
} from "features/patient/patientSlice";
import { createPatient } from "services/patientService";

export function useCreatePatient() {
  const dispatch = useDispatch();

  const visibleModal = useSelector(
    (state) => state.patient.patientForm.visible
  );
  const error = useSelector((state) => state.patient.patientForm.error);
  const loading = useSelector((state) => state.patient.patientForm.loading);

  const formStep = useSelector((state) => state.patient.patientForm.step);
  const formValues = useSelector((state) => state.patient.patientForm.values);

  const setVisibleModal = useCallback(
    (value) => {
      dispatch(updateVisible(value));
    },
    [dispatch]
  );

  const nextStep = useCallback(() => {
    dispatch(nextFormStep());
  }, [dispatch]);

  const prevStep = useCallback(() => {
    dispatch(prevFormStep());
  }, [dispatch]);

  const handleOnChangeFormValue = useCallback(
    (fieldName, value) => {
      dispatch(updatePatientFormField({ fieldName, value }));
    },
    [dispatch]
  );

  const handleOnClose = useCallback(() => {
    dispatch(clearPatientFormState());
    dispatch(updateVisible(false));
  }, [dispatch]);

  const handleOnCreatePatient = useCallback(
    async (_userId) => {
      dispatch(updateLoading(true));

      try {
        const _patientBody = {
          ...formValues,
          fecha_nacimiento: moment(
            formValues?.fecha_nacimiento,
            "DD/MM/YYYY"
          ).toISOString(),
        };
        console.log("[Create Patient] - Creando paciente...");
        const newPatient = await createPatient(_userId, _patientBody);

        openNotification("success", "Paciente creado!");

        dispatch(clearPatientFormState());
        dispatch(updateVisible(false));
        dispatch(includeNewPatient(newPatient.data));
      } catch (error) {
        console.log("[Create Patient] - Error creando paciente: ");
        console.log(error);

        if (error.code === "auth/email-already-in-use") {
          openNotification("error", "El email ya se encuentra registrado.");
        } else {
          dispatch(updateError(error));
          openNotification("error", "Error creando paciente.");
        }
      } finally {
        dispatch(updateLoading(false));
      }
    },
    [dispatch, formValues]
  );

  return {
    visibleModal,
    setVisibleModal,

    handleOnClose,
    handleOnCreatePatient,
    handleOnChangeFormValue,

    error,
    loading,
    formStep,
    formValues,
    nextStep,
    prevStep,
  };
}
