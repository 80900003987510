import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import useWindowDimensions from "../../utils/window";
const coolors = {
  orange: "#faad14",
  green: "#52c41a",
  yellow: "#FFDE0A",
  // ligths are based on the 3rd superior shade of original color in coolors.co
  orangeLigth: "#faad14",
  greenLigth: "#52c41a",
  yellowLigth: "#fadb14",
};

const LinearGauge = ({
  title,
  value = 0,
  refValues,
  GWidth = 500,
  suffix = "",
}) => {
  const { width } = useWindowDimensions();

  const [barWidth, setBarWidth] = useState(GWidth);
  //responsiveness
  useEffect(() => {
    setBarWidth(() => {
      if (width > 1200) {
        return 430;
      } else if (width > 1060) {
        return 350;
      } else if (width > 400) {
        return 200;
      } else if (width > 330) {
        return 200;
      } else if (width <= 330) {
        return 150;
      }
    });
  }, [width]);
  const personVal = (value * barWidth) / refValues.global.max;
  const isOk = value >= refValues.healthy.min && value <= refValues.healthy.max;
  const getPercentage = (metric) => {
    //console.log(title, value, (metric * 100) / refValues.global.max + "%");
    return (metric * 100) / refValues.global.max + "%";
  };

  const getBallCoolor = () => {
    if (isOk) {
      return coolors.green;
    } else {
      if (value > refValues.healthy.max) {
        return coolors.yellow;
      }
    }
    return coolors.orange;
  };

  return (
    <Col
      lg={12}
      md={24}
      className="mb-4 flex justify-center"
      style={{
        width: barWidth,
      }}
    >
      <div>
        <Row
          style={{
            width: barWidth,
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          <Col span={23}>{title}</Col>
          <Col span={1}>
            <Tooltip
              shouldDisableClick
              className="GreyTooltip"
              title={
                <p
                  align="left"
                  style={{
                    marginBottom: "0em",
                  }}
                >
                  <i>{title}</i>
                  <br />
                  Rango Global: {refValues.global.min + " " + suffix} -{" "}
                  {refValues.global.max + " " + suffix}
                  <br />
                  Rango Saludable: {refValues.healthy.min + " " + suffix} -{" "}
                  {refValues.healthy.max + " " + suffix}
                  <br />
                </p>
              }
            >
              <InfoCircleOutlined />{" "}
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <svg width={barWidth} height={30}>
            <g>
              <linearGradient
                id={`gradient-${title.replace(/\s/g, "-")}`}
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
                spreadMethod="pad"
              >
                <stop
                  offset={getPercentage(refValues.healthy.min)}
                  stopColor={coolors.orangeLigth}
                  stopOpacity="1"
                ></stop>
                <stop
                  offset={getPercentage(refValues.healthy.min)}
                  stopColor={coolors.greenLigth}
                  stopOpacity="1"
                ></stop>
                <stop
                  offset={getPercentage(refValues.healthy.max)}
                  stopColor={coolors.greenLigth}
                  stopOpacity="1"
                ></stop>
                <stop
                  offset={getPercentage(refValues.healthy.max)}
                  stopColor={coolors.yellowLigth}
                  stopOpacity="1"
                ></stop>
              </linearGradient>
            </g>

            <g>
              <rect
                x="0"
                y="25%"
                rx="8"
                ry="8"
                width="100%"
                height={15}
                fill={`url(#gradient-${title.replace(/\s/g, "-")})`}
              ></rect>
            </g>
            <Tooltip placement="bottom" title={value + " " + suffix}>
              <g transform={`translate(${personVal},-0.5)`}>
                {/* 472 #62e776 */}
                <circle cx="15" cy="15" r="14" fill={getBallCoolor()}></circle>
                {isOk ? (
                  //green check
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    width="2.2em"
                    height="2.2em"
                    fill="white"
                  >
                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                    <path
                      fill="white"
                      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                    />
                  </svg>
                ) : (
                  //warning
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    width="2.2em"
                    height="2.2em"
                    fill="black"
                  >
                    <path
                      fill="#fafafa"
                      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                    />
                    <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z" />
                  </svg>
                )}
              </g>
            </Tooltip>
          </svg>
        </Row>
        <Row style={{ width: barWidth }} justify="space-between">
          <Col>{refValues.global.min + " " + suffix}</Col>
          <Col>{refValues.global.max + " " + suffix}</Col>
        </Row>
      </div>
    </Col>
  );
};
LinearGauge.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
  //refValues: PropTypes.object.isRequired,
  GWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.oneOfType([PropTypes.string]),
};

export default LinearGauge;
