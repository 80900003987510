import { BackTop, Layout } from "antd";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

// Utils
import useWindowDimensions from "../utils/window";

// Components
import Loading from "./Loading";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Content from "../components/content/Content";
import Dashboards from "../components/dashboards/Dashboards";
import Calendario from "../components/calendario/Calendario";
import Nutricional from "../components/nutrition/Nutricional";
import Welcome from "./Welcome";

// Assets
import avatar_female from "../assets/img/avatar-female.png";
import avatar_male from "../assets/img/avatar-male.png";

// Custom Hook
import { useUserInfo } from "hooks/useUserInfo";

import CustomSider from "../components/sider/Sider";

const Main = ({ visibleDropdown, setVisibleDropdown }) => {
  const { height } = useWindowDimensions();
  const { handleGetUserInfo } = useUserInfo();
  const showWelcome = useSelector((state) => state.navigation.showWelcome);

  const selectedPatient = useSelector((state) => state.patient.selected);
  const isReady = useSelector((state) => state.patient.isReady);

  // Basic Info
  const [photoUrl, setPhotoUrl] = useState(() => {
    return selectedPatient?.genero?.toLowerCase() === "masculino"
      ? avatar_male
      : avatar_female;
  });

  // Update avatar photo when selected patient
  useEffect(() => {
    setPhotoUrl(() => {
      return selectedPatient?.genero?.toLowerCase() === "masculino"
        ? avatar_male
        : avatar_female;
    });
  }, [selectedPatient]);

  // Excecutes when email, user or rol is changed
  useEffect(() => {
    handleGetUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isReady ? (
        <>
          <Layout style={{ minHeight: height }} className="font-sans">
            <BackTop />
            <Header
              visibleDropdown={visibleDropdown}
              setVisibleDropdown={setVisibleDropdown}
              photoUrl={photoUrl}
              setPhotoUrl={setPhotoUrl}
            />
            {!showWelcome && <CustomSider />}
            <Switch>
              <Route path="/pasaportes" exact>
                <Content photoUrl={photoUrl} setPhotoUrl={setPhotoUrl} />
              </Route>
              <Route path="/dashboards" exact>
                <Dashboards />
              </Route>
              <Route path="/calendario" exact>
                <Calendario />
              </Route>
              <Route path="/nutricional" exact>
                <Nutricional />
              </Route>
              <Route path="/welcome" exact>
                <Welcome />
              </Route>
              <Redirect to="welcome" />
            </Switch>
          </Layout>
          <Footer />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Main;
