import React from "react";
import { Col, Layout, Row } from "antd";
import {
  IdcardOutlined,
  BarChartOutlined,
  ScheduleOutlined,
  BookOutlined,
} from "@ant-design/icons";

// Utils
import { headerTitles } from "../constants";

import { ReactComponent as Image } from "../assets/img/MOEBIUS-LOGO.svg";

import "../styles/welcome.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateWelcome } from "../features/navigation/navigationSlice";

// Constants
const { Content } = Layout;

const customMenuButton = (title, icon, route, key, dispatch) => (
  <Col key={key} lg={6} md={12} xs={24}>
    <div
      className="w-48 p-4 bg-white mb-10 mx-auto cursor-pointer trigger-on-hover"
      onClick={() => {
        dispatch(updateWelcome(false));
      }}
    >
      <Link to={route}>
        <Row className="text-5xl mx-auto my-6 text-white">
          <span className="flex justify-center items-center mx-auto w-32 h-32 gradient-bg rounded-full target-on-hover">
            {icon}
          </span>
        </Row>
        <Row className="flex justify-center items-center text-lg font-semibold text-center mb-4">
          {title}
        </Row>
      </Link>
    </div>
  </Col>
);

const Welcome = () => {
  const databaseUser = useSelector((state) => state.user.databaseUser);
  const dispatch = useDispatch();
  const data = [
    {
      key: "passport-key",
      title: headerTitles.passport[databaseUser.rol],
      icon: <IdcardOutlined />,
      route: "/pasaportes",
    },
    {
      key: "dashboards-key",
      title: headerTitles.dashboard[databaseUser.rol],
      icon: <BarChartOutlined />,
      route: "/dashboards",
    },
    {
      key: "calendario-key",
      title: headerTitles.calendar[databaseUser.rol],
      icon: <ScheduleOutlined />,
      route: "/calendario",
    },
    {
      key: "nutricional-key",
      title: headerTitles.nutritional[databaseUser.rol],
      icon: <BookOutlined />,
      route: "/nutricional",
    },
  ];
  return (
    <Content className={"mt-16 bg-white"}>
      <div className="diagonal gradient-bg">
        <Row className="mx-20">
          <Col xs={24} sm={14}>
            <div className="text-3xl sm:text-5xl text-white font-bold px-4 pt-10 sm:py-20 sm:px-2">
              Bienvenido, {databaseUser?.nombres.split(" ")[0]}
            </div>
          </Col>
          <Col
            xs={24}
            sm={10}
            className="flex justify-center items-center pt-6"
          >
            <Image className="welcome-svg h-36 md:h-56 md:mt-4 w-40 md:w-60" />
          </Col>
        </Row>
      </div>
      <div className="under-diagonal">
        <div className="py-32">
          <Row justify="space-between">
            <div className="w-full text-center text-2xl sm:text-3xl text-gray-700 font-bold pb-12">
              Navega en tu plan Moebius
            </div>
          </Row>
          <Row justify="space-between" className="px-20">
            {data.map((e) => {
              return customMenuButton(
                e.title,
                e.icon,
                e.route,
                e.key,
                dispatch
              );
            })}
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default Welcome;
