// Reference: https://redux.js.org/tutorials/quick-start
import { createSlice } from "@reduxjs/toolkit";
import initialState from "../initialState";

export const mealFormSlice = createSlice({
  name: "nutrition",
  initialState: initialState().nutrition,
  reducers: {
    clearMealFormState: (state) => {
      state.formMeal = initialState().nutrition.formMeal;
    },
    updateDisableNextButton: (state) => {
      let canPressNext = true;
      if (state.formMeal.step === 0) {
        canPressNext = canPressNext && state.formMeal.hora !== -1;
      }
      if (state.formMeal.step === 1) {
        state.formMeal.alimentos.forEach(({ descripcion, porcion }) => {
          canPressNext =
            canPressNext && descripcion !== null && descripcion !== "";
          canPressNext = canPressNext && porcion !== null && porcion > 0;
        });
      }
      state.formMeal.disableNextStep = !canPressNext;
    },

    addValueToFormStep: (state, action) => {
      state.formMeal.step += action.payload;
      mealFormSlice.caseReducers.updateDisableNextButton(state);
    },

    updateFormMealType: (state, action) => {
      state.formMeal.tipo = action.payload;
    },
    updateFormMealTime: (state, action) => {
      state.formMeal.hora = action.payload;
      mealFormSlice.caseReducers.updateDisableNextButton(state);
    },

    addAlimentToMeal: (state, action) => {
      const index = action.payload;
      if (!state.formMeal.alimentos[index]) {
        state.formMeal.alimentos[index] = {
          id_alimento: "form-aliment-" + index,
          descripcion: null,
          porcion: null,
          medida: null,
          intercambios: null,
        };
      }
      mealFormSlice.caseReducers.updateDisableNextButton(state);
    },
    removeAlimentFromMeal: (state, action) => {
      state.formMeal.alimentos.splice(action.payload, 1);
      mealFormSlice.caseReducers.updateDisableNextButton(state);
    },
    updateAlimentField: (state, action) => {
      const { id, fieldName, val } = action.payload;
      state.formMeal.alimentos[id][fieldName] = val;
      mealFormSlice.caseReducers.updateDisableNextButton(state);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearMealFormState,
  addValueToFormStep,
  updateFormMealType,
  updateFormMealTime,
  addAlimentToMeal,
  removeAlimentFromMeal,
  updateAlimentField,
} = mealFormSlice.actions;

export default mealFormSlice.reducer;
